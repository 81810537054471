import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from './environments/environment';

@Component({
    selector: 'app-environment-alert',
    template: `
        <style>
            .red {
                background-color: red;
                color: #fff;
                padding: 5px;
                font-size: 0.8em;
                text-align: center;
            }
        </style>
        <div class="container red" *ngIf="!environment.production">
            This is a testing site for the CC School. Logins and courses will not be functional. To get to the real website,
            <a href="https://www.carecourses.com">click here</a>
            <div *ngIf="false">
                <p>This is a false</p>
            </div>
        </div>
    `,
})
export class EnvironmentAlertComponent implements OnInit {
    environment = environment;

    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    ngOnInit(): void {
        // This component doesn't have any properties or dependencies to initialize
    }
}
