import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-sort-order',
    styles: [`
    `],
    template: `
        <span style="display:flex; flex-direction: column; justify-content: center; margin-left: 15px;" (click)="sortOrderToggle()" role="button">
            <i class="fa fa-sort-asc" [class.lower-opacity]="sortOrderDirection === 'Descending'"></i>
            <i class="fa fa-sort-desc" [class.lower-opacity]="sortOrderDirection === 'Ascending'"></i>
        </span>
    `,
})
export class SortOrderComponent implements OnInit {
    @Input() sortOrderDirection = 'Descending';
    @Output() sortOrderChange = new EventEmitter<string>();
    constructor() {
        //No init
    }

    ngOnInit(): void {
        //No init
    }

    sortOrderToggle(): void {
        if (this.sortOrderDirection === 'Descending') {
            this.sortOrderChange.emit('Ascending');
        } else {
            this.sortOrderChange.emit('Descending');
        }
    }
}
