<div id="billing-cards-panel" style="height: 1px; overflow: hidden">
    &nbsp;
    <!-- Angular anchoring is buggy. Adding this div to anchor to was only way to make this work -->
</div>
<div class="miles-card">
    <h4>Billing Address</h4>
    <collapsible-card-entity-list
        *ngIf="customerBillingList && !editingItem"
        [entities]="customerBillingList"
        [itemTemplate]="billingTemplate"
        [selectedEntity]="selectedItem"
        [includeEdit]="true"
        [includeAdd]="true"
        [includeDelete]="true"
        [labelTextForShowHide]="'View or Add Billing Addresses'"
        [textForAddButton]="'Add Billing Address'"
        [(isOpen)]="showAllAddresses"
        [selectButton]="selectButton"
        [selectButtonText]="selectButtonText"
        [fewTotal]="numberOfItemsBeforeExpanded"
        [numOfColumns]="numberOfItemsBeforeExpanded"
        [emptyEntity]="emptyBillingAddress"
        (edit)="edit($event)"
        (entityClick)="edit($event)"
        (add)="edit($event)"
        (select)="select($event)"
        (delete)="onItemDeleted($event)"
    >
    </collapsible-card-entity-list>
    <app-billing-detail
        *ngIf="editingItem"
        [customerBilling]="editingItem"
        (onNewPrimary)="setNewPrimary($event)"
        (onComplete)="save()"
        (onCancel)="cancel()"
    ></app-billing-detail>
</div>
<br />

<ng-template #billingTemplate let-billing>
    <app-billing-info [billing]="billing" [isAdmin]="isAdmin"></app-billing-info>
</ng-template>
