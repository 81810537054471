<div class="container login-container">
    <div class="row d-flex justify-content-center">
        <div class="col-md-6 login-form-1">
            <div class="message"><h1>Send My Password</h1></div>
            <form [formGroup]="forgotPasswordForm" (submit)="onLogin()">
                <div class="form-group" [class.has-error]="emailHasError()">
                    <input type="text" class="form-control" placeholder="example@domain.com" formControlName="email" />

                    <div *ngIf="showEmailRequiredError()" class="small errortext" [style.position]="'block'">Email is required</div>
                    <div *ngIf="showEmailInvalidError()" class="small errortext" [style.position]="'block'">
                        Invalid email address
                    </div>
                </div>
                <button type="button" routerLink="/login" class="btn btn-secondary">Back</button>
                <button [disabled]="!forgotPasswordForm.valid" type="submit" class="btn btn-primary float-right">
                    Submit
                </button>
            </form>
        </div>
    </div>
</div>
