import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TopOfPage } from '@common/functions/common-functions.library';
import { ICustomerRegistrationPayload } from '@model/interfaces/custom/customer-registration-payload';
import { IState } from '@model/interfaces/state';
import { CustomerBillingDynamicControlsPartial } from '@model/partials/customer-billing-partial.form-controls';
import { CustomerDynamicControlsPartial, emailAddressessMatch, passwordsMatch } from '@model/partials/customer.form-controls';
import { AuthService, MtAuthGuard } from '@mt-ng2/auth-module';
import { IMetaItem } from '@mt-ng2/base-service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CountriesEnums, ListItemTypeEnums } from '../common/constants/Enums';
import { CombinePhoneStrings, GetState } from '../common/functions/common-functions.library';
import { CommonService } from '../common/services/common.service';
import { ShoppingCartService } from '../common/shopping-cart/shopping-cart.service';
import { IEmailPasswordDTO } from '../model/interfaces/custom/email-password-dto';
import { CustomerRegistrationService } from './customer-registration.service';
// tslint:disable: object-literal-sort-keys

@Component({
    selector: 'customer-registration',
    templateUrl: './customer-registration.component.html',
})
export class CustomerRegistrationComponent implements OnInit {
    abstractCustomerControls: any;
    abstractCustomerBillingControls: any;
    customerRegistrationForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    states: IState[];
    statesMetaItem: IMetaItem[];
    facilityTypes: IMetaItem[];
    coutriesMetaItem: IMetaItem[];
    hearAboutList: IMetaItem[];
    returnUrl = '';
    confirmInformationModal: IModalWrapperApi;
    billingCountryId: number;
    workCountryId: number;
    workStateId: number;
    hearAboutUsAnswer: string;
    zipInvalid: string;
    phoneInvalid: string;

    confirmInformationModalOptions: IModalOptions = {
        confirmButtonText: 'Create Account',
        cancelButtonText: 'Make Changes',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
        reverseButtons: true,
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary',
        },
        hideClass: { popup: '' },
        showClass: { popup: '' },
    };

    get billingCountryIsUS(): boolean {
        return this.billingCountryId === CountriesEnums.US;
    }
    get billingCountryIsCanada(): boolean {
        return this.billingCountryId === CountriesEnums.Canada;
    }
    get phoneShowingWithExtension(): boolean {
        return this.billingCountryIsUS || this.billingCountryIsCanada;
    }
    get workCountryIsUS(): boolean {
        return this.workCountryId === CountriesEnums.US;
    }
    get workCountryIsCanada(): boolean {
        return this.workCountryId === CountriesEnums.Canada;
    }
    get hearAboutUsIsOther(): boolean {
        return this.hearAboutUsAnswer === 'Other';
    }
    get allowRegistryId(): boolean {
        return this.workCountryId === CountriesEnums.US && this.states?.filter((opt) => opt.StateId === this.workStateId)[0]?.AllowRegistryId;
    }

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private shoppingCartService: ShoppingCartService,
        private customerRegistrationService: CustomerRegistrationService,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private authService: AuthService,
        private title: Title,
        private meta: Meta,
    ) {}

    // --------------- Setup ----------------- //

    ngOnInit(): void {
        forkJoin([
            this.commonService.getStates(),
            this.commonService.getCountries(),
            this.commonService.getListItems(ListItemTypeEnums.TypeOfFacility),
            this.commonService.getListItems(ListItemTypeEnums.HearAboutUs),
        ]).subscribe(([states, countries, facilityTypes, hearAboutList]) => {
            this.states = states;
            this.statesMetaItem = this.states.map((state) => ({ Id: state.StateId, Name: state.StateDesc } as IMetaItem));
            this.coutriesMetaItem = countries.map((country) => ({ Id: country.CountryId, Name: country.CountryName } as IMetaItem));
            this.facilityTypes = facilityTypes.map((facility) => ({ Id: facility.ListItemId, Name: facility.ListItemName } as IMetaItem));
            this.hearAboutList = hearAboutList.map((obj) => ({ Id: obj.ListItemId, Name: obj.ListItemName } as IMetaItem));

            this._createForm();
            this._setCountries();
            this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
            this.title.setTitle(`Create Account`);
            this.commonService.clearMetaTags();
            this.meta.addTags([
                {
                    content: `Care Courses Register`,
                    name: 'keywords',
                },
                {
                    content: `Care Courses Create Account`,
                    name: 'description',
                },
            ]);
        });

        TopOfPage();
    }

    private _createForm(): void {
        this._getControls();
        this.customerRegistrationForm = this.fb.group({
            Customer: this.fb.group({}, { validators: [passwordsMatch, emailAddressessMatch] }),
            CustomerBilling: this.fb.group({}),
            OptToEmailMarketing: null,
        });
        this.cdr.detectChanges();
    }

    private _getControls(): void {
        this.abstractCustomerControls = new CustomerDynamicControlsPartial(null, {
            authUsers: null,
            facilityTypes: this.facilityTypes,
            formGroup: 'Customer',
            hearAboutList: this.hearAboutList,
            workCountries: this.coutriesMetaItem,
            workStates: this.statesMetaItem,
        }).Form;

        this.abstractCustomerBillingControls = new CustomerBillingDynamicControlsPartial(null, {
            countryMetaItems: this.coutriesMetaItem,
            formGroup: 'CustomerBilling',
            states: this.states,
        }).Form;
    }

    private _setCountries(): void {
        this.workCountryId = CountriesEnums.US;
        this.billingCountryId = CountriesEnums.US;
        setTimeout(() => {
            this.customerRegistrationForm.get('Customer.WorkCountryId')?.setValue(CountriesEnums.US);
            this.customerRegistrationForm.get('CustomerBilling.CountryId')?.setValue(CountriesEnums.US);
        });
    }

    // --------------- Form validity ----------------- //

    checkIfFormValid(): void {
        if (
            this.customerRegistrationForm.valid &&
            this.customerRegistrationForm.value.Customer.FacilityId !== 0 &&
            !this.zipInvalid &&
            this.customerRegistrationForm.value.OptToEmailMarketing !== null
        ) {
            const emailPasswordDTO: IEmailPasswordDTO = {
                Email: this.customerRegistrationForm.value.Customer.EmailAddress,
                Password: this.customerRegistrationForm.value.Customer.Password,
            };
            this.customerRegistrationService.checkValidEmailPassword(emailPasswordDTO).subscribe(
                () => this.confirmInformationModal.show(),
                (errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 400) {
                        this.notificationsService.error(String(errorResponse.error.Message), undefined, { enableHtml: true });
                    } else {
                        this.notificationsService.error('Loading...');
                    }
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.customerRegistrationForm);
            this.customerRegistrationForm.controls.OptToEmailMarketing.markAsTouched();
            if (this.customerRegistrationForm.controls.Customer.errors?.emailAddressessDoNotMatch) {
                this.notificationsService.error('Email addresses do not match');
            } else if (this.customerRegistrationForm.controls.Customer.errors?.passwordsDoNotMatch) {
                this.notificationsService.error('Passwords do not match');
            } else if (this.customerRegistrationForm.value.Customer.FacilityId === 0) {
                this.notificationsService.error('Please select a valid facility type');
            } else if (this.zipInvalid) {
                this.notificationsService.error(this.zipInvalid);
            } else if (this.phoneInvalid) {
                this.notificationsService.error(this.phoneInvalid);
            } else {
                this._error();
            }
        }
    }

    // --------------- Form submission ----------------- //

    formSubmitted(): void {
        if (this.customerRegistrationForm.value.Customer.FacilityId === 0) {
            this.notificationsService.error('Please select a valid facility type');
        } else if (this.customerRegistrationForm.valid) {
            const dto: ICustomerRegistrationPayload = {
                BillingAddress1: this.customerRegistrationForm.value.CustomerBilling.BillingAddress1,
                BillingAddress2: this.customerRegistrationForm.value.CustomerBilling.BillingAddress2,
                BillingAddress3: this.customerRegistrationForm.value.CustomerBilling.BillingAddress3,
                BillingAddress4: this.billingCountryIsUS
                    ? GetState(this.states, Number(this.customerRegistrationForm.value.CustomerBilling.BillingStateId)).StateCode
                    : this.customerRegistrationForm.value.CustomerBilling.BillingAddress4,
                BillingAddress5: this.billingCountryIsUS
                    ? this.customerRegistrationForm.value.CustomerBilling.USZipCode
                    : this.customerRegistrationForm.value.CustomerBilling.BillingAddress5,
                BillingCity: this.customerRegistrationForm.value.CustomerBilling.BillingCity,
                BillingcountryId: this.customerRegistrationForm.value.CustomerBilling.CountryId,
                EmailAddress: this.customerRegistrationForm.value.Customer.EmailAddress,
                FacilityId: this.customerRegistrationForm.value.Customer.FacilityId,
                FacilityName: this.customerRegistrationForm.value.Customer.CompanyName,
                FirstName: this.customerRegistrationForm.value.Customer.FirstName,
                HearAboutOtherText: this.customerRegistrationForm.value.Customer.HearAboutOther || '',
                HearAboutUsId: this.customerRegistrationForm.value.Customer.HearAboutUsId,
                HomePhone: this.phoneShowingWithExtension
                    ? CombinePhoneStrings(
                          String(this.customerRegistrationForm.value.CustomerBilling.HomePhone),
                          String(this.customerRegistrationForm.value.CustomerBilling.HomeExt),
                      )
                    : this.customerRegistrationForm.value.CustomerBilling.HomePhonePlaintext,
                LastName: this.customerRegistrationForm.value.Customer.LastName,
                MiddleName: this.customerRegistrationForm.value.Customer.MiddleName,
                Password: this.customerRegistrationForm.value.Customer.Password,
                RegistryId: this.customerRegistrationForm.value.Customer.RegistryId,
                WorkCountryId: this.customerRegistrationForm.value.Customer.WorkCountryId,
                WorkStateId: this.customerRegistrationForm.value.Customer.WorkStateId,
                OptToEmailMarketing: this.customerRegistrationForm.value.OptToEmailMarketing,
            };
            const date = new Date();
            if (this.customerRegistrationForm.value.OptToEmailMarketing == false) {
                dto.OptToEmailMarketingEndDate = date;
            } else {
                dto.OptToEmailMarketingStartDate = date;
                dto.OptToEmailMarketingEndDate = null;
            }

            this.customerRegistrationService.registerCustomer(dto).subscribe(
                (result) => {
                    this._success(dto.EmailAddress, dto.Password);
                },
                (err) => {
                    const Error: { [s: string]: unknown } = err.error;
                    this.notificationsService.error(Object.values(Error).join(), '', { enableHtml: true });
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.customerRegistrationForm);
            if (this.customerRegistrationForm.controls.Customer.errors) {
                if (this.customerRegistrationForm.controls.Customer.errors.emailAddressessDoNotMatch) {
                    this.notificationsService.error('Email addresses do not match');
                } else if (this.customerRegistrationForm.controls.Customer.errors.passwordsDoNotMatch) {
                    this.notificationsService.error('Passwords do not match');
                } else {
                    this._error();
                }
            }
        }
    }

    // --------------- Form management and manipulation ----------------- //

    newBillingCountry(countryId: number): void {
        this.billingCountryId = countryId;
        this._clearCountryFields();
    }

    newWorkCountry(countryId: number): void {
        this.workCountryId = countryId;
        setTimeout(() => this.customerRegistrationForm.get('Customer.WorkStateId')?.setValue(''));
    }

    newWorkState(stateId: number): void {
        this.workStateId = stateId;
        setTimeout(() => this.customerRegistrationForm.get('Customer.RegistryId')?.setValue(''));
    }

    newHearAboutUs(optionId: number): void {
        this.hearAboutUsAnswer = this._getMetaItemName(optionId, this.hearAboutList);
    }

    private _clearCountryFields(): void {
        setTimeout(() => {
            this.customerRegistrationForm.get('CustomerBilling.BillingAddress1')?.setValue('');
            this.customerRegistrationForm.get('CustomerBilling.BillingAddress2')?.setValue('');
            this.customerRegistrationForm.get('CustomerBilling.BillingCity')?.setValue('');
            this.customerRegistrationForm.get('CustomerBilling.BillingStateId')?.setValue('');
            this.customerRegistrationForm.get('CustomerBilling.USZipCode')?.setValue('');
            this.customerRegistrationForm.get('CustomerBilling.BillingAddress3')?.setValue('');
            this.customerRegistrationForm.get('CustomerBilling.BillingAddress4')?.setValue('');
            this.customerRegistrationForm.get('CustomerBilling.BillingAddress5')?.setValue('');
        });
    }

    formatFirstName(): void {
        const firstName = this.customerRegistrationForm.get('Customer.FirstName');
        firstName.setValue(this._capitalizeFirstLetter(String(firstName.value)));
    }

    formatMiddleName(): void {
        const middleName = this.customerRegistrationForm.get('Customer.MiddleName');
        if (middleName.value.length === 1) {
            middleName.setValue(`${String(middleName.value.toUpperCase())}.`);
        } else {
            middleName.setValue(this._capitalizeFirstLetter(String(middleName.value)));
        }
    }

    formatLastName(): void {
        const lastName = this.customerRegistrationForm.get('Customer.LastName');
        lastName.setValue(this._capitalizeFirstLetter(String(lastName.value)));
    }

    private _capitalizeFirstLetter(str: string): string {
        if (!str.length) {
            return '';
        }
        return str[0].toUpperCase() + str.substring(1);
    }

    // --------------- Misc ----------------- //

    closeModal(): void {
        this.confirmInformationModal.close();
    }

    cancel(): void {
        this.location.back();
    }

    private _success(EmailAddress: string, Password: string): void {
        this.notificationsService.success('Customer registered successfully');
        this.authService
            .login(EmailAddress, Password, false)
            .pipe(mergeMap((loginResponse) => this.shoppingCartService.convertFromAnonymousToCustomerCart(loginResponse.UserDetails.Id)))
            .subscribe(() => {
                if (this.returnUrl) {
                    void this.router.navigateByUrl(this.returnUrl);
                } else {
                    void this.router.navigate([`/customer`]);
                }
            });
    }

    private _error(): void {
        this.notificationsService.error('Please enter all required fields');
    }

    private _getMetaItemName(id: number, list: IMetaItem[]): string {
        return list.find((opt) => opt.Id === id).Name;
    }
}
