import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { MtDisableDuringHttpCallsModule  } from '@mt-ng2/disable-during-http-calls';
import { AuthUserPasswordComponent } from './auth-user/auth-user-password/auth-user-password.component';

@NgModule({
    bootstrap: [],
    declarations: [AuthUserPasswordComponent],
    exports: [AuthUserPasswordComponent],
    imports: [CommonModule, ReactiveFormsModule, DynamicFormModule, MtDisableDuringHttpCallsModule ],
})
export class AuthEntityModule {}
