import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { CommonService } from './services/common.service';

import { CardEntityListComponent } from './card-entity-list-component/card-entity-list.component';
import { PageContentComponent } from '../cms/page-content/page-content.component';
import { EntityListModule, IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { SafeHtmlPipe } from './directives/sanitize-directive';
import { DynamicFormModule, DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { MtDisableDuringHttpCallsModule  } from '@mt-ng2/disable-during-http-calls';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { AuthEntityService } from '../auth-entity/auth-entity.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ScullyNotificationsService } from './services/scully-notification.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalModule } from '@mt-ng2/modal-module';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BillingDetail } from './billing/billing-detail/billing-detail.component';
import { BillingList } from './billing/billing-list/billing-list.component';
import { ShippingDetail } from './shipping/shipping-detail/shipping-detail.component';
import { ShippingList } from './shipping/shipping-list/shipping-list.component';
import { UsOrCanadianPhonePipe } from './directives/us-phone-directive';
import { MyAccountButtons } from './my-account-buttons/my-account-buttons.component';
import { LoadingWidget } from './loading-widget/loading-widget';
import { SortOrderComponent } from './sort-order-component/sort-order-component';
import { MtPayPalModule, IMtPayPalModuleConfig, MtPayPalModuleConfigToken } from '@mt-ng2/paypal';
import { environment } from '@environment';
import { CollapsibleCardEntityListComponent } from './collapsible-card-entity-list/collapsible-card-entity-list.component';
import { BillingInfo } from './billing/billing-info.component';
import { ShippingInfo } from './shipping/shipping-info.component';
import { ShadowdomHTMLComponent } from './shadowdom-html/shadowdom-html.component';
import { ZipCodeFieldComponent } from './custom-dynamic-fields/zip-code-field/zip-code-field.component';
import { PlaintextPhoneFieldComponent } from './custom-dynamic-fields/plaintext-phone-field/plaintext-phone-field.component';
import { EmailFieldComponent } from './custom-dynamic-fields/email-field/email-field.component';
import { USCandaPhoneFieldComponent } from './custom-dynamic-fields/US-Canada-phone-field/US-Canada-phone-field.component';
import { NavModule } from '@mt-ng2/nav-module';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    declarations: [
        CardEntityListComponent,
        CollapsibleCardEntityListComponent,
        PageContentComponent,
        SafeHtmlPipe,
        UsOrCanadianPhonePipe,
        ShoppingCartComponent,
        ShippingList,
        ShippingDetail,
        ShippingInfo,
        BillingList,
        BillingDetail,
        BillingInfo,
        MyAccountButtons,
        LoadingWidget,
        SortOrderComponent,
        ZipCodeFieldComponent,
        PlaintextPhoneFieldComponent,
        USCandaPhoneFieldComponent,
        EmailFieldComponent,
        ShadowdomHTMLComponent,
    ],
    exports: [
    AuthEntityModule,
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    ModalModule,
    EntityListModule,
    CardEntityListComponent,
    CollapsibleCardEntityListComponent,
    PageContentComponent,
    SafeHtmlPipe,
    DynamicFormModule,
    MtDisableDuringHttpCallsModule,
    ModalModule,
    ShoppingCartComponent,
    ShippingList,
    ShippingInfo,
    BillingList,
    BillingInfo,
    MyAccountButtons,
    LoadingWidget,
    SortOrderComponent,
    MtPayPalModule,
    ZipCodeFieldComponent,
    PlaintextPhoneFieldComponent,
    USCandaPhoneFieldComponent,
    EmailFieldComponent,
    ShadowdomHTMLComponent,
    NavModule,
],
    imports: [
    AuthEntityModule,
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    ModalModule,
    EntityListModule,
    DynamicFormModule,
    MtDisableDuringHttpCallsModule,
    MatSlideToggleModule,
    ModalModule,
    NgSelectModule,
    MtPayPalModule,
    NavModule,
],
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                AuthEntityService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: NotificationsService, useClass: ScullyNotificationsService },
                { provide: MtPayPalModuleConfigToken, useValue: { clientId: environment.paypalClientId } as IMtPayPalModuleConfig },
                UsOrCanadianPhonePipe,
            ],
        };
    }
}
