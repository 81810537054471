import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ICustomerAddressDTO } from '@model/DTO/customer-address-dto';
import { ICustomerShipping } from '@model/interfaces/customer-shipping';

export const emptyCustomerShipping: ICustomerShipping = {
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class CustomerShippingAddressesService {
    constructor(public http: HttpClient) {}

    getEmptyCustomerShipping(): ICustomerShipping {
        return { ...emptyCustomerShipping };
    }

    getAddresses(): Observable<ICustomerShipping[]> {
        return this.http.get<ICustomerShipping[]>(`/customers/shipping/_search`);
    }

    getAddressById(id: number): Observable<ICustomerShipping> {
        if (id > 0) {
            return this.http.get<ICustomerShipping>(`/customers/shipping/${id}`);
        } else {
            return of(this.getEmptyCustomerShipping());
        }
    }

    saveAddress(address: ICustomerShipping): Observable<number> {
        const clone = { ...address };
        this.nullOutFKs(clone);
        clone.Id = clone.Id || 0;
        return this.http.put<number>(`/customers/shipping`, clone, {
            responseType: 'text' as 'json',
        });
    }

    deleteAddress(addressId: number): Observable<object> {
        return this.http.delete(`/customers/shipping/${addressId}`, { responseType: 'text' as 'json' });
    }

    private nullOutFKs(object: any): any {
        for (const prop in object) {
            if (typeof object[prop] === 'object') {
                object[prop] = null;
            }
        }
        return object;
    }
}
