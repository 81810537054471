<div *ngIf="customerRegistrationForm" class="container">
    <br />
    <form class="miles-card padded" [formGroup]="customerRegistrationForm">
        <h1>Create New Account</h1>

        <!----------------------------------- User info ----------------------------------->
        <hr />
        <!-- Preliminary Info -->
        <mt-dynamic-field [field]="abstractCustomerControls.CompanyName" [form]="customerRegistrationForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerControls.FacilityId" [form]="customerRegistrationForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerControls.FirstName" [form]="customerRegistrationForm" (customBlur)="formatFirstName()"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerControls.MiddleName" [form]="customerRegistrationForm" (customBlur)="formatMiddleName()"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerControls.LastName" [form]="customerRegistrationForm" (customBlur)="formatLastName()"></mt-dynamic-field>

        <!----------------------------------- Addresses ----------------------------------->
        <br />
        <h3>Location</h3>
        <hr />

        <!-- Work Address -->
        <mt-dynamic-field
            [field]="abstractCustomerControls.WorkCountryId"
            [form]="customerRegistrationForm"
            (valueChanges)="newWorkCountry($event)"
        ></mt-dynamic-field>
        <mt-dynamic-field
            *ngIf="workCountryIsUS"
            [field]="abstractCustomerControls.WorkStateId"
            [form]="customerRegistrationForm"
            (valueChanges)="newWorkState($event)"
        ></mt-dynamic-field>
        <mt-dynamic-field *ngIf="allowRegistryId" [field]="abstractCustomerControls.RegistryId" [form]="customerRegistrationForm"></mt-dynamic-field>

        <!-- Billing Address -->
        <mt-dynamic-field
            [field]="abstractCustomerBillingControls.CountryId"
            [form]="customerRegistrationForm"
            (valueChanges)="newBillingCountry($event)"
        ></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress1" [form]="customerRegistrationForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress2" [form]="customerRegistrationForm"></mt-dynamic-field>
        <ng-container *ngIf="billingCountryIsUS">
            <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingCity" [form]="customerRegistrationForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingStateId" [form]="customerRegistrationForm"></mt-dynamic-field>

            <zip-code-dynamic-field
                [field]="abstractCustomerBillingControls.USZipCode"
                [form]="customerRegistrationForm"
                [fieldName]="'CustomerBilling.USZipCode'"
                (invalidReason)="zipInvalid = $event"
            ></zip-code-dynamic-field>
        </ng-container>
        <ng-container *ngIf="!billingCountryIsUS">
            <ng-container *ngIf="!billingCountryIsCanada">
                <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress3" [form]="customerRegistrationForm"></mt-dynamic-field>
            </ng-container>
            <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingCity" [form]="customerRegistrationForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress4" [form]="customerRegistrationForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress5" [form]="customerRegistrationForm"></mt-dynamic-field>
        </ng-container>

        <!-- Phone Number -->
        <div *ngIf="phoneShowingWithExtension" class="phone-number">
            <us-canada-phone-dynamic-field
                [field]="abstractCustomerBillingControls.HomePhone"
                [form]="customerRegistrationForm"
                [fieldName]="'CustomerBilling.HomePhone'"
                [required]="true"
                (invalidReason)="phoneInvalid = $event"
            ></us-canada-phone-dynamic-field>
            <mt-dynamic-field class="extension" [field]="abstractCustomerBillingControls.HomeExt" [form]="customerRegistrationForm"></mt-dynamic-field>
        </div>
        <plaintext-phone-dynamic-field
            *ngIf="!phoneShowingWithExtension"
            [field]="abstractCustomerBillingControls.HomePhonePlaintext"
            [form]="customerRegistrationForm"
            [fieldName]="'CustomerBilling.HomePhonePlaintext'"
        ></plaintext-phone-dynamic-field>

        <!----------------------------------- Account info ----------------------------------->
        <br />
        <h3>Account</h3>
        <hr />
        <email-dynamic-field
            [field]="abstractCustomerControls.EmailAddress"
            [form]="customerRegistrationForm"
            [fieldName]="'Customer.EmailAddress'"
        ></email-dynamic-field>
        <!-- <mt-dynamic-field [field]="abstractCustomerControls.EmailAddress" [form]="customerRegistrationForm"></mt-dynamic-field> -->
        <mt-dynamic-field [field]="abstractCustomerControls.ConfirmEmailAddress" [form]="customerRegistrationForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerControls.Password" [form]="customerRegistrationForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerControls.ConfirmPassword" [form]="customerRegistrationForm"></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractCustomerControls.HearAboutUsId"
            [form]="customerRegistrationForm"
            (valueChanges)="newHearAboutUs($event)"
        ></mt-dynamic-field>
        <mt-dynamic-field *ngIf="hearAboutUsIsOther" [field]="abstractCustomerControls.HearAboutOther" [form]="customerRegistrationForm"></mt-dynamic-field>
        <p style="font-size: 1rem">
            <span style="color: red">*</span>Click “yes" to receive emailed newsletters and other important updates and insights!
        </p>
        <div>
            <div *ngIf="abstractCustomerControls?.OptToEmailMarketing">
                <label>
                    <input type="radio" formControlName="OptToEmailMarketing" [value]="true" />
                    Yes
                </label>
                <label>
                    <input type="radio" formControlName="OptToEmailMarketing" [value]="false" />
                    No
                </label>
            </div>
            <p style="font-size: 0.85rem">
                You can unsubscribe at any time using the link at the bottom of our promotional emails. We value your privacy and will never sell any
                of your information. Click “no” to opt out of our newsletter and other promotional emails. (You will still receive emails related to
                your orders and courses.)
            </p>
        </div>

        <!----------------------------------- buttons ----------------------------------->
        <br />
        <button class="btn btn-secondary" type="button" (click)="cancel()">Cancel</button>
        <button
            class="btn btn-primary btn-success float-right"
            type="submit"
            (click)="checkIfFormValid()"
            mtDisableClickDuringHttpCalls
        >
            Next
        </button>
    </form>
    <br />
    <p><b class="errortext">* </b>Indicates required field</p>
</div>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="true"
    [options]="confirmInformationModalOptions"
    [allowOutsideClick]="true"
    (ready)="confirmInformationModal = $event"
    (cancelClick)="closeModal()"
    (okClick)="formSubmitted()"
>
    <div>
        Please enter your information carefully and completely. Include your middle initial or middle name, and do not use all-caps or all lower case.
        To change the appearance of your name once you have created an account, you must call or email our office.
    </div>
</mt-modal-wrapper>
