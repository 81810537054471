import { Component, OnInit } from '@angular/core';
import { ImageAltAndTitleText } from '@common/constants/Enums';
import { environment } from '@environment';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { ShoppingCartService } from '../common/shopping-cart/shopping-cart.service';
import { CustomerService } from '../customers/services/customer.service';
import { ICustomer } from '../model/interfaces/customer';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    styleUrls: ['./header.component.less'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
    currentUser: ILoggedIn;
    currentCustomer: ICustomer;
    isAdmin = false;
    environment = environment;
    imageAltAndTitleTextEnum = ImageAltAndTitleText;

    get isLoggedIn(): boolean {
        return this.currentUser ? true : false;
    }

    constructor(
        private authService: AuthService,
        private customerService: CustomerService,
        private cartService: ShoppingCartService,
        private angularLocation: Location,
        private router: Router,
    ) {}

    ngOnInit(): void {
        const loggedInasObeservable = this.authService.currentUser.asObservable();
        loggedInasObeservable
            .pipe(
                tap((user: ILoggedIn) => {
                    this.currentUser = user.Id ? user : null;
                    this.isAdmin = this.currentUser?.CustomOptions?.IsAdmin;
                }),
                delay(1), // Wait to allow user to be authenticated
                switchMap((_) => {
                    if (this.currentUser) {
                        return this.customerService.getInfo();
                    }
                    return of<ICustomer>(null);
                }),
            )
            .subscribe((customer) => (this.currentCustomer = customer));
    }

    logout(event: any): void {
        event.preventDefault();
        this.authService.logout();
        this.cartService.resetShoppingCartService();
    }

    goToShoppingCart(): void {
        const currentLocation = this.angularLocation.path();
        if (!currentLocation.includes('cart')) {
            void this.router.navigate(['/cart'], { queryParams: { returnUrl: currentLocation } });
        }
    }

    getCurrentUserName(): string {
        const firstName = this.currentCustomer.FirstName === null ? '' : this.currentCustomer.FirstName;
        const lastName = this.currentCustomer.LastName === null ? '' : this.currentCustomer.LastName;
        return `${firstName} ${lastName} (ID# ${this.currentCustomer.CustomerId})`;
    }
}
