import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { UrlSerializer } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { AppError, BreckErrorHandler, ErrorsModuleConfigToken, IErrorsModuleConfig, IToastableError } from '@mt-ng2/errors-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UrlSerializer } from '@angular/router';
import { environment } from '@environment';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { AdminAccessComponent } from './admin-access/admin-access.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CatalogRequestComponent } from './catalog-request/catalog-request.component';
import { PageTemplateComponent } from './cms/page-template/page-template.component';
import { SharedModule } from './common/shared.module';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { EnvironmentAlertComponent } from './environment-alert.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './header/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LowerCaseUrlSerializer } from './lowerCaseUrl';
import { NotFoundComponent } from './not-found/not-found.component';
import { PingPageComponent } from './ping-page/ping-page.component';
import { PublicPageComponent } from './public-page/public-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CookieModule } from '@mt-ng2/cookie';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import {CookieService} from 'ngx-cookie-service';

const errorConfig: IErrorsModuleConfig = {
    customErrorHandlerFunction: (error: AppError): IToastableError => {
        return {
            errorMessage: 'Loading...',
            notificationType: 3,
        };
    },
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        PageTemplateComponent,
        MenuComponent,
        CustomerRegistrationComponent,
        EnvironmentAlertComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        AdminAccessComponent,
        PublicPageComponent,
        CatalogRequestComponent,
        NotFoundComponent,
        PingPageComponent,
    ],
    imports: [
        SharedModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule,
        NotificationsModule,
        AuthModule.forRoot(),
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-full-width',
        }),
        ScullyLibModule.forRoot({
            alwaysMonitor: true,
        }),
    MtDisableDuringHttpCallsModule.forRoot(), MtDisableDuringHttpCallsModule.forRoot(),],
    providers: [
        CookieService,
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: ErrorsModuleConfigToken, useValue: errorConfig },
        { provide: 'googleTagManagerId', useValue: environment.gtmId },
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
		{ provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {}
