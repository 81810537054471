import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { AdminAccessService } from './admin-access.service';

@Component({
    selector: 'app-admin-access',
    templateUrl: './admin-access.component.html',
})
export class AdminAccessComponent implements OnInit, OnDestroy {
    resetPasswordForm: UntypedFormGroup;
    resetKey: string;
    customerId: number;
    adminUserId: number;
    orderId:number;
    badKeyError = `Oops something went wrong!
    - Probably the key has been used or expired.
    - Or you did something you weren't supposed to do.
    - Your best bet is to just retry.`;

    protected authService: AuthService;
    protected router: Router;
    public route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected adminAccessService: AdminAccessService;
    private _subscription = new Subscription();

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.adminAccessService = injector.get(AdminAccessService);
    }

    ngOnInit(): void {
        this._subscription.add(
            this.route.queryParams.subscribe((params) => {
                this.resetKey = params.resetKey;
                this.customerId = params.customerId;
                this.adminUserId = params.adminUserId;
                this.orderId = params.orderId;
            }),
        );
        this.adminAccessService.adminAccess(this.resetKey, this.customerId, this.adminUserId).subscribe(
            (answer) => {
                this.success();
                if(this.orderId){
                    void this.router.navigate([`customer/order/${this.orderId}`]);
                }else{
                    void this.router.navigate(['/customer/info']);
                }
            },
            (error) => {
                if (error.status === 418) {
                    this.notificationsService.error('Access link is invalid');
                    // eslint-disable-next-line no-console
                    console.error(this.badKeyError);
                } else if (error.status === 400) {
                    // TODO: Can we just inject the error service?
                    if (error.error) {
                        this.error(String(error.error.ModelState.Service));
                    } else {
                        this.error('Something went wrong. Please generate a new access email');
                        // eslint-disable-next-line no-console
                        console.error(this.badKeyError);
                    }
                }
                void this.router.navigate(['/home']);
            },
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }


    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        // Do nothing
    }
}
