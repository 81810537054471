import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ICustomerAddressDTO } from '@model/DTO/customer-address-dto';
import { ICustomerBilling } from '@model/interfaces/customer-billing';

export const emptyCustomerBilling: ICustomerBilling = {
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class CustomerBillingAddressesService {
    constructor(public http: HttpClient) {}

    getEmptyCustomerBilling(): ICustomerBilling {
        return { ...emptyCustomerBilling };
    }

    getAddresses(): Observable<ICustomerBilling[]> {
        return this.http.get<ICustomerBilling[]>(`/customers/billing/_search`);
    }

    getAddressById(id: number): Observable<ICustomerBilling> {
        if (id > 0) {
            return this.http.get<ICustomerBilling>(`/customers/billing/${id}`);
        } else {
            return of(this.getEmptyCustomerBilling());
        }
    }

    saveAddress(address: ICustomerBilling): Observable<number> {
        const clone = { ...address };
        this.nullOutFKs(clone);
        clone.Id = clone.Id || 0;
        return this.http.put<number>(`/customers/billing`, clone, {
            responseType: 'text' as 'json',
        });
    }

    deleteAddress(addressId: number): Observable<object> {
        return this.http.delete(`/customers/billing/${addressId}`, { responseType: 'text' as 'json' });
    }

    private nullOutFKs(object: any): any {
        for (const prop in object) {
            if (typeof object[prop] === 'object') {
                object[prop] = null;
            }
        }
        return object;
    }

}
