import { Validators } from '@angular/forms';
import { IMetaItem } from '@mt-ng2/base-service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { CountriesEnums } from '../../common/constants/Enums';
import { GetState, InternationalPhoneMask, SplitPhoneString } from '../../common/functions/common-functions.library';
import { CustomerShippingDynamicControls, ICustomerShippingDynamicControlsParameters } from '../form-controls/customer-shipping.form-controls';
import { ICustomerShipping } from '../interfaces/customer-shipping';
import { IState } from '../interfaces/state';

export interface ICustomerShippingDynamicControlsParametersPartial extends ICustomerShippingDynamicControlsParameters {
    countryMetaItems?: IMetaItem[];
    states?: IState[];
}

export class CustomerShippingDynamicControlsPartial extends CustomerShippingDynamicControls {
    constructor(customerShipping?: ICustomerShipping, additionalParameters?: ICustomerShippingDynamicControlsParametersPartial) {
        super(customerShipping, additionalParameters);

        // Set countries dropdown
        this.Form.CountryId.options = additionalParameters.countryMetaItems;

        // US states dropdown -- Additional field for when Country is US
        this._setStateDropdown(customerShipping, additionalParameters);

        // Zip code info
        this._setZipCode(customerShipping);

        // Phone number info
        this._setPhonesAndExtensions(customerShipping);

        // Misc
        this._setLabels();
        this._setRequireds();
        this._makeTextboxes();
    }

    private _setStateDropdown(customerShipping: ICustomerShipping, additionalParameters?: ICustomerShippingDynamicControlsParametersPartial): void {
        const statesMetaItems = additionalParameters.states.map((state) => ({ Id: state.StateId, Name: state.StateDesc } as IMetaItem));
        const stateId = customerShipping?.CountryId === CountriesEnums.US && customerShipping.Id ? GetState(additionalParameters.states, undefined, customerShipping?.ShippingAddress4).StateId : null;
        this.Form.ShippingStateId = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'ShippingStateId',
            options: statesMetaItems,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: {required: true},
            value: stateId,
        });
    }

    private _setZipCode(customerShipping: ICustomerShipping): void {
        this.Form.USZipCode = new DynamicField({
            formGroup: this.formGroup,
            label: 'Postal/Zip Code',
            name: 'USZipCode',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(10), Validators.minLength(5), Validators.required ],
            validators: { 'maxlength': 10, 'minlength': 5, required: true, showRequired: true },
            value: customerShipping?.ShippingAddress5,
        });
    }

    private _setPhonesAndExtensions(customerShipping: ICustomerShipping): void {
        this.Form.HomePhone.type.inputType = InputTypes.Phone;
        this.Form.WorkPhone.type.inputType = InputTypes.Phone;
        this.Form.CellPhone.type.inputType = InputTypes.Phone;

        let homePhone = SplitPhoneString(customerShipping?.HomePhone);
        let workPhone = SplitPhoneString(customerShipping?.WorkPhone);

        // Home phone
        this.Form.HomePhone.value = homePhone.Phone;
        this.Form.HomePhone.validation = [ Validators.minLength(10), Validators.required ];
        this.Form.HomePhone.validators = { minlength: 10, required: true, showRequired: true };

        this.Form.HomePhonePlaintext = new DynamicField({
            formGroup: this.formGroup,
            label: 'Primary Phone Number',
            name: 'HomePhonePlaintext',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(50), Validators.required, Validators.pattern(InternationalPhoneMask) ],
            validators: { 'maxlength': 50, required: true, showRequired: true, 'pattern': InternationalPhoneMask },
            value: homePhone.Phone,
        });

        this.Form.HomeExt = new DynamicField({
            formGroup: this.formGroup,
            label: 'Ext.',
            name: 'HomeExt',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(4), Validators.pattern(InternationalPhoneMask)],
            validators: { maxlength: 4, 'pattern': InternationalPhoneMask },
            value: homePhone.Extension,
        });

        // Work phone
        this.Form.WorkPhone.value = workPhone.Phone;
        this.Form.WorkPhone.validation = [ Validators.minLength(10) ];
        this.Form.WorkPhone.validators = { minlength: 10 };

        this.Form.WorkPhonePlaintext = new DynamicField({
            formGroup: this.formGroup,
            label: 'Work Phone',
            name: 'WorkPhonePlaintext',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(50), Validators.pattern(InternationalPhoneMask) ],
            validators: { 'maxlength': 50, 'pattern': InternationalPhoneMask },
            value: workPhone.Phone,
        });

        this.Form.WorkExt = new DynamicField({
            formGroup: this.formGroup,
            label: 'Ext.',
            name: 'WorkExt',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(4), Validators.pattern(InternationalPhoneMask)],
            validators: { maxlength: 4, 'pattern': InternationalPhoneMask },
            value: workPhone.Extension,
        });

        // Cell phone
        this.Form.CellPhone.validation = [ Validators.minLength(10) ];
        this.Form.CellPhone.validators = { minlength: 10 };

        this.Form.CellPhonePlaintext = new DynamicField({
            formGroup: this.formGroup,
            label: 'Cell Phone',
            name: 'CellPhonePlaintext',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(50), Validators.pattern(InternationalPhoneMask) ],
            validators: { 'maxlength': 50, 'pattern': InternationalPhoneMask },
            value: customerShipping?.CellPhone,
        });
    }

    private _setLabels(): void {
        this.Form.ShippingAddress1.labelHtml = '<label>Address Line 1</label>';
        this.Form.ShippingAddress2.labelHtml = '<label>Address Line 2</label>';
        this.Form.ShippingAddress3.labelHtml = '<label>Address Line 3</label>';
        this.Form.ShippingCity.labelHtml = '<label>City</label>';
        this.Form.ShippingAddress4.labelHtml = '<label>Province</label>';
        this.Form.ShippingAddress5.labelHtml = '<label>Postal/Zip Code</label>';
        this.Form.CompanyName.labelHtml = '<label>Facility Name</label>';
        this.Form.HomePhone.labelHtml = '<label>Primary Phone</label>';
    }

    private _setRequireds(): void {
        this.Form.FirstName.setRequired(true);
        this.Form.LastName.setRequired(true);
        this.Form.HomePhone.setRequired(true);
        this.Form.ShippingAddress1.setRequired(true);
        this.Form.ShippingCity.setRequired(true);
        this.Form.ShippingStateId.setRequired(true);
        this.Form.ShippingAddress4.setRequired(true);
        this.Form.ShippingAddress5.setRequired(true);
        this.Form.CountryId.setRequired(true);
    }

    private _makeTextboxes(): void {
        this.Form.FirstName.type.inputType = InputTypes.Textbox;
        this.Form.MiddleName.type.inputType = InputTypes.Textbox;
        this.Form.LastName.type.inputType = InputTypes.Textbox;
        this.Form.CompanyName.type.inputType = InputTypes.Textbox;
        this.Form.ShippingAddress1.type.inputType = InputTypes.Textbox;
        this.Form.ShippingAddress2.type.inputType = InputTypes.Textbox;
        this.Form.ShippingAddress3.type.inputType = InputTypes.Textbox;
        this.Form.ShippingAddress4.type.inputType = InputTypes.Textbox;
        this.Form.ShippingAddress5.type.inputType = InputTypes.Textbox;
        this.Form.ShippingCity.type.inputType = InputTypes.Textbox;
    }

}
