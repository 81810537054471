<h4>Password</h4>
<a class="float-right" style="cursor: pointer;" (click)="ShowPassword()">
    {{ isShowingPassword ? 'Hide Password' : 'Show Password' }}
</a>
<br>

<mt-dynamic-form *ngIf="showForm" [config]="formObject" (submitted)="formSubmitted($event)" (formCreated)="saveCreatedForm($event)">
    <div class="float-multiple-right">
        <button type="button" Class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button
            type="submit"
            mtDisableClickDuringHttpCalls
            Class="btn btn-primary btn-success"
            style="margin-left: 10px"
        >
            Save
        </button>
    </div>
</mt-dynamic-form>
