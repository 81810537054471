import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@environment';
import { CommonService } from '../common/services/common.service';

@Component({
    selector: 'app-home',
    styleUrls: ['./home.component.less'],
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    constructor(
        private title: Title,
        private meta: Meta,
        private commonService: CommonService,
        private location: Location,
        private renderer: Renderer2,
         private el: ElementRef
    ) {}

    ngOnInit(): void {
        this.title.setTitle(`Care Courses`);
        this.commonService.clearMetaTags();
        this.meta.addTags([
            {
                content: `The Care Courses School`,
                name: 'keywords',
            },
            {
                content: `Care Courses Home Page`,
                name: 'description',
            },
        ]);
        const link: HTMLLinkElement = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'preload');
        this.renderer.setAttribute(link, 'as', 'image');
        this.renderer.setAttribute(link, 'fetchpriority', 'high');
        this.renderer.setAttribute(link, 'href', `${environment.adminSite}/UploadedImages/Images/Home_Page_Photos/hero-banner.webp`);
        this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, link);
        // This is to remove any query params from the URL
        // /home?ItemID=29438475932874583745
        // https://stackoverflow.com/a/52227937
        this.location.replaceState(this.location.path().split('?')[0], "");
    }
}
