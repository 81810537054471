    import { DefaultUrlSerializer, UrlTree } from '@angular/router';

    // FROM: https://stackoverflow.com/questions/51985973/in-angular-6-how-make-case-insensitive-url-pattern
    // ALSO SEE: https://stackoverflow.com/questions/36154672/angular2-make-route-paths-case-insensitive/39560520#39560520
    export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
        parse(url: string): UrlTree {
            // Optional Step: Do some stuff with the url if needed.
            const urlParts = url.split('?');
            urlParts[0] = urlParts[0].toLowerCase();

            // If you lower it in the optional step
            // you don't need to use "toLowerCase"
            // when you pass it down to the next function
            return super.parse(urlParts.join('?'));
        }
    }
