import { Component, EventEmitter, Input, Output } from '@angular/core';
// tslint:disable: object-literal-sort-keys

@Component({
    selector: 'email-dynamic-field',
    templateUrl: './email-field.component.html',
})
export class EmailFieldComponent {
    @Input() field: any;
    @Input() form: any;
    @Input() fieldName: any;
    @Input() required: boolean;
    @Output() invalidReason = new EventEmitter<string>();
    emailInvalid: boolean;
    errorMessage = 'Please enter a valid email address';

    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    checkEmailValidity(): void {
        const emailDynamicField = this.form.get(this.fieldName);
        if (emailDynamicField.invalid) {
            this.emailInvalid = true;
            this.invalidReason.emit(this.emailInvalid ? this.errorMessage : null);
        }
    }
}
