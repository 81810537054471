import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '@environment';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        // replace all relative image links to point to the admin site
        const adminSite = environment.adminSite;
        value = value.replace(/\.\.\/UploadedImages/gi, adminSite + '/UploadedImages');
        value = value.replace(/"\/UploadedImages/gi, '"' + adminSite + '/UploadedImages');
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    toHTML(input): any {
        return new DOMParser().parseFromString(String(input), 'text/html').documentElement.textContent;
    }
}
