import { Component, EventEmitter, Input, Output } from '@angular/core';
// tslint:disable: object-literal-sort-keys

@Component({
    selector: 'us-canada-phone-dynamic-field',
    templateUrl: './US-Canada-phone-field.component.html',
})
export class USCandaPhoneFieldComponent {
    @Input() field: any;
    @Input() form: any;
    @Input() fieldName: any;
    @Input() required: boolean;
    @Output() invalidReason = new EventEmitter<string>();
    phoneNumInvalid: boolean;
    errorMessage = 'Phone number must be 10 digits';

    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    checkPhoneNumberValidity(): void {
        const phoneNum = this.form.get(this.fieldName).value;
        // If phone length is 10 we're good -- if field is not required, 0 is also good
        const isValid = phoneNum.length === 10 || (!this.required && phoneNum.length === 0);
        this.phoneNumInvalid = !isValid;
        this.invalidReason.emit(this.phoneNumInvalid ? this.errorMessage : null);
    }
}
