import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotesOnHoldColorChangeService } from '@customers/services/notes-on-hold-color.service';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { WindowSizeService } from './common/services/window-size.service';
import { environment } from '@environment';

@Component({
    selector: 'app-root',
    template: `
        <div>
            <div class="app-root-wrapper" [ngClass]="{ adminClass: this.notesColorChangeService.isColorChanged }">
                <app-header></app-header>
                <ng-progress></ng-progress>
                <app-environment-alert></app-environment-alert>
                <div id="top-of-page-content"></div>
                <div style="min-height: 100vh;">
                    <router-outlet></router-outlet>
                </div>
                <app-footer></app-footer>
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    adminNotesBackgroundColor = false;
    newUserListener: (this: Window, ev: StorageEvent) => void;
    private _subscriptions = new Subscription();

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this._setWindowSize();
    }

    constructor(
        private windowSizeService: WindowSizeService,
        private _authService: AuthService,
        public notesColorChangeService: NotesOnHoldColorChangeService,
        private gtmService: GoogleTagManagerService,
        private router: Router,
    ) {
        this.newUserListener = this._newUserSignIn.bind(this);
        // Add a localStorage listener -- when localStorage changes, the function bound to newUserListner fires
        window.addEventListener('storage', this.newUserListener);
        try {
            if (environment.gtmId !== 'GTM-TWNJB') {
                void this.router.events.forEach((item) => {
                    if (item instanceof NavigationEnd) {
                        const gtmTag = {
                            event: 'page',
                            pageName: item.url,
                        };
                        void this.gtmService.pushTag(gtmTag).catch((error) => {
                            // eslint-disable-next-line no-console
                            console.log("Error in GTM Service: ", error);
                        });
                    }
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("Error in GTM Service: ", error);
        }

    }

    ngOnInit(): void {
        this._setWindowSize();
        const loggedInasObeservable = this._authService.currentUser.asObservable();

        this._subscriptions.add(
            loggedInasObeservable.pipe(skip(1)).subscribe((newUser: ILoggedIn) => {
                this._localStorageNewUser(newUser.AuthId);
            }),
        );
    }

    ngOnDestroy(): void {
        window.removeEventListener('storage', this.newUserListener);
        this._subscriptions.unsubscribe();
    }

    private _newUserSignIn(event: StorageEvent): void {
        if (event.key === 'newUserSignIn' && event.newValue !== event.oldValue) {
            location.reload();
        }
    }

    private _localStorageNewUser(userId: number): void {
        const x = localStorage.getItem('newUserSignIn');
        if (+x !== userId) {
            localStorage.setItem('newUserSignIn', String(userId) + '');
        }
    }

    private _setWindowSize(): void {
        this.windowSizeService.updateWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    }
}
