import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CountriesEnums } from '@common/constants/Enums';
import { AnnounceBillingShippingService } from '@common/services/announce-billing-shipping.service';
import { CommonService } from '@common/services/common.service';
import { CustomerBillingAddressesService } from '@customers/services/customer-billing-addresses.service';
import { CustomerShippingAddressesService } from '@customers/services/customer-shipping-addresses.service';
import { ICountry } from '@model/interfaces/country';
import { ICustomerBilling } from '@model/interfaces/customer-billing';
import { ICustomerShipping } from '@model/interfaces/customer-shipping';
import { CustomerBillingDynamicControlsPartial } from '@model/partials/customer-billing-partial.form-controls';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { IExpandableObject } from '../../../model/expandable-object';
import { IState } from '../../../model/interfaces/state';
import { CombinePhoneStrings, GetState } from '../../functions/common-functions.library';

@Component({
    selector: 'app-billing-detail',
    templateUrl: './billing-detail.component.html',
})
export class BillingDetail implements OnInit, AfterViewChecked {
    @Input() customerBilling: ICustomerBilling;
    @Output('onComplete') completeEmitter = new EventEmitter();
    @Output('onCancel') cancelEmitter = new EventEmitter();
    @Output('onNewPrimary') onNewPrimaryEmitter = new EventEmitter<ICustomerBilling>();

    abstractCustomerBillingControls: IExpandableObject;
    customerBillingForm: UntypedFormGroup;
    countries: ICountry[];
    states: IState[];
    doubleClickIsDisabled = false;
    saveAsShippingAddress = false;
    countryId: number;
    zipInvalid: string;
    homePhoneInvalid: string;
    workPhoneInvalid: string;
    cellPhoneInvalid: string;
    wasPrimaryOnLoad: boolean;

    get billingCountryIsUS(): boolean {
        return this.countryId === CountriesEnums.US;
    }
    get billingCountryIsCanada(): boolean {
        return this.countryId === CountriesEnums.Canada;
    }
    get phoneShowingWithExtension(): boolean {
        return this.billingCountryIsUS || this.billingCountryIsCanada;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private commonService: CommonService,
        private notificationsService: NotificationsService,
        private customerBillingAddressesService: CustomerBillingAddressesService,
        private customerShippingAddressesService: CustomerShippingAddressesService,
        private announceBillingShippingService: AnnounceBillingShippingService,
    ) {}

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    ngOnInit(): void {
        this.wasPrimaryOnLoad = this.customerBilling.IsPrimary;
        this.countryId = this.customerBilling.CountryId;
        forkJoin([this.commonService.getCountries(), this.commonService.getStates()]).subscribe(([countries, states]) => {
            this.countries = countries;
            this.states = states;
            this._createForm();
            const countryControl = this.customerBillingForm.get('CustomerBilling.CountryId');
            if (!countryControl.value) {
                countryControl.setValue(CountriesEnums.US);
            }
        });
    }

    newCountry(countryId: number): void {
        this.countryId = countryId;
        this._clearCountryFields();
    }

    private _clearCountryFields(): void {
        setTimeout(() => {
            this.customerBillingForm.get('CustomerBilling.BillingAddress1')?.setValue('');
            this.customerBillingForm.get('CustomerBilling.BillingAddress2')?.setValue('');
            this.customerBillingForm.get('CustomerBilling.BillingCity')?.setValue('');
            this.customerBillingForm.get('CustomerBilling.BillingStateId')?.setValue('');
            this.customerBillingForm.get('CustomerBilling.USZipCode')?.setValue('');
            this.customerBillingForm.get('CustomerBilling.BillingAddress3')?.setValue('');
            this.customerBillingForm.get('CustomerBilling.BillingAddress4')?.setValue('');
            this.customerBillingForm.get('CustomerBilling.BillingAddress5')?.setValue('');
        });
    }

    formSubmitted(): void {
        if (this.customerBillingForm.valid && !this.zipInvalid) {
            Object.assign(this.customerBilling, this.customerBillingForm.value.CustomerBilling);
            this.customerBilling.BillingAddress3 = this.customerBillingForm.value.CustomerBilling.BillingAddress3 || '';
            if (this.billingCountryIsUS || this.billingCountryIsCanada) {
                this.customerBilling.WorkPhone = CombinePhoneStrings(
                    String(this.customerBillingForm.value.CustomerBilling.WorkPhone),
                    String(this.customerBillingForm.value.CustomerBilling.WorkExt),
                );
                this.customerBilling.HomePhone = CombinePhoneStrings(
                    String(this.customerBillingForm.value.CustomerBilling.HomePhone),
                    String(this.customerBillingForm.value.CustomerBilling.HomeExt),
                );
                this.customerBilling.CellPhone = this.customerBillingForm.value.CustomerBilling.CellPhone;
                if (this.billingCountryIsUS) {
                    this.customerBilling.BillingAddress4 = GetState(
                        this.states,
                        Number(this.customerBillingForm.value.CustomerBilling.BillingStateId),
                    ).StateCode;
                    this.customerBilling.BillingAddress5 = this.customerBillingForm.value.CustomerBilling.USZipCode;
                }
            } else {
                this.customerBilling.WorkPhone = this.customerBillingForm.value.CustomerBilling.WorkPhonePlaintext;
                this.customerBilling.HomePhone = this.customerBillingForm.value.CustomerBilling.HomePhonePlaintext;
                this.customerBilling.CellPhone = this.customerBillingForm.value.CustomerBilling.CellPhonePlaintext;
            }

            this.customerBillingAddressesService.saveAddress(this.customerBilling).subscribe((id) => {
                this.customerBilling.Id = id;
                this.customerBilling.Country = this.countries.find((c) => c.CountryId === this.customerBilling.CountryId);
                if (this.saveAsShippingAddress) {
                    this._copyToShippingAddress();
                }
                if (this.customerBilling.IsPrimary) {
                    this.onNewPrimaryEmitter.emit(this.customerBilling);
                }

                if (!id) {
                    this.notificationsService.warning('This address has already been added');
                    this.cancel();
                } else {
                    this._success();
                }
            });
        } else {
            markAllFormFieldsAsTouched(this.customerBillingForm);
            if (this.zipInvalid) {
                this.notificationsService.error(this.zipInvalid);
            } else if (this.homePhoneInvalid) {
                this.notificationsService.error(this.homePhoneInvalid);
            } else if (this.workPhoneInvalid) {
                this.notificationsService.error(this.workPhoneInvalid);
            } else if (this.cellPhoneInvalid) {
                this.notificationsService.error(this.cellPhoneInvalid);
            } else if (this._formPhoneIssue) {
                this._error('Please check phone number');
            } else {
                this._error();
            }
        }
    }

    primaryClicked(isChecked: boolean): void {
        setTimeout(() => {
            if (this.wasPrimaryOnLoad && !isChecked) {
                this.notificationsService.warning('You must have one primary address');
                this.customerBilling.IsPrimary = true;
            }
        });
    }

    private get _formPhoneIssue(): boolean {
        return (
            (this.customerBillingForm.get('CustomerBilling.HomePhonePlaintext') &&
                !this.customerBillingForm.get('CustomerBilling.HomePhonePlaintext').valid) ||
            (this.customerBillingForm.get('CustomerBilling.HomeExt') && !this.customerBillingForm.get('CustomerBilling.HomeExt').valid) ||
            (this.customerBillingForm.get('CustomerBilling.WorkPhonePlaintext') &&
                !this.customerBillingForm.get('CustomerBilling.WorkPhonePlaintext').valid) ||
            (this.customerBillingForm.get('CustomerBilling.WorkExt') && !this.customerBillingForm.get('CustomerBilling.WorkExt').valid) ||
            (this.customerBillingForm.get('CustomerBilling.CellPhonePlaintext') &&
                !this.customerBillingForm.get('CustomerBilling.CellPhonePlaintext').valid)
        );
    }

    isUSZipCodeValid(): boolean {
        if (!this.billingCountryIsUS) {
            return true;
        } else {
            const result = this.USZipCodeInvalid();
            return !result;
        }
    }

    private _copyToShippingAddress(): void {
        const asShippingAddress: ICustomerShipping = { ...this.customerBilling };
        asShippingAddress.Id = 0;
        asShippingAddress.ShippingAddress1 = this.customerBilling.BillingAddress1;
        asShippingAddress.ShippingAddress2 = this.customerBilling.BillingAddress2;
        asShippingAddress.ShippingAddress3 = this.customerBilling.BillingAddress3;
        asShippingAddress.ShippingAddress4 = this.customerBilling.BillingAddress4;
        asShippingAddress.ShippingAddress5 = this.customerBilling.BillingAddress5;
        asShippingAddress.ShippingCity = this.customerBilling.BillingCity;
        asShippingAddress.IsPrimary = true;
        this.customerShippingAddressesService.saveAddress(asShippingAddress).subscribe((data) => {
            asShippingAddress.Id = data;
            this.announceBillingShippingService.announceNewShippingFromBilling(asShippingAddress);
        });
    }

    private _createForm(): void {
        this._getControls();
        this.customerBillingForm = this.fb.group({
            CustomerBilling: this.fb.group({}),
        });
        this.cdr.detectChanges();
    }

    private _getControls(): void {
        this.abstractCustomerBillingControls = new CustomerBillingDynamicControlsPartial(this.customerBilling, {
            countryMetaItems: this.countries.map((c) => ({ Id: c.CountryId, Name: c.CountryName })),
            formGroup: 'CustomerBilling',
            states: this.states,
        }).Form;
    }

    private _error(message?: string): void {
        this.notificationsService.error(message || 'Please enter all required fields');
    }

    private _success(): void {
        this.notificationsService.success('Billing information saved successfully');
        this.completeEmitter.emit(this.customerBilling);
        this._topOfComponent();
    }

    cancel(): void {
        this.cancelEmitter.emit();
        this._topOfComponent();
    }

    private _topOfComponent(): void {
        window.location.hash = '';
        window.location.hash = 'billing-cards-panel';
    }

    USZipCodeInvalid(): boolean {
        if (this.customerBillingForm.get('CustomerBilling.USZipCode')) {
            const currZipVal = this.customerBillingForm.get('CustomerBilling.USZipCode').value;
            if (currZipVal) {
                return currZipVal.length < 5 || (currZipVal.length > 5 && currZipVal.length < 10) || /[a-zA-Z]/.test(String(currZipVal));
            }
            return false;
        }
        return false;
    }
}
