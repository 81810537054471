import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, ILoginResponse } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
    providedIn: 'root',
})
export class AdminAccessService {
    constructor(public http: HttpClient, private environmentService: EnvironmentService, private authService: AuthService) {}

    adminAccess(resetKey: string, userId: number, adminUserId: number): Observable<ILoginResponse> {
        const data: any = {
            AdminUserId: adminUserId,
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            CustomerId: userId,
            ResetKey: resetKey,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/adminAccess', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.authService.saveToken(response, false)));
    }
}
