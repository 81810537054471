<div id="footer" class="footer py-4 position-relative">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="footer-heading">Quick Links</div>
                <div class="row">
                    <div class="col-lg-6 footer-links footer-text">
                        <a href="/publicpages/trainingprocess">How it Works</a>
                        <a href="/publicpages/aboutus">About Care Courses</a>
                        <a href="/publicpages/care_courses">Child Care Training</a>
                        <a href="/publicpages/stateinformation">State Training</a>
                    </div>
                    <div class="col-lg-6 footer-links footer-text">
                        <a href="/publicpages/nationalcredential">CDA Training</a>
                        <a href="/publicpages/faq">FAQ</a>
                        <a href="/publicpages/newsletter_signup" target="_blank">Get Our Newsletter</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 offset-lg-2">
                <div class="footer-heading">Contact Us</div>
                <div class="footer-text">
                    The Care Courses School, Inc.<br />
                    P.O. Box 10526<br />
                    McLean, Virginia 22102<br />
                    <br />
                    1-800-685-7610 (US and Canada)<br />
                    703-448-1800 (Other countries)<br />
                    <br />
                </div>
                <div>
                    <a
                        href="https://www.iacet.org/affiliates/accredited-providers-list/accredited-provider-overview/?providerID=109409"
                        target="_blank"
                        style="text-decoration-line: none"
                    >
                        <img
                            class="mr-3"
                            src="assets/icon-logo-iacet-logo.jpg"
                            title="{{ imageAltAndTitleTextEnum.IACETLogoTitle }}"
                            alt="{{ imageAltAndTitleTextEnum.IACETLogoAlt }}"
                        />
                    </a>
                    <a href=" https://www.cdacouncil.org/en/council-partners/" target="_blank" style="text-decoration-line: none">
                        <img
                            class="mr-3"
                            src="assets/CDA-gs-Seal.png"
                            style="width: 77px"
                            title="{{ imageAltAndTitleTextEnum.CDASealTitle }}"
                            alt="{{ imageAltAndTitleTextEnum.CDASealAlt }}"
                        />
                    </a>
                    <a href="https://www.registryalliance.org/" target="_blank" style="text-decoration-line: none">
                        <img
                            class="mr-3"
                            src="assets/icon-logo-tnwra-logo.jpg"
                            title="{{ imageAltAndTitleTextEnum.TNWRALogoTitle }}"
                            alt="{{ imageAltAndTitleTextEnum.TNWRALogoAlt }}"
                        />
                    </a>
                </div>
            </div>
        </div>

        <div class="social-media text-center mt-4">
            <a href="https://www.facebook.com/CareCourses/">
                <span class="fa-stack fa-2x">
                    <i class="fa fa-circle-thin fa-stack-2x"></i>
                    <i class="fa fa-facebook fa-stack-1x" style="position: relative; left: -1px; top: 2px"></i>
                </span>
            </a>
            <a href="https://twitter.com/care_courses?lang=en">
                <span class="fa-stack fa-2x">
                    <i class="fa fa-circle-thin fa-stack-2x"></i>
                    <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512" style="position: relative; left: -1px; top: -2px">
                        <path
                            fill="#ffffff"
                            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                        />
                    </svg>
                </span>
            </a>
            <a href="https://www.instagram.com/care_courses?lang=en">
                <span class="fa-stack fa-2x">
                    <i class="fa fa-circle-thin fa-stack-2x"></i>
                    <i class="fa fa-instagram fa-stack-1x" style="position: relative; top: 1px; left: 1px"></i>
                </span>
            </a>
        </div>
        <br />
        <div class="footer-text text-center">
            © {{ date | date : 'yyyy' }} The Care Courses School, Inc.<br />
            All Rights Reserved.&nbsp;<a href="{{ environment.baseApiUrl + '/sitemap.xml' }}">Sitemap</a>.
        </div>
    </div>
</div>
