import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.ShadowDom,
    selector: 'app-shadowdom',
    styleUrls: ['./shadowdom-html.component.less'],
    template: `<span [innerHTML]="innerHTML | safeHtml"></span>`,
})
export class ShadowdomHTMLComponent {
    @Input() innerHTML: string;
}
