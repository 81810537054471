import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IMenuDTO } from '@model/DTO/menu-dto';
import { MenuService } from './menu.service';

@Component({
    selector: 'app-menu',
    styleUrls: ['./menu.component.less'],
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
    @Input() isLoggedIn: boolean;
    @ViewChild('hamburger') hamburger: ElementRef;
    @ViewChild('menuDiv') menuDiv: ElementRef;
    hasOpenHamburgerMenu = false;
    menusOpen: any = {};
    get hamburgerShowing(): boolean {
        return this.hamburger?.nativeElement?.offsetParent;
    }

    private _menuItemsUnfiltered: IMenuDTO[];
    private get _isTouchScreendevice(): boolean {
        return 'ontouchstart' in window;
    }

    constructor(private menuService: MenuService, private router: Router, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.menuService.getMenu().subscribe((navItems) => (this._menuItemsUnfiltered = navItems));

        this.renderer.listen('window', 'click', (e: Event) => {
            if (e.target !== this.menuDiv.nativeElement && !this.menuDiv.nativeElement.contains(e.target)) {
                this.hasOpenHamburgerMenu = false;
            }
        });
    }

    clearUnselectedMenus(selectedNavPath: string): void {
        for (const menuString in this.menusOpen) {
            // openMenus contains X.Y.Z mapped to bool where X is parent menu num, Y is child, Z is grandchild
            if (!selectedNavPath.toString().includes(menuString.substring(menuString.length - 2))) {
                // Check for all except the last submenu number
                this.menusOpen[menuString] = false;
            }
        }
    }

    clearMenus(): any {
        this.menusOpen = {};
    }

    hasChildren(item: IMenuDTO): boolean {
        return item.Children && item.Children.length > 0;
    }

    get navItems(): IMenuDTO[] {
        if (this._menuItemsUnfiltered) {
            return this._menuItemsUnfiltered
                .filter((item) => item.LoggedIn === this.isLoggedIn)
                .map((item) => {
                    if (item.Children) {
                        item.Children = item.Children.filter((child) => child.LoggedIn === this.isLoggedIn);
                    }
                    return item;
                });
        }
        return null;
    }

    navItemClicked(navPath: string, item: IMenuDTO): void {
        // If there are no children, or if the menu item is already open
        if (!this.hasChildren(item) || this.menusOpen[navPath]) {
            if (item.PageTemplatePath) {
                void this.router.navigate([item.PageTemplatePath]);
                this.hasOpenHamburgerMenu = false;
            } else {
                window.location.href = item.CustomURL;
            }
        } else {
            this.openMenu(navPath);
        }
    }

    navItemHover(navPath: string): void {
        if (!this._isTouchScreendevice && !this.hamburgerShowing) {
            this.openMenu(navPath);
        }
    }

    openMenu(navPath: string): any {
        this.menusOpen[navPath] = true;
        this.clearUnselectedMenus(navPath);
    }
}
