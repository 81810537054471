import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormatNonUSPhoneNumber } from '../../functions/common-functions.library';
// tslint:disable: object-literal-sort-keys

@Component({
    selector: 'plaintext-phone-dynamic-field',
    templateUrl: './plaintext-phone-field.component.html',
})
export class PlaintextPhoneFieldComponent implements OnDestroy {
    @Input() field: any;
    @Input() form: any;
    @Input() fieldName: any;

    private _subscriptions = new Subscription();

    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }
    // ...

    ngAfterViewInit(): void {
        const subscription: Subscription = this.form.get(this.fieldName).valueChanges.subscribe((phoneNum: string) => {
            this.form.get(this.fieldName).setValue(FormatNonUSPhoneNumber(phoneNum), { emitEvent: false });
        });
        this._subscriptions.add(subscription);
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }
}
