import { Injectable } from '@angular/core';
import { ScullyNotificationStrategy } from '@common/strategies/concrete-strategies/scully-notification-strategy';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ToastrService } from 'ngx-toastr';

// do not implement this scully notification service directly in component, it is being provided as a token in the module

@Injectable({ providedIn: 'root' })
export class ScullyNotificationsService extends NotificationsService {
    /**
     *
     */
    private toastrService: ToastrService;
    constructor(toastrService: ToastrService) {
        const strategy = new ScullyNotificationStrategy(toastrService);
        super(strategy);
        this.toastrService = toastrService;
    }
}
