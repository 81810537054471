import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PageContentService } from './page-content-service';

@Component({
    selector: 'app-page-content',
    templateUrl: './page-content.component.html',
})
export class PageContentComponent implements OnInit {
    @Input() content: string;
    @Input() pageContentId: number;

    constructor(
        private pageContentService: PageContentService,
    ) {}

    ngOnInit(): void {
        if (!this.content && this.pageContentId) {
            this.pageContentService.getContent(this.pageContentId).subscribe((content) => {
                this.content = content.PageContent_;
            });
        }
    }
}
