<form *ngIf="customerShippingForm" class="container" [formGroup]="customerShippingForm" (ngSubmit)="formSubmitted()">
    <br />
    <h4>Contact information</h4>

    <div class="row">
        <div class="col-md-6">
            <mt-dynamic-field [field]="abstractCustomerShippingControls.FirstName" [form]="customerShippingForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerShippingControls.MiddleName" [form]="customerShippingForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerShippingControls.LastName" [form]="customerShippingForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerShippingControls.CompanyName" [form]="customerShippingForm"></mt-dynamic-field>
        </div>
        <div class="col-md-6">
            <ng-container *ngIf="phoneShowingWithExtension">
                <div class="phone-number">
                    <us-canada-phone-dynamic-field
                        [field]="abstractCustomerShippingControls.HomePhone"
                        [form]="customerShippingForm"
                        [fieldName]="'CustomerShipping.HomePhone'"
                        [required]="true"
                        (invalidReason)="homePhoneInvalid = $event"
                    ></us-canada-phone-dynamic-field>
                    <mt-dynamic-field class="extension" [field]="abstractCustomerShippingControls.HomeExt" [form]="customerShippingForm"></mt-dynamic-field>
                </div>
                <div class="phone-number">
                    <us-canada-phone-dynamic-field
                        [field]="abstractCustomerShippingControls.WorkPhone"
                        [form]="customerShippingForm"
                        [fieldName]="'CustomerShipping.WorkPhone'"
                        [required]="false"
                        (invalidReason)="workPhoneInvalid = $event"
                    ></us-canada-phone-dynamic-field>
                    <mt-dynamic-field class="extension" [field]="abstractCustomerShippingControls.WorkExt" [form]="customerShippingForm"></mt-dynamic-field>
                </div>
                <us-canada-phone-dynamic-field
                    [field]="abstractCustomerShippingControls.CellPhone"
                    [form]="customerShippingForm"
                    [fieldName]="'CustomerShipping.CellPhone'"
                    [required]="false"
                    (invalidReason)="cellPhoneInvalid = $event"
                ></us-canada-phone-dynamic-field>
            </ng-container>
            <ng-container *ngIf="!phoneShowingWithExtension">
                <plaintext-phone-dynamic-field
                    [field]="abstractCustomerShippingControls.HomePhonePlaintext"
                    [form]="customerShippingForm"
                    [fieldName]="'CustomerShipping.HomePhonePlaintext'"
                ></plaintext-phone-dynamic-field>
                <plaintext-phone-dynamic-field
                    [field]="abstractCustomerShippingControls.WorkPhonePlaintext"
                    [form]="customerShippingForm"
                    [fieldName]="'CustomerShipping.WorkPhonePlaintext'"
                ></plaintext-phone-dynamic-field>
                <plaintext-phone-dynamic-field
                    [field]="abstractCustomerShippingControls.CellPhonePlaintext"
                    [form]="customerShippingForm"
                    [fieldName]="'CustomerShipping.CellPhonePlaintext'"
                ></plaintext-phone-dynamic-field>
            </ng-container>
        </div>
    </div>
    <br />

    <h4>Shipping information</h4>
    <mt-dynamic-field
        [field]="abstractCustomerShippingControls.CountryId"
        [form]="customerShippingForm"
        (valueChanges)="newCountry($event)"
    ></mt-dynamic-field>
    <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingAddress1" [form]="customerShippingForm"></mt-dynamic-field>
    <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingAddress2" [form]="customerShippingForm"></mt-dynamic-field>
    <ng-container *ngIf="countryIsUS">
        <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingCity" [form]="customerShippingForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingStateId" [form]="customerShippingForm"></mt-dynamic-field>

        <zip-code-dynamic-field
            [field]="abstractCustomerShippingControls.USZipCode"
            [form]="customerShippingForm"
            [fieldName]="'CustomerShipping.USZipCode'"
            (invalidReason)="zipInvalid = $event"
        ></zip-code-dynamic-field>
    </ng-container>
    <ng-container *ngIf="!countryIsUS">
        <ng-container *ngIf="!countryIsCanada">
            <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingAddress3" [form]="customerShippingForm"></mt-dynamic-field>
        </ng-container>
        <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingCity" [form]="customerShippingForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingAddress4" [form]="customerShippingForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerShippingControls.ShippingAddress5" [form]="customerShippingForm"></mt-dynamic-field>
    </ng-container>

    <input id="save-as-billing-address" type="checkbox" [(ngModel)]="saveAsBillingAddress" [ngModelOptions]="{ standalone: true }" />
    <label for="save-as-billing-address">Save as new billing address</label>
    <br />
    <input
        id="save-as-primary"
        type="checkbox"
        [(ngModel)]="customerShipping.IsPrimary"
        (ngModelChange)="primaryClicked($event)"
        [ngModelOptions]="{ standalone: true }"
    />
    <label for="save-as-primary">Save as my primary shipping address</label>

    <div class="float-multiple-right">
        <br />
        <button type="button" Class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button
            type="submit"
            mtDisableClickDuringHttpCalls
            style="margin-left: 10px"
            Class="btn btn-primary btn-success"
        >
            Save
        </button>
    </div>
</form>
