import { Component, Input } from '@angular/core';
import { ICustomerBilling } from '@model/interfaces/customer-billing';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { IState } from '../../model/interfaces/state';
import { CountriesEnums } from '../constants/Enums';
import { GetState } from '../functions/common-functions.library';
import { CommonService } from '../services/common.service';

@Component({
    selector: 'app-billing-info',
    styleUrls: ['./billing-info.component.less'],
    templateUrl: './billing-info.component.html',
})
export class BillingInfo {
    @Input() billing: ICustomerBilling;
    @Input() isAdmin: boolean;
    USCountryId = CountriesEnums.US;
    states: IState[];

    private _susbcription = new Subscription();

    constructor(private notificationService: NotificationsService, public commonService: CommonService) {}

    ngOnInit(): void {
        this._susbcription.add(this.commonService.getStates().subscribe((states) => (this.states = states)));
    }

    ngOnDestroy(): void {
        this._susbcription.unsubscribe();
    }

    getStateName(stateCode: string): string {
        if (this.states) {
            return GetState(this.states, undefined, stateCode).StateDesc;
        }
        return '';
    }

    copyAddress(address: ICustomerBilling, event: Event): void {
        event?.stopPropagation?.();
        const value =
            address.FirstName +
            ' ' +
            (address.MiddleName ? address.MiddleName + ' ' : '') +
            address.LastName +
            '\r\n' +
            (address.CompanyName ? address.CompanyName + '\r\n' : '') +
            address.BillingAddress1 +
            '\r\n' +
            (address.BillingAddress2 ? address.BillingAddress2 + '\r\n' : '') +
            (address.BillingAddress3 ? address.BillingAddress3 + '\r\n' : '') +
            address.BillingCity +
            ', ' +
            address.BillingAddress4 +
            ' ' +
            (address.BillingAddress5 ? address.BillingAddress5 : '') +
            '\r\n' +
            address.Country.CountryName;

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notificationService.success('Billing address copied');
    }
}
