import { NotificationStrategy } from '@mt-ng2/notifications-module';
import { isScullyRunning } from '@scullyio/ng-lib';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

interface ToastrOptions extends Partial<IndividualConfig> {
    onHidden?: () => void;
}
export class ScullyNotificationStrategy extends NotificationStrategy {
    /**
     *
     */
    constructor(private toastrService: ToastrService) {
        super();
    }

    success(message: string, options?: ToastrOptions): void {
        if (!options.onHidden) {
            if (!isScullyRunning()) {
                this.toastrService.success(message, null, options);
            }
        } else {
            if (!isScullyRunning()) {
                this.toastrService.success(message, null, options).onHidden.subscribe(() => options.onHidden());
            }
        }
    }

    error(message: string, title?: string, options?: Partial<IndividualConfig>): void {
        if (!isScullyRunning()) {
            this.toastrService.error(message, title, options);
        }
    }

    warning(message: string, options?: Partial<IndividualConfig>): void {
        if (!isScullyRunning()) {
            this.toastrService.warning(message, null, options);
        }
    }

    info(message: string, options?: Partial<IndividualConfig>): void {
        if (!isScullyRunning()) {
            this.toastrService.info(message, null, options);
        }
    }

    show(message: string, title?: string, options?: any, type?: string): void {
        if (!isScullyRunning()) {
            throw new Error('Method not implemented.');
        }
    }
}
