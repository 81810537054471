import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordService {
    constructor(public http: HttpClient) {}

    forgot(email: string): Observable<object> {
        const data: any = {
            email: email,
        };
        return this.http.post('/customers/forgot', data, {
            headers: { BypassAuth: 'true' },
        });
    }
}
