import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TopOfPage } from '../common/functions/common-functions.library';

@Component({
    selector: 'app-public-page',
    templateUrl: './public-page.component.html',
})
export class PublicPageComponent implements OnInit {
    pageName: string;

    public title: Title;

    constructor(
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        TopOfPage();
        this.route.paramMap.subscribe((val) => {
            this.pageName = val.get('pagename');
        });
    }
}
