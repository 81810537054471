<ng-container *ngIf="shipping">
    <h5 class="m-0 open-sans-font" style="font-weight: bold">
        {{ shipping.FirstName + ' ' + (shipping.MiddleName || '') + ' ' + shipping.LastName }}
        &nbsp;<a href="#" onclick="return false;" *ngIf="isAdmin" (click)="copyAddress(shipping, $event)"
            ><i style="font-size: 1rem" class="fa fa-copy fa-fw"></i
        ></a>
    </h5>
    <div>{{ shipping.CompanyName }}</div>
    <div>{{ shipping.ShippingAddress1 }}</div>
    <div *ngIf="shipping.ShippingAddress2">{{ shipping.ShippingAddress2 }}</div>
    <div *ngIf="shipping.ShippingAddress3">{{ shipping.ShippingAddress3 }}</div>
    <div>
        {{ shipping.ShippingCity + ', ' }}
        <ng-container *ngIf="shipping.CountryId && shipping.CountryId === USCountryId">{{
            getStateName(shipping.ShippingAddress4) + ' '
        }}</ng-container>
        <ng-container *ngIf="shipping.CountryId && shipping.CountryId !== USCountryId">{{ shipping.ShippingAddress4 + ' ' }}</ng-container>
        {{ shipping.ShippingAddress5 }}
    </div>
    <div *ngIf="shipping.CountryId && shipping.CountryId !== USCountryId">{{ shipping.Country.CountryName }}</div>
    <div class="colon-aligned-data mb-y">
        <div>Primary Phone:</div>
        <span>{{ shipping.HomePhone | usOrCanadianPhone: shipping.CountryId }}</span>
        <div>Work Phone:</div>
        <span>{{ shipping.WorkPhone | usOrCanadianPhone: shipping.CountryId }}</span>
        <div>Cell Phone:</div>
        <span>{{ shipping.CellPhone | usOrCanadianPhone: shipping.CountryId }}</span>
    </div>
</ng-container>
<ng-container *ngIf="!shipping">
    <i>No shipping address</i>
</ng-container>
