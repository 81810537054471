import { Component, OnInit} from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-myaccount-buttons',
    styles: [`
        a {
            margin-left: 4px;
        }
    `],
    template: `
    <div *ngIf="isAdmin">
        <a routerLink="/customer/order" class="sm-full-width btn btn-primary float-sm-right my-1">My Orders</a>
        <a routerLink="/customer/course" class="sm-full-width btn btn-primary float-sm-right my-1">My Courses</a>
        <a routerLink="/customer/info" class="sm-full-width btn btn-primary float-sm-right my-1 mb-4">My Information</a>
    </div>
    <div *ngIf="!isAdmin">
        <a routerLink="/customer" class="sm-full-width btn btn-primary float-sm-right my-1 mb-4">My Account</a>
    </div>
    `,
})
export class MyAccountButtons implements OnInit {
    isAdmin = false;
    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.authService.currentUser.subscribe((user) => {
            this.isAdmin = user.CustomOptions?.IsAdmin;
        });

    }
}
