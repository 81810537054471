import { Component } from '@angular/core';
import { environment } from '@environment';
import { CourseSortBy, ImageAltAndTitleText } from '../common/constants/Enums';

@Component({
    selector: 'app-footer',
    styleUrls: ['./footer.component.less'],
    templateUrl: './footer.component.html',
})
export class FooterComponent {
    courseSortBy = CourseSortBy;
    imageAltAndTitleTextEnum = ImageAltAndTitleText;
    environment = environment;
    date = Date();
}
