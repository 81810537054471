import { AuthUserDynamicControls } from '../form-controls/auth-user.form-controls';
import { IAuthUser } from '../interfaces/auth-user';
import { IUserRole } from '../interfaces/user-role';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {
    constructor(private extAuthUser: IAuthUser, userRoles: IUserRole[], configControls: string[], resetPassword = false) {
        super(extAuthUser, { roles: userRoles });

        if (configControls.includes('SendResetEmail') && (!this.extAuthUser || this.extAuthUser.Id === 0)) {
            // Add the send email checkbox when creating a new user
            this.Form.SendResetEmail = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Send Password Reset Email',
                name: 'SendResetEmail',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                }),
                value: '',
            });
        }

        if (configControls.includes('CurrentPassword')) {
            this.Form.CurrentPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Current Password',
                name: 'CurrentPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: '',
            });
        }

        if (resetPassword) {
            (this.Form.Password as DynamicField).labelHtml = `<label>New Password</label>`;
        }

        if (configControls.includes('ConfirmPassword')) {
            // Add the controls to validate passwords.  These are not in the model.
            this.Form.ConfirmPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Confirm Password',
                labelHtml: `<label>Confirm New Password</label>`,
                name: 'ConfirmPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required],
                validators: {
                    required: true,
                },
                value: '',
            });
        }
    }
}
