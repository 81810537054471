import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { CardEntityListComponent } from '../card-entity-list-component/card-entity-list.component';

@Component({
    selector: 'collapsible-card-entity-list',
    styleUrls: ['../card-entity-list-component/card-entity-list.component.less'],
    templateUrl: '../card-entity-list-component/card-entity-list.component.html',
})
export class CollapsibleCardEntityListComponent<T extends IEntity> extends CardEntityListComponent<T> implements OnChanges {
    @Input('isOpen') set isOpen(value: boolean) {
        this.allEntitiesShowing = value;
    }
    @Output('isOpenChange') isOpenEmitter = new EventEmitter<boolean>();
    /** The number of items to show before "Show all" has been clicked */
    @Input() fewTotal = 2;
    /** Whether the component is showing all on render */
    showAllOnInit = false;
    originalEntities: T[] = [];
    originalIncludeAdd: boolean;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.originalIncludeAdd = this.includeAdd;
        this.allEntitiesShowing = this.showAllOnInit;
        this.originalEntities = this.entities;
        this._determineEntitiesToShow();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // If entities was updated from the child, and it's not the first change, we need to re-render this component
        if (changes.entities && !changes.entities.firstChange) {
            this.originalEntities = changes.entities.currentValue;
            this._determineEntitiesToShow();
        }

        if (changes.isOpen && !changes.isOpen.firstChange) {
            this.isOpenEmitter.emit(Boolean(changes.isOpen.currentValue));
        }
    }

    /** If there are few enough items, all items should be displayed, and no show/hide button should appear */
    private get _fewItemsShowAll(): boolean {
        return this._lengthExcludingEmptyItem(this.originalEntities) < this.fewTotal;
    }

    private _determineEntitiesToShow(): void {
        this.includeAdd = this.originalIncludeAdd;
        this.showAllOnInit = this.allEntitiesShowing;
        this.entities = this.originalEntities;
        this.includeShowHideAllButton = !this._fewItemsShowAll;

        if (!this._fewItemsShowAll && !this.allEntitiesShowing) {
            this.entities = [];
            for (let i = 0; i < this.fewTotal; i++) {
                this.entities.push(this.originalEntities[i]);
            }
            this.includeAdd = false;
        }
        super._addEmptyItemIfNeeded();
    }

    private _lengthExcludingEmptyItem(list: T[]): number {
        if (this.hasEmptyItem) {
            return list.length - 1;
        }
        return list.length;
    }

    showHideClicked(): void {
        super.showHideClicked();
        this.isOpenEmitter.emit(this.allEntitiesShowing);
        this._determineEntitiesToShow();
    }
}
