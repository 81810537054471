<ng-container *ngIf="billing">
    <h5 class="m-0 open-sans-font" style="font-weight: bold">
        {{ billing.FirstName + ' ' + (billing.MiddleName || '') + ' ' + billing.LastName }}
        &nbsp;<a href="#" onclick="return false;" *ngIf="isAdmin" (click)="copyAddress(billing, $event)"
            ><i style="font-size: 1rem" class="fa fa-copy fa-fw"></i></a
        ><br />
    </h5>
    <div>{{ billing.CompanyName }}</div>
    <div>{{ billing.BillingAddress1 }}</div>
    <div *ngIf="billing.BillingAddress2">{{ billing.BillingAddress2 }}</div>
    <div *ngIf="billing.BillingAddress3">{{ billing.BillingAddress3 }}</div>
    <div>
        {{ billing.BillingCity + ', ' }}
        <ng-container *ngIf="billing.CountryId && billing.CountryId === USCountryId">{{ getStateName(billing.BillingAddress4) + ' ' }}</ng-container>
        <ng-container *ngIf="billing.CountryId && billing.CountryId !== USCountryId">{{ billing.BillingAddress4 + ' ' }}</ng-container>
        {{ billing.BillingAddress5 }}
    </div>
    <div *ngIf="billing.CountryId && billing.CountryId !== USCountryId">{{ billing.Country.CountryName }}</div>
    <div class="colon-aligned-data my-3">
        <div>Primary Phone:</div>
        <span>{{ billing.HomePhone | usOrCanadianPhone: billing.CountryId }}</span>
        <div>Work Phone:</div>
        <span>{{ billing.WorkPhone | usOrCanadianPhone: billing.CountryId }}</span>
        <div>Cell Phone:</div>
        <span>{{ billing.CellPhone | usOrCanadianPhone: billing.CountryId }}</span>
    </div>
</ng-container>
<ng-container *ngIf="!billing">
    <i>No billing address</i>
</ng-container>
