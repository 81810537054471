import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICatalogRequest } from '../model/interfaces/catalog-request';
// tslint:disable: object-literal-sort-keys

export const emptyCatalogRequest: ICatalogRequest = {
    CatalogRequestId: 0,
    Quantity: 0,
    FirstName: null,
    MiddleName: null,
    LastName: null,
    EmailAddress: null,
    Country: null,
    Address1: null,
    Address2: null,
    Address3: null,
    City: null,
    Address4: null,
    Address5: null,
    FacilityName: null,
    Comments: null,
};

@Injectable({
    providedIn: 'root',
})
export class CatalogRequestService {

    constructor(public http: HttpClient) {}

    get emptyCatalogRequestService(): ICatalogRequest {
        return { ...emptyCatalogRequest };
    }

    addRequest(newRequest: ICatalogRequest): Observable<ICatalogRequest> {
        return this.http.post<ICatalogRequest>('/catalog-request', newRequest);
    }
}
