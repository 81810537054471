<div class="container login-container">
    <div class="row">
        <div class="col-md-6 login-form-1">
            <div class="message"><h1 class="message">Care Courses Customers</h1></div>
            <form *ngIf="loginForm" [formGroup]="loginForm" (submit)="onLogin()">
                <div class="form-group" [class.has-error]="showUsernameRequiredError()">
                    <input type="text" class="form-control" placeholder="Email Address" formControlName="username" />
                    <div *ngIf="showUsernameRequiredError()" class="small errortext" [style.position]="'absolute'">Email is required</div>
                </div>
                <div class="form-group" [class.has-error]="showPasswordRequiredError()" style="top: 7px">
                    <input #Password type="{{ isPasswordVisible ? 'text' : 'password'}}" autocomplete="off" class="form-control" placeholder="Password" formControlName="password" />
                    <a class="password-toggle" (click)="togglePasswordVisibility()">
                        {{ isPasswordVisible ? 'Hide' : 'Show' }}
                    </a>
                    <div *ngIf="showPasswordRequiredError()" class="small errortext" [style.position]="'absolute'">Password is required</div>
                </div>
                <br />
                <div class="row">
                    <div class="col-6">
                        <a [routerLink]="'../forgot-password'">Forgot Password</a><br />
                        <a [routerLink]="'../create-account'">Create Account</a>
                    </div>
                    <div class="col-6">
                        <button type="submit" class="btn btn-primary float-right m-auto">Login</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6 login-form-2">
            <div class="message">New Customers</div>
            <div></div>
            <div>
                <div>
                    <span style="font-size: 16px"
                        ><strong><span style="font-size: 16px">New to Care Courses? Never used us before?</span></strong
                        ><br />
                    </span>
                </div>
                <div>
                    <span style="font-size: 16px"><br /> </span>
                </div>
                <div>
                    <span style="font-size: 16px"
                        >Click to create an account where you can place&nbsp;<strong>orders</strong>, view your&nbsp;<strong>course history</strong>,
                        and activate&nbsp;<strong>enrollment codes</strong>. Please use your personal email address for your account, not a shared
                        email address.<br />
                    </span>
                </div>
            </div>
            <br />
            <a (click)="registerClicked()" class="btn btn-primary">Create Account</a>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center">
    <div><app-page-content [pageContentId]="pageContentEnums.LoginPageBottom"></app-page-content></div>
</div>
