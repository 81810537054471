import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountriesEnums } from '@common/constants/Enums';
import { ICustomerBilling } from '@model/interfaces/customer-billing';
import { CustomerBillingAddressesService } from '@customers/services/customer-billing-addresses.service';
import { IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';
import { Subscription } from 'rxjs';
import { AnnounceBillingShippingService } from '@common/services/announce-billing-shipping.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
// tslint:disable: no-unused-expression

@Component({
    selector: 'app-billing-list',
    templateUrl: './billing-list.component.html',
})
export class BillingList implements OnInit {
    @Output('selectedAddress') selectedAddressEmitter = new EventEmitter<ICustomerBilling>();
    @Input() selectButton = false;
    @Input() selectButtonText: string;
    @Input() numberOfItemsBeforeExpanded = 1;
    @Input() sortBySelect = false;
    customerBillingList: ICustomerBilling[];
    editingItem: ICustomerBilling;
    editingNewItem = false;
    USCountryId = CountriesEnums.US;
    isAdmin = false;
    subscription = new Subscription();
    selectedItem: ICustomerBilling;
    emptyBillingAddress = this.customerBillingAddressesService.getEmptyCustomerBilling();
    selectedItemId: number;

    @Output('listIsExpanded') listIsExpandedEmitter = new EventEmitter<boolean>();
    private _showAllAddresses = false;
    get showAllAddresses(): boolean {
        return this._showAllAddresses;
    }
    set showAllAddresses(val: boolean) {
        this._showAllAddresses = val;
        this.listIsExpandedEmitter.emit(val);
    }

    constructor(
        private customerBillingAddressesService: CustomerBillingAddressesService,
        private notificationService: NotificationsService,
        private authService: AuthService,
        private announceBillingShippingService: AnnounceBillingShippingService,
        private checkoutService: CheckoutService,
    ) {}

    ngOnInit(): void {
        this.getBillingAddressList();
        this.authService.currentUser.subscribe((user) => {
            this.isAdmin = user.CustomOptions?.IsAdmin;
        });
        this.subscription.add(
            this.announceBillingShippingService.newBillingFromShippingObservable.subscribe((newBilling) => {
                this.getBillingAddressList();
            }),
        );
    }

    getBillingAddressList(): void {
        this.customerBillingAddressesService.getAddresses().subscribe((addresses) => {
            this.customerBillingList = addresses;
            for (const address of this.customerBillingList) {
                if (
                    address.Id === this.checkoutService.selectedBillingAddressId ||
                    (!this.checkoutService.selectedBillingAddressId && address.IsPrimary)
                ) {
                    this.selectedAddressEmitter.emit(address);
                    this.selectedItem = address;
                    this._moveItemToTop(address);
                    break;
                }
            }
        });
    }

    edit(address: ICustomerBilling): void {
        this.editingNewItem = !address.Id;
        // If there is only one, empty, "new" address
        if (this.customerBillingList.length === 1) {
            address.IsPrimary = true;
        }
        this.editingItem = address;
        this._topOfComponent();
    }

    select(address: ICustomerBilling, silent = false): void {
        if (this.sortBySelect) {
            if (!address.IsPrimary) {
                const primaryAddress = this.customerBillingList.find((cb) => cb.IsPrimary);
                this._moveItemToTop(primaryAddress);
            }
            this._moveItemToTop(address);
        }
        this.selectedItem = address;
        this.selectedAddressEmitter.emit(address);
        this.showAllAddresses = false;
        !silent && this.notificationService.success('Successfully selected new address');
    }

    private _topOfComponent(): void {
        window.location.hash = '';
        window.location.hash = 'billing-cards-panel';
    }

    private _moveItemToTop(item: ICustomerBilling): void {
        this.customerBillingList = this.customerBillingList.filter((x) => x.Id !== item.Id);
        this.customerBillingList.unshift(item);
    }

    save(): void {
        if (this.editingNewItem) {
            const latestAddress = this.customerBillingList[this.customerBillingList.length - 1];
            this.selectedAddressEmitter.emit(latestAddress);
            this.selectedItem = latestAddress;
            if (this.customerBillingList.length === 1) {
                latestAddress.IsPrimary = true;
            }
            // If we're adding a new address, select it
            this.select(latestAddress, true);
        }
        this.editingItem = null;
        this._closeExpandedIfNeeded();
    }

    cancel(): void {
        this.editingItem = null;
        this._closeExpandedIfNeeded();
    }

    private _closeExpandedIfNeeded(): void {
        if (!this.selectButton) {
            this.showAllAddresses = false;
        }
    }

    setNewPrimary(itemToUpdateAsPrimary: ICustomerBilling): void {
        for (const i of this.customerBillingList) {
            i.IsPrimary = false;
        }
        itemToUpdateAsPrimary.IsPrimary = true;
        if (!this.sortBySelect) {
            this._moveItemToTop(itemToUpdateAsPrimary);
        }
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedEntity: ICustomerBilling = event.entity;
        if (selectedEntity.IsPrimary) {
            this.notificationService.warning('You cannot delete your primary billing address');
        } else if (this.customerBillingList.length === 1) {
            this.notificationService.warning('Please add a new billing address before deleting this one');
        } else {
            this.customerBillingAddressesService.deleteAddress(selectedEntity.Id).subscribe(() => {
                this.customerBillingList = this.customerBillingList.filter((i) => i !== selectedEntity);
                this.notificationService.success('Billing address deleted');
            });
        }
    }
}
