import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { IPageTemplate } from '@model/interfaces/page-template';
import { CommonService } from '../../common/services/common.service';
import { PageTemplateService } from './page-template-service';

@Component({
    selector: 'app-page-template',
    templateUrl: './page-template.component.html',
})
export class PageTemplateComponent implements OnInit {
    _pageName: string;
    @Input()
    get pageName(): string {
        return this._pageName;
    }
    set pageName(val: string) {
        this._pageName = val;
        this.initialize();
    }

    template: IPageTemplate;

    constructor(
        private pageTemplateService: PageTemplateService,
        private sanitizer: DomSanitizer,
        private title: Title,
        private meta: Meta,
        private router: Router,
        private commonService: CommonService,
    ) {}

    ngOnInit(): void {
        this.initialize();
    }

    ngOnDestroy(): void {
        if (this.template?.EnableGoogleAnalytics) {
            this.meta.removeTag('google-site-verification');
        }
    }

    private initialize(): void {
        this.pageTemplateService.getTemplate(this.pageName).subscribe((template) => {
            this.template = template;
            this.commonService.clearMetaTags();
            if (template) {
                this.title.setTitle(this.template.MetaTitle);
                this.meta.addTags([
                    {
                        content: this.template.MetaKeywords,
                        name: 'keywords',
                    },
                    {
                        content: this.template.MetaDesc,
                        name: 'description',
                    },
                ]);
                if (template?.EnableGoogleAnalytics) {
                    this.meta.addTags([
                        {
                            content: environment.googleSiteVerification,
                            name: 'google-site-verification',
                        },
                    ]);
                }


            } else {
                void this.router.navigate(['/home']);
            }
        });
    }
}
