import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ForgotPasswordService } from './forgot-password-service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: UntypedFormGroup;
    logoFull: string;

    protected forgotPasswordService: ForgotPasswordService;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    protected metaService: Meta;

    constructor(injector: Injector) {
        this.forgotPasswordService = injector.get(ForgotPasswordService);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.metaService = injector.get(Meta);
    }

    ngOnInit(): void {
        this.createForm();
        this.setPageTitleMeta();
    }

    setPageTitleMeta(): void {
        this.metaService.addTag({
            content: 'Forgotten your Care Courses password? ',
            name: 'description',
        });
    }

    createForm(): void {
        if (!this.forgotPasswordForm) {
            this.forgotPasswordForm = new UntypedFormGroup({});
        }
        this.forgotPasswordForm.addControl('email', new UntypedFormControl('', [Validators.required.bind(this), Validators.email.bind(this)]));
    }

    emailHasError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showEmailRequiredError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('required');
    }

    showEmailInvalidError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('email');
    }

    onLogin(): void {
        if (this.forgotPasswordForm.valid) {
            const values = this.forgotPasswordForm.value;
            this.forgotPasswordService.forgot(String(values.email)).subscribe((success) => {
                this.notificationsService.success(
                    'If the email exists in our system, a reset link has been generated. Please check your email for further instructions',
                );
            });
        } else {
            markAllFormFieldsAsTouched(this.forgotPasswordForm);
        }
    }
}
