import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageAltAndTitleText, ItemTypeEnum, MetaItemType } from '@common/constants/Enums';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { CourseService } from '../../courses/services/course.service';
import { environment } from '../../environments/environment';
import { IMetaItemExtended } from '../../model/custom/MetaItemExtended';
import { ICourseFormat } from '../../model/interfaces/course-format';
import { IItem } from '../../model/interfaces/item';
import { IShoppingCart } from '../../model/interfaces/shopping-cart';
import { ShoppingCartService } from './shopping-cart.service';

@Component({
    selector: 'app-shopping-cart',
    styleUrls: ['./shopping-cart.component.less'],
    templateUrl: './shopping-cart.component.html',
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
    @Input() showSubtotal = true;
    cartInventory: IShoppingCart[] = [];
    environment = environment;
    isAdmin = false;
    adminCartAddQty = 1;
    itemTypeEnum = ItemTypeEnum;

    selectedMetaItem: IMetaItemExtended;
    metaItemList: IMetaItemExtended[];
    currentUser: ILoggedIn;
    imageAltAndTitleTextEnum = ImageAltAndTitleText;
    private subscriptions = new Subscription();

    constructor(
        public shoppingCartService: ShoppingCartService,
        private notificationService: NotificationsService,
        private courseService: CourseService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private meta: Meta,
    ) {}

    ngOnInit(): void {
        // Only fetch all course formats if the user is logged in as admin
        const loggedInasObeservable = this.authService.currentUser.asObservable();
        loggedInasObeservable
            .pipe(
                tap((user: ILoggedIn) => {
                    this.currentUser = user;
                    this.isAdmin = user.CustomOptions?.IsAdmin;
                }),
                filter(() => this.isAdmin),
                switchMap(() => {
                    return this.courseService.getFormatItemBundleMetaItems();
                }),
            )
            .subscribe((list) => {
                this.metaItemList = list;
            });

        this.subscriptions.add(this.shoppingCartService.$cartInventory.subscribe((inventory) => (this.cartInventory = inventory)));
        if (this.isOnCartPage) {
            this.title.setTitle('Care Courses - Shopping Cart');
            this.meta.addTag({
                content: 'Your Care Courses shopping cart',
                name: 'description',
            });
        }
        window.scrollTo(0, 0);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    adminAddToCart(): void {
        if (this.selectedMetaItem && this.adminCartAddQty > 0) {
            if (this.selectedMetaItem.Type === MetaItemType.Format) {
                const selectedFormat = { CourseFormatId: this.selectedMetaItem.Id } as ICourseFormat;
                this.shoppingCartService.updateCartWithCourse(selectedFormat, undefined, this.adminCartAddQty).subscribe((_) => {
                    this.notificationService.success('Cart updated');
                    this.selectedMetaItem = null;
                });
            } else {
                const selectedFormat = { ItemId: this.selectedMetaItem.Id } as IItem;
                this.shoppingCartService.updateCartWithItem(selectedFormat, undefined, undefined, this.adminCartAddQty).subscribe((_) => {
                    this.notificationService.success('Cart updated');
                    this.selectedMetaItem = null;
                });
            }
        } else if (!this.selectedMetaItem) {
            this.notificationService.warning('Please select a course to add to the cart');
        } else if (this.adminCartAddQty <= 0) {
            this.notificationService.warning('Please add a quantity of 1 or more');
        }
    }

    changeAdminCartAddQty(e): void {
        this.adminCartAddQty = +(e.target as HTMLTextAreaElement).value;
    }

    goToItem(cartItem: IShoppingCart): void {
        if (cartItem.CourseFormatId) {
            void this.router.navigate(['courses', cartItem.ItemId]);
        } else if (cartItem.Item?.Bundle) {
            if (cartItem.Item.Bundle.ParentBundleAssociations[0]?.ParentBundleId) {
                void this.router.navigate(['bundle/parentbundle', cartItem.Item.Bundle.ParentBundleAssociations[0].ParentBundleId]);
            } else {
                this.notificationService.warning('Cannot redirect because this bundle has no associated parent bundle');
            }
        }
    }

    updateCount(cartItem: IShoppingCart, newQtyStr: any): void {
        const newQty = +newQtyStr;
        if (newQty > 0) {
            if (cartItem.CourseFormatId) {
                this.shoppingCartService.updateCartWithCourse(cartItem.CourseFormat, newQty, undefined).subscribe((item) => {
                    cartItem = item;
                    this.notificationService.success('Cart updated');
                });
            } else {
                this.shoppingCartService.updateCartWithItem(cartItem.Item, undefined, newQty, undefined).subscribe((item) => {
                    cartItem = item;
                    this.notificationService.success('Cart updated');
                });
            }
        } else if (newQtyStr !== null) {
            this.notificationService.warning('Please enter a quantity of 1 or more');
        }
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedEntity = event.entity as IShoppingCart;
        this.shoppingCartService.removeFromCart(selectedEntity.ShoppingCartId).subscribe(() => {
            this.notificationService.success('Item removed');
        });
    }

    goToCheckout(): void {
        if (this.currentUser.Id > 0) {
            void this.router.navigate(['/checkout']);
        } else {
            this.notificationService.info('Please login or create an account to complete your order.');
            void this.router.navigateByUrl('/login?returnUrl=checkout');
        }
    }

    continueShopping(): void {
        const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
        if (returnUrl && !returnUrl.includes('courses') && !returnUrl.includes('bundle')) {
            void this.router.navigate(['/courses']);
        } else {
            void this.router.navigate([this.activatedRoute.snapshot.queryParams.returnUrl]);
        }
    }

    displayItemPrice(cartItem: IShoppingCart): number {
        if (cartItem.Item.BundleId) {
            return cartItem.Item.Bundle.BundlePrice;
        } else {
            return cartItem.Item.Price;
        }
    }

    get isOnCartPage(): boolean {
        return this.activatedRoute.snapshot.routeConfig.path === 'cart';
    }

    showQuantityField(cartItem: IShoppingCart): boolean {
        return cartItem.Item.ItemTypeId !== this.itemTypeEnum.Service;
    }

    isCourseOrBundle(cartItem: IShoppingCart): boolean {
        return cartItem.CourseFormatId > 0 || cartItem.Item?.BundleId > 0;
    }
}
