import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { IShippingType } from '../../model/interfaces/shipping-type';
import { map } from 'rxjs/operators';
import { IOrder } from '../../model/interfaces/order';
import { IMtPayPalService } from '@mt-ng2/paypal';
import { ICustomerShipping } from '../../model/interfaces/customer-shipping';
import { ICustomerBilling } from '../../model/interfaces/customer-billing';

export const emptyOrder: IOrder = {
    OrderId: 0,
    Response: '',
    ShipCountryId: 0,
    ShippingTypeId: 0,
    TwoWeekSent: false,
};

@Injectable({
    providedIn: 'root',
})
export class CheckoutService implements IMtPayPalService {
    public selectedBillingAddressId: number;
    public selectedShippingAddressId: number;
    public customerId = 0;
    private _currentOrderDiscount: number;
    private _currentGrandTotal: number;

    constructor(public http: HttpClient) {}

    getEmptyOrder(): IOrder {
        return { ...emptyOrder };
    }

    setCurrentOrderDiscount(discount: number): void {
        if (discount === null || discount === undefined) {
            discount = 0;
        }
        this._currentOrderDiscount = discount;
    }

    /** Sets a grandTotal property in this service that is used if checking out with Paypal */
    setCurrentOrderTotal(grandTotal: number): void {
        this._currentGrandTotal = grandTotal;
    }

    getShippingTypes(): Observable<IShippingType[]> {
        return this.http.get<IShippingType[]>('/checkout/types');
    }

    getTotalShippingCost(shippingTypeId: number, countryId: number, cartShippableSubtotal: number, numItems: number): Observable<[number, string]> {
        return this.http.get<SimpleType>(`/checkout/shipping-total/${shippingTypeId}/${countryId}/${cartShippableSubtotal}/${numItems}`).pipe(
            map((x) => {
                return [x.Item1, x.Item2];
            }),
        );
    }

    getPayPalOrderId(orderId: number): Observable<string> {
        return this.http.get<string>(`/checkout/${this.customerId}/${this._currentOrderDiscount}/${this._currentGrandTotal}/paypal-order-id`);
    }

    capturePayPalOrder(orderId: number, payPalOrderId: string): Observable<void> {
        return this.http.post<null>(`/checkout/capture-paypal-order/${payPalOrderId}`, null);
    }

    // tslint:disable: member-ordering
    private _$currentShippingAddressId = new BehaviorSubject(null);
    get $currentShippingAddressId(): Observable<number> {
        return this._$currentShippingAddressId.asObservable();
    }
    setCurrentShippingAddressId(val: number): void {
        this._$currentShippingAddressId.next(val);
    }

    private _$currentBillingAddressId = new BehaviorSubject(null);
    get $currentBillingAddressId(): Observable<number> {
        return this._$currentBillingAddressId.asObservable();
    }
    setCurrentBillingAddressId(val: number): void {
        this._$currentBillingAddressId.next(val);
    }
}

class SimpleType {
    Item1: number;
    Item2: string;
}
