import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICustomerShipping } from '@model/interfaces/customer-shipping';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { IState } from '../../model/interfaces/state';
import { CountriesEnums } from '../constants/Enums';
import { GetState } from '../functions/common-functions.library';
import { CommonService } from '../services/common.service';

@Component({
    selector: 'app-shipping-info',
    styleUrls: ['./shipping-info.component.less'],
    templateUrl: './shipping-info.component.html',
})
export class ShippingInfo implements OnInit, OnDestroy {
    @Input() shipping: ICustomerShipping;
    @Input() isAdmin: boolean;
    USCountryId = CountriesEnums.US;
    states: IState[];

    private _susbcription = new Subscription();

    constructor(private notificationService: NotificationsService, private commonService: CommonService) {}

    ngOnInit(): void {
        this._susbcription.add(this.commonService.getStates().subscribe((states) => (this.states = states)));
    }

    ngOnDestroy(): void {
        this._susbcription.unsubscribe();
    }

    getStateName(stateCode: string): string {
        if (this.states) {
            return GetState(this.states, undefined, stateCode).StateDesc;
        }
        return '';
    }

    copyAddress(address: ICustomerShipping, event: Event): void {
        event?.stopPropagation?.();
        const value =
            address.FirstName +
            ' ' +
            (address.MiddleName ? address.MiddleName + ' ' : '') +
            address.LastName +
            '\r\n' +
            (address.CompanyName ? address.CompanyName + '\r\n' : '') +
            address.ShippingAddress1 +
            '\r\n' +
            (address.ShippingAddress2 ? address.ShippingAddress2 + '\r\n' : '') +
            (address.ShippingAddress3 ? address.ShippingAddress3 + '\r\n' : '') +
            address.ShippingCity +
            ', ' +
            address.ShippingAddress4 +
            ' ' +
            (address.ShippingAddress5 ? address.ShippingAddress5 : '') +
            '\r\n' +
            address.Country.CountryName;

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notificationService.success('Shipping address copied');
    }
}
