<div class="header">
    <div class="container mt-3">
        <div class="row">
            <div class="col-md-6 order-md-2">
                <div class="account-links float-right">
                    <div class="col-md-12">
                        <div class="row" style="justify-content: end;" *ngIf="isLoggedIn && currentCustomer">
                            <ng-container>
                                <a class="float-right" routerLink="/customer">
                                    <i class="fa fa-user fa-lg">&nbsp;&nbsp;</i>{{getCurrentUserName()}}
                                </a>
                            </ng-container>
                        </div>
                        <div class="row" style="justify-content: end;">
                            <a *ngIf="isAdmin" [attr.href]="environment.adminSite + '/Customers/CustomerList.aspx?RetrieveSearch=1'" class="float-right">
                                <i class="fa fa-home fa-lg" aria-hidden="true">&nbsp;&nbsp;</i>Admin Portal
                            </a>
                            <a *ngIf="!isLoggedIn" routerLink="/login" class="float-right">
                                <i class="fa fa-lock fa-lg" aria-hidden="true">&nbsp;&nbsp;</i>Login
                            </a>
                            <a *ngIf="!isLoggedIn" class="float-right" routerLink="/create-account">
                                <i class="fa fa-user fa-lg" aria-hidden="true">&nbsp;&nbsp;</i>Create Account
                            </a>
                            <a href="/cart" onclick="return false" (click)="goToShoppingCart()" class="float-right">
                                <i class="fa fa-shopping-cart fa-lg" aria-hidden="true">&nbsp;&nbsp;</i>My Cart
                            </a>
                            <a *ngIf="isLoggedIn" href="#" (click)="logout($event)" class="float-right">
                                <i class="fa fa-lock fa-lg" aria-hidden="true">&nbsp;&nbsp;</i>Log Out
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 order-md-1">
                <a routerLink="/home"
                    ><img
                        class="logo img-fluid logo-margin"
                        src="assets/Care-Courses-Header-7132023.png"
                        title="{{ imageAltAndTitleTextEnum.CareCourseLogoTitle }}"
                        alt="Logo, {{ imageAltAndTitleTextEnum.CareCourseLogoAlt }}"
                /></a>
            </div>
        </div>
    </div>
    <app-menu [isLoggedIn]="isLoggedIn"></app-menu>
</div>
