import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAccessComponent } from './admin-access/admin-access.component';
import { CatalogRequestComponent } from './catalog-request/catalog-request.component';
import { ShoppingCartComponent } from './common/shopping-cart/shopping-cart.component';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PingPageComponent } from './ping-page/ping-page.component';
import { PublicPageComponent } from './public-page/public-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const appRoutes: Routes = [
    { path: 'courses/sort/1', redirectTo:'/publicpages/childcare_training', pathMatch:'full'},
    { path: 'courses/sort/2', redirectTo:'/publicpages/care_courses', pathMatch:'full'},
    { path: 'courses/sort/3', redirectTo:'/publicpages/childcare_training_topics_infants', pathMatch:'full'},
    { path: 'courses', redirectTo:'/publicpages/childcare_training', pathMatch:'full'},

    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'create-account', component: CustomerRegistrationComponent },
    { path: 'adminaccess', component: AdminAccessComponent },
    { path: 'publicpages/:pagename', component: PublicPageComponent },
    { path: 'home', component: HomeComponent },
    { path: 'catalog-request', component: CatalogRequestComponent },
    { path: 'cart', component: ShoppingCartComponent },
    { path: 'not-found', component: NotFoundComponent },
    { path: 'ping-page', component: PingPageComponent },
    { path: 'customer', loadChildren: () => import('./customers/customer.module').then(m => m.CustomerModule) },
    { path: 'courses', loadChildren: () => import('./courses/course.module').then(m => m.CourseModule) },
    { path: 'checkout', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) },
    { path: 'quiz/:enrollmentCodeId/:courseTypeId', loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule) },
    { path: 'bundle', loadChildren: () => import('./bundles/bundles.module').then(m => m.BundlesModule) },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', redirectTo: '/not-found' },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', useHash: false })],
})
export class AppRoutingModule {}
