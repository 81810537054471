<style>
    .serif {
        font-family: 'Source Serif Pro', serif;
    }
    .sans {
        font-family: 'Open Sans', sans-serif;
    }
    .max-width {
        max-width: 1024px;
    }
    .background-color {
        background-color: #fdefe0;
        height: 100vh;
    }
    .hero-banner {
        background: url(../assets/not-found.jpg) no-repeat center;
        background-size: cover;
        height: 600px;
        position: relative;
        width: 100%;
    }
    .hero-banner .inner-hero-text {
        margin-top: 200px;
        margin-bottom: 200px;
    }
    .hero-banner h1 {
        font-size: 5em;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        text-shadow: -1px 2px 9px #000000;
        text-transform: uppercase;
    }
    .hero-banner p {
        font-size: 1.5em;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        text-shadow: -1px 2px 9px #000000;
        text-transform: uppercase;
        margin: 0px 20px;
    }
    @media (max-width: 1024px) {
        .hero-banner .inner-hero-text {
            margin-top: 150px;
            margin-bottom: 150px;
        }
        .hero-banner h1 {
            font-size: 2.5em;
        }
    }
    .hero-banner .hero-btn-wrap {
        display: table;
        margin: auto;
        width: 100%;
        max-width: 1024px;
    }
    .hero-banner .hero-btns {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .hero-banner .hero-btns a {
        display: inline-block;
        padding: 10px 20px;
        color: #fff !important;
        min-width: 150px;
        text-align: center;
        font-weight: bold;
    }
    @media (max-width: 1024px) {
        .hero-banner .hero-btns a {
            min-width: auto;
            display: block;
        }
    }
    .Pad20 {
        padding: 20px;
    }
    .PadL20 {
        padding-left: 20px;
    }
    .PadR20 {
        padding-right: 20px;
    }
    .PadT20 {
        padding-top: 20px;
    }
    .PadB20 {
        padding-bottom: 20px;
    }
    .Pad30 {
        padding: 30px;
    }
    .PadL30 {
        padding-left: 30px;
    }
    .PadR30 {
        padding-right: 30px;
    }
    .PadT30 {
        padding-top: 30px;
    }
    .PadB30 {
        padding-bottom: 30px;
    }
    .Pad40 {
        padding: 40px;
    }
    .PadL40 {
        padding-left: 40px;
    }
    .PadR40 {
        padding-right: 40px;
    }
    .PadT40 {
        padding-top: 40px;
    }
    .PadB40 {
        padding-bottom: 40px;
    }
    .Pad60 {
        padding: 60px;
    }
    .PadL60 {
        padding-left: 60px;
    }
    .PadR60 {
        padding-right: 60px;
    }
    .PadT60 {
        padding-top: 60px;
    }
    .PadB60 {
        padding-bottom: 60px;
    }
    .Margin20 {
        margin: 20px;
    }
    .MarginL20 {
        margin-left: 20px;
    }
    .MarginR20 {
        margin-right: 20px;
    }
    .MarginT20 {
        margin-top: 20px;
    }
    .MarginB20 {
        margin-bottom: 20px;
    }
    .Margin30 {
        margin: 30px;
    }
    .MarginL30 {
        margin-left: 30px;
    }
    .MarginR30 {
        margin-right: 30px;
    }
    .MarginT30 {
        margin-top: 30px;
    }
    .MarginB30 {
        margin-bottom: 30px;
    }
    .Margin40 {
        margin: 40px;
    }
    .MarginL40 {
        margin-left: 40px;
    }
    .MarginR40 {
        margin-right: 40px;
    }
    .MarginT40 {
        margin-top: 40px;
    }
    .MarginB40 {
        margin-bottom: 40px;
    }
    .Margin60 {
        margin: 60px;
    }
    .MarginL60 {
        margin-left: 60px;
    }
    .MarginR60 {
        margin-right: 60px;
    }
    .MarginT60 {
        margin-top: 60px;
    }
    .MarginB60 {
        margin-bottom: 60px;
    }
    .hero-btns .green-btn {
        background-color: rgba(150, 179, 96, 0.8) !important;
    }
    .hero-btns .green-btn:hover {
        background-color: rgba(150, 179, 96, 1) !important;
    }
    .hero-btns .red-btn {
        background-color: rgba(226, 89, 78, 0.8) !important;
    }
    .hero-btns .red-btn:hover {
        background-color: rgba(226, 89, 78, 1) !important;
    }
    .hero-btns .gold-btn {
        background-color: rgba(227, 125, 54, 0.8) !important;
    }
    .hero-btns .gold-btn:hover {
        background-color: rgba(227, 125, 54, 1) !important;
    }
    .hero-btns .lavender-btn {
        background-color: rgba(178, 147, 217, 0.8) !important;
    }
    .hero-btns .lavender-btn:hover {
        background-color: rgba(178, 147, 217, 1) !important;
    }
    .hero-btns .pastel-btn {
        background-color: rgba(140, 202, 228, 0.8) !important;
    }
    .hero-btns .pastel-btn:hover {
        background-color: rgba(140, 202, 228, 1) !important;
    }
    .hero-btns .green-btn,
    .hero-btns .red-btn,
    .hero-btns .gold-btn,
    .hero-btns .lavender-btn,
    .hero-btns .pastel-btn {
        margin: 0 !important;
        border: none !important;
    }
    .hero-btns .green-btn:hover,
    .hero-btns .red-btn:hover,
    .hero-btns .gold-btn:hover,
    .hero-btns .lavender-btn:hover,
    .hero-btns .pastel-btn:hover {
        text-decoration: none !important;
    }
    .blue-bg {
        background-color: #33506b;
    }
    .light-blue-bg {
        background-color: #5474af;
    }
    .light-grey-bg,
    .light-gray-bg {
        background-color: #f2f2f2;
    }
    .blue-banner {
        background-color: #33506b;
        border-bottom: 8px solid #5474af;
        padding: 20px;
        text-align: center;
        color: #fff;
        font-size: 1.2em;
    }
    .img-column {
        position: relative;
    }
    .img-column-heading {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        color: #fff;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    }
    .img-column-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        color: #fff;
        background: rgba(0, 0, 0, 0.8);
    }
    @media (max-width: 1024px) {
        .img-column,
        .img-column-text,
        .img-column-heading,
        .m-text-center {
            text-align: center;
        }
        .img-column,
        .m-width {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .img-column-text h4,
    .img-column-text h3,
    .img-column-text p {
        color: #fff;
    }
    @media (min-width: 768px) {
        .eq-height {
            height: 100%;
        }
    }
    .blue-banner-img {
        background: url(../UploadedImages/Images/blue-banner.jpg) no-repeat center;
        background-size: cover;
        padding: 100px 0;
        color: #fff !important;
    }
    @media (max-width: 1024px) {
        .blue-banner-img {
            background-color: #33506b;
            background-image: none;
            padding: 40px 0;
        }
    }
</style>
<div class="background-color">
    <div class="hero-banner">
        <div class="row">
            <div class="mx-auto text-center col-md-12 max-width">
                <div class="inner-hero-text">
                    <h1 class="text-white">Oops!</h1>
                    <p class="text-white" style="font-size: 1em">Error Code: 404</p>
                    <br />
                    <p class="text-white">We searched high and low but can't find the page you're looking for.</p>
                    <p class="text-white" style="font-size: 1.2em">Let's find a better place for you to go.</p>
                </div>
            </div>
        </div>
        <div class="hero-btn-wrap">
            <div class="hero-btns">
                <a href="../home" class="pastel-btn" alt="home">Home</a>
                <a href="/publicpages/care_courses" class="green-btn" alt="Care Courses Course Listing">Course Listing</a>
                <a href="/publicpages/stateinformation" class="red-btn" alt="Care Courses State Information">State Information</a>
                <a href="/publicpages/cdatraining" class="gold-btn" alt="Care Courses CDA Training Courses">CDA Training</a>
            </div>
        </div>
    </div>
    <div class="blue-banner"></div>
    <br />
    <p style="text-align: center; font-size: 1em">
        Can't find what you're looking for? We're happy to help! Call us at 1-800-685-7610 or email us at
        <a href="mailto:info@carecourses.com">info@carecourses.com</a>
    </p>
</div>
