import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class EmptyListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'This entity list is not used, but is necessary to leverage entity list paging',
                }),
            ],
        };
        super(listConfig);
    }
}
