import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICourseFormat } from '@model/interfaces/course-format';
import { IItem } from '@model/interfaces/item';
import { IShoppingCart } from '@model/interfaces/shopping-cart';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Guid } from 'guid-typescript';

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartService {
    private _guid: string;

    public cartShippingTypeId?: number = null;
    // Cart Observable
    private _$cartInventory = new BehaviorSubject<IShoppingCart[]>(null);

    get $cartInventory(): Observable<IShoppingCart[]> {
        return this._$getCart();
    }

    get $cartSubtotal(): Observable<number> {
        return this._$getCart().pipe(
            filter((cart) => cart != null),
            map((cart) => this._subtotal(cart)),
        );
    }

    get $cartShippableSubtotal(): Observable<number> {
        return this._$getCart().pipe(
            filter((cart) => cart != null),
            map((cart) => this._shippableSubtotal(cart)),
        );
    }

    get $cartCount(): Observable<number> {
        return this._$getCart().pipe(
            filter((cart) => cart != null),
            map((cart) => this._count(cart)),
        );
    }

    get $cartShippableCount(): Observable<number> {
        return this._$getCart().pipe(
            filter((cart) => cart != null),
            map((cart) => this._shippableCount(cart)),
        );
    }

    private _requestIsOut: boolean;
    /**
     * This function ensures that whenever the cart is referenced, it has been loaded from the back end
     * @returns If no current cart, the backend inventory, otherwise the current cart
     */
    private _$getCart(): Observable<IShoppingCart[]> {
        // If we have not gotten the cart yet
        if (!this._$cartInventory.getValue() && !this._requestIsOut) {
            this._setGuid();
            this._requestIsOut = true;
            return this.http.get<IShoppingCart[]>(`/cart/${this._guid}`).pipe(
                finalize(() => (this._requestIsOut = false)),
                switchMap((inventory) => {
                    this._updateCurrentCartInventory(inventory);
                    return this._$cartInventory.asObservable();
                }),
            );
        }
        return this._$cartInventory.asObservable();
    }

    private _updateCurrentCartInventory(cart: IShoppingCart[]): void {
        const NextCart: IShoppingCart[] = JSON.parse(JSON.stringify(cart));
        this._$cartInventory.next(NextCart);
    }

    private _subtotal(inventory: IShoppingCart[]): number {
        let price = 0;
        if (!inventory) {
            return price;
        }
        for (const item of inventory) {
            if (item.CourseFormatId) {
                price += item.CourseFormat.Price * item.Qty;
            } else if (item.Item && item.Item.BundleId) {
                price += item.Item.Bundle.BundlePrice * item.Qty;
            } else {
                price += item.Item.Price * item.Qty;
            }
        }
        return price;
    }

    private _shippableSubtotal(inventory: IShoppingCart[]): number {
        let price = 0;
        if (!inventory) {
            return price;
        }
        for (const item of inventory) {
            if (item.CourseFormatId && item.CourseFormat.ApplyShipping) {
                price += item.CourseFormat.Price * item.Qty;
            } else if (item.ItemId && !item.Item.Bundle?.BundleDetails && item.Item.ChargeShipping) {
                price += item.Item.Price * item.Qty;
            } else if (item.Item.Bundle?.BundleDetails && item.Item.ChargeShipping) {
                price += (item.Item?.Bundle?.BundlePrice ?? 0) * item.Qty;
            }
        }
        return price;
    }

    private _count(inventory: IShoppingCart[]): number {
        let count = 0;
        if (!inventory) {
            return count;
        }
        for (const item of inventory) {
            count += +item.Qty;
        }
        return count;
    }

    /** Total count of all items in the cart that are shippable. For bundles, items within will be checked individually */
    private _shippableCount(inventory: IShoppingCart[]): number {
        let count = 0;
        if (!inventory) {
            return count;
        }
        for (const item of inventory) {
            if (item.CourseFormatId && item.CourseFormat.ApplyShipping) {
                count += +item.Qty;
            } else if (item.ItemId && !item.Item.Bundle?.BundleDetails && item.Item.ChargeShipping) {
                count += +item.Qty;
            } else if (item.Item.Bundle?.BundleDetails && item.Item.ChargeShipping) {
                for (const detail of item.Item.Bundle.BundleDetails) {
                    if (
                        detail.Active &&
                        ((detail.CourseFormat?.Active && detail.CourseFormat?.ApplyShipping) || (detail.Item?.Active && detail.Item?.ChargeShipping))
                    ) {
                        count += item.Qty;
                    }
                }
            }
        }
        return count;
    }

    // Shopping cart service

    constructor(private http: HttpClient, private cookieService: CookieService) {}

    /**
     * @param courseFormat The format to add to cart
     * @param totalQty (optional) Overrides the existing format qty (if there is one)
     * @param additiveQty (optional) Adds to the existing format qty (if there is one)
     * @param updateMainInventory If true, the service's main cart inventory BehaviorSubject will be updated, which will re-render components subscribed to it
     * @returns The updated shopping cart
     */
    updateCartWithCourse(courseFormat: ICourseFormat, totalQty?: number, additiveQty?: number): Observable<IShoppingCart> {
        return this._updateCart(courseFormat, undefined, totalQty, additiveQty);
    }

    /**
     * @param updateMainInventory If true, the service's main cart inventory BehaviorSubject will be updated, which will re-render components subscribed to it
     */
    updateCartWithItem(item: IItem, quizSubmissionId = 0, totalQty?: number, additiveQty?: number): Observable<IShoppingCart> {
        return this._updateCart(undefined, item, totalQty, additiveQty, quizSubmissionId);
    }

    private _updateCart(
        courseFormat: ICourseFormat,
        item: IItem,
        totalQty?: number,
        additiveQty?: number,
        quizSubmissionId?: number,
    ): Observable<IShoppingCart> {
        return this._$getCart().pipe(
            take(1),
            switchMap((currentCart) => {
                const newItem = this.getEmptyShoppingCart();
                let itemAlreadyInCart: IShoppingCart;
                if (courseFormat) {
                    itemAlreadyInCart = currentCart.find((item) => item.CourseFormatId === courseFormat.CourseFormatId);
                    if (!itemAlreadyInCart) {
                        newItem.CourseFormatId = courseFormat.CourseFormatId;
                    }
                } else if (item) {
                    itemAlreadyInCart = currentCart.find(
                        (i) => item.ItemId === i.ItemId && (!i.QuizSubmissionId || !quizSubmissionId || i.QuizSubmissionId === quizSubmissionId),
                    );
                    if (!itemAlreadyInCart) {
                        newItem.ItemId = item.ItemId;
                        newItem.QuizSubmissionId = quizSubmissionId;
                    }
                }

                if (!itemAlreadyInCart) {
                    newItem.Qty = totalQty || additiveQty || 1;
                    return this.http.post<IShoppingCart>(`/cart`, newItem).pipe(
                        tap((i) => {
                            // After subscribed, add result to cart inventory
                            currentCart.unshift(i);
                            this._updateCurrentCartInventory(currentCart);
                        }),
                    );
                } else if (+totalQty !== +itemAlreadyInCart.Qty && (!item || quizSubmissionId === 0 || !quizSubmissionId)) {
                    itemAlreadyInCart.Qty = totalQty || +itemAlreadyInCart.Qty + (additiveQty || 1);
                    itemAlreadyInCart.Customer = null;
                    itemAlreadyInCart.ShippingTypeId = this.cartShippingTypeId;
                    return this.http.put<IShoppingCart>(`/cart`, itemAlreadyInCart).pipe(
                        tap(() => {
                            this._updateCurrentCartInventory(currentCart);
                        }),
                    );
                }
                return of<IShoppingCart>(); // Will not get here
            }),
        );
    }

    removeFromCart(shoppingCartId: number): Observable<IShoppingCart> {
        return this.http.delete<IShoppingCart>(`/cart/${shoppingCartId}`).pipe(
            tap(() => {
                const currentCartInventory = this._$cartInventory.getValue().filter((item) => item.ShoppingCartId !== shoppingCartId);
                this._updateCurrentCartInventory(currentCartInventory);
            }),
        );
    }

    removeMultipleItemsFromCartById(shoppingCartIds: number[]): Observable<IShoppingCart> {
        return this.http.post<IShoppingCart>(`/cart/removeMultipleItemsByIds`, shoppingCartIds).pipe(
            tap(() => {
                const currentCartInventory = this._$cartInventory
                    .getValue()
                    .filter((item) => !shoppingCartIds.some((x) => item.ShoppingCartId === x));
                this._updateCurrentCartInventory(currentCartInventory);
            }),
        );
    }

    convertFromAnonymousToCustomerCart(userId: number): Observable<IShoppingCart[]> {
        this._setGuid();
        return this.http
            .put<IShoppingCart[]>(`/cart/convert-cart/${userId}/${this._guid}`, null)
            .pipe(tap((convertedCart) => this._updateCurrentCartInventory(convertedCart)));
    }

    updateShoppingCartShippingType(userId: number, shippingTypeId: number): Observable<any> {
        return this.http.post<any>(`/cart/update-shipping-type/${userId}/${shippingTypeId}`, null);
    }

    getEmptyShoppingCart(): IShoppingCart {
        return {
            SessionId: this._guid,
            ShippingTypeId: null,
            ShoppingCartId: 0,
        } as IShoppingCart;
    }

    deleteAllFromCart(): void {
        this._$cartInventory.next([]);
    }

    resetShoppingCartService(): void {
        this._updateCurrentCartInventory(null);
    }

    // Helpers
    private _setGuid(): void {
        const cookieGuid = this.cookieService.get('guid');
        if (cookieGuid) {
            this._guid = cookieGuid;
        } else {
            this._guid = String(Guid.create()) + '';
            this.cookieService.set('guid', this._guid, { path: '/', sameSite: 'Lax'});
        }
    }

    get getGuid(): string {
        return this._guid;
    }
}
