import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPageTemplate } from '@model/interfaces/page-template';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageTemplateService {
    constructor(public http: HttpClient) {}

    getTemplate(pageName: string): Observable<IPageTemplate> {
        return this.http.get<IPageTemplate>(`/pageTemplate/${pageName}`);
    }
}
