import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NotesOnHoldColorChangeService {
    isColorChanged = false;
    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    setColorChange(val: boolean): void {
        this.isColorChanged = val;
    }
}
