import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-widget',
    styleUrls: ['./loading-widget.less'],
    template: `<div class="fa-3x loading-widget text-center" [class.compressed]="compressed"><i class="fa fa-circle-o-notch fa-spin"></i></div>`,
})
export class LoadingWidget {
    @Input() compressed = false;

    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }
}
