import { Pipe, PipeTransform } from '@angular/core';
import { CountriesEnums } from '../constants/Enums';
import { CombinePhoneStrings, SplitPhoneString } from '../functions/common-functions.library';

@Pipe({ name: 'usOrCanadianPhone' })
export class UsOrCanadianPhonePipe implements PipeTransform {
    USCountryId = CountriesEnums.US;
    CanadaCountryId = CountriesEnums.Canada;
    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    transform(rawNumber: string, countryId = CountriesEnums.US): string {
        if (rawNumber && (countryId === this.USCountryId || countryId === this.CanadaCountryId)) {
            const phoneData = SplitPhoneString(rawNumber);
            const reg = /([0-9][0-9][0-9])([0-9][0-9][0-9])([0-9][0-9][0-9][0-9])/;
            phoneData.Phone = phoneData.Phone.replace(reg, '($1) $2-$3');
            return CombinePhoneStrings(phoneData.Phone, phoneData.Extension);
        }
        return rawNumber;
    }
}
