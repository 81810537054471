import { Validators } from '@angular/forms';
import { IMetaItem } from '@mt-ng2/base-service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { CountriesEnums } from '../../common/constants/Enums';
import { GetState, InternationalPhoneMask, SplitPhoneString } from '../../common/functions/common-functions.library';
import { CustomerBillingDynamicControls, ICustomerBillingDynamicControlsParameters } from '../form-controls/customer-billing.form-controls';
import { ICustomerBilling } from '../interfaces/customer-billing';
import { IState } from '../interfaces/state';

export interface ICustomerBillingDynamicControlsParametersPartial extends ICustomerBillingDynamicControlsParameters {
    countryMetaItems?: IMetaItem[];
    states?: IState[];
}

export class CustomerBillingDynamicControlsPartial extends CustomerBillingDynamicControls {
    constructor(customerbillingPartial?: ICustomerBilling, additionalParameters?: ICustomerBillingDynamicControlsParametersPartial) {
        super(customerbillingPartial, additionalParameters);

        // Set countries dropdown
        this.Form.CountryId.options = additionalParameters.countryMetaItems;

        // US states dropdown -- Additional field for when Country is US
        this._setStateDropdown(customerbillingPartial, additionalParameters);

        // Zip code info
        this._setZipCode(customerbillingPartial);

        // Phone number info
        this._setPhonesAndExtensions(customerbillingPartial);

        // Misc
        this._setLabels();
        this._setRequireds();
        this._setAsTextboxes();
    }

    private _setStateDropdown(customerbillingPartial: ICustomerBilling, additionalParameters?: ICustomerBillingDynamicControlsParametersPartial): void {
        const statesMetaItems = additionalParameters.states.map((state) => ({ Id: state.StateId, Name: state.StateDesc } as IMetaItem));
        const stateId = customerbillingPartial?.CountryId === CountriesEnums.US && customerbillingPartial?.Id ? GetState(additionalParameters.states, undefined, customerbillingPartial?.BillingAddress4).StateId : null;
        this.Form.BillingStateId = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'BillingStateId',
            options: statesMetaItems,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: {required: true},
            value: stateId,
        });
    }

    private _setZipCode(customerBillingPartial: ICustomerBilling): void {
        this.Form.USZipCode = new DynamicField({
            formGroup: this.formGroup,
            label: 'Postal/Zip Code',
            name: 'USZipCode',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(10), Validators.minLength(5), Validators.required ],
            validators: { 'maxlength': 10, 'minlength': 5, required: true, showRequired: true },
            value: customerBillingPartial?.BillingAddress5,
        });

        this.Form.BillingAddress5 = new DynamicField({
            formGroup: this.formGroup,
            label: 'Billing Address5',
            name: 'BillingAddress5',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(200) ],
            validators: { },
            value: customerBillingPartial?.BillingAddress5,
        });
    }

    private _setPhonesAndExtensions(customerbillingPartial: ICustomerBilling): void {
        this.Form.HomePhone.type.inputType = InputTypes.Phone;
        this.Form.WorkPhone.type.inputType = InputTypes.Phone;
        this.Form.CellPhone.type.inputType = InputTypes.Phone;

        let homePhone = SplitPhoneString(customerbillingPartial?.HomePhone);
        let workPhone = SplitPhoneString(customerbillingPartial?.WorkPhone);

        // Home phone
        this.Form.HomePhone.value = homePhone.Phone;
        this.Form.HomePhone.validation = [ Validators.minLength(10), Validators.required ];
        this.Form.HomePhone.validators = { minlength: 10, required: true, showRequired: true };

        this.Form.HomePhonePlaintext = new DynamicField({
            formGroup: this.formGroup,
            label: 'Primary Phone Number',
            name: 'HomePhonePlaintext',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(50), Validators.required, Validators.pattern(InternationalPhoneMask) ],
            validators: { 'maxlength': 50, required: true, showRequired: true, 'pattern': InternationalPhoneMask },
            value: homePhone.Phone,
        });

        this.Form.HomeExt = new DynamicField({
            formGroup: this.formGroup,
            label: 'Ext.',
            name: 'HomeExt',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(4), Validators.pattern(InternationalPhoneMask)],
            validators: { maxlength: 4, 'pattern': InternationalPhoneMask },
            value: homePhone.Extension,
        });

        // Work phone
        this.Form.WorkPhone.value = workPhone.Phone;
        this.Form.WorkPhone.validation = [ Validators.minLength(10) ];
        this.Form.WorkPhone.validators = { minlength: 10 };

        this.Form.WorkPhonePlaintext = new DynamicField({
            formGroup: this.formGroup,
            label: 'Work Phone',
            name: 'WorkPhonePlaintext',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(50), Validators.pattern(InternationalPhoneMask) ],
            validators: { 'maxlength': 50, 'pattern': InternationalPhoneMask },
            value: workPhone.Phone,
        });

        this.Form.WorkExt = new DynamicField({
            formGroup: this.formGroup,
            label: 'Ext.',
            name: 'WorkExt',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(4), Validators.pattern(InternationalPhoneMask)],
            validators: { maxlength: 4, 'pattern': InternationalPhoneMask },
            value: workPhone.Extension,
        });

        // Cell phone
        this.Form.CellPhone.validation = [ Validators.minLength(10) ];
        this.Form.CellPhone.validators = { minlength: 10 };

        this.Form.CellPhonePlaintext = new DynamicField({
            formGroup: this.formGroup,
            label: 'Cell Phone',
            name: 'CellPhonePlaintext',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.maxLength(50), Validators.pattern(InternationalPhoneMask) ],
            validators: { 'maxlength': 50, 'pattern': InternationalPhoneMask },
            value: customerbillingPartial?.CellPhone,
        });
    }

    private _setLabels(): void {
        this.Form.BillingAddress1.labelHtml = '<label>Address Line 1</label>';
        this.Form.BillingAddress2.labelHtml = '<label>Address Line 2</label>';
        this.Form.BillingAddress3.labelHtml = '<label>Address Line 3</label>';
        this.Form.BillingCity.labelHtml = '<label>City</label>';
        this.Form.BillingAddress4.labelHtml = '<label>Province</label>';
        this.Form.BillingAddress5.labelHtml = '<label>Postal/Zip Code</label>';
        this.Form.CompanyName.labelHtml = '<label>Facility Name</label>';
        this.Form.HomePhone.labelHtml = '<label>Primary Phone Number</label>';
    }

    private _setRequireds(): void {
        this.Form.FirstName.setRequired(true);
        this.Form.LastName.setRequired(true);
        this.Form.HomePhone.setRequired(true);
        this.Form.BillingAddress1.setRequired(true);
        this.Form.BillingCity.setRequired(true);
        this.Form.BillingStateId.setRequired(true);
        this.Form.BillingAddress4.setRequired(true);
        this.Form.BillingAddress5.setRequired(true);
        this.Form.CountryId.setRequired(true);
    }

    private _setAsTextboxes(): void {
        this.Form.FirstName.type.inputType = InputTypes.Textbox;
        this.Form.MiddleName.type.inputType = InputTypes.Textbox;
        this.Form.LastName.type.inputType = InputTypes.Textbox;
        this.Form.CompanyName.type.inputType = InputTypes.Textbox;
        this.Form.BillingAddress1.type.inputType = InputTypes.Textbox;
        this.Form.BillingAddress2.type.inputType = InputTypes.Textbox;
        this.Form.BillingAddress3.type.inputType = InputTypes.Textbox;
        this.Form.BillingCity.type.inputType = InputTypes.Textbox;
        this.Form.BillingAddress4.type.inputType = InputTypes.Textbox;
        this.Form.BillingAddress5.type.inputType = InputTypes.Textbox;
    }
}
