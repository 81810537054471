import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { sortByProperty } from '@mt-ng2/common-functions';

import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { IListItem } from '@model/interfaces/list-item';
import { DatePipe } from '@angular/common';
import { Meta } from '@angular/platform-browser';

import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _states: IState[];
    private _countries: ICountry[];

    constructor(private http: HttpClient, private datePipe: DatePipe, private meta: Meta) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                map((answer) => {
                    answer.sort((a, b) => a.StateDesc.localeCompare(b.StateDesc));
                    this._states = answer;
                    return answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(tap((answer) => (this._countries = answer)));
        } else {
            return of(this._countries);
        }
    }

    getFirstStylesFromHTML(html: string): string {
        const styleStartIndex = html.indexOf('style');
        const firstQuoteAfterStyle = html.indexOf('"', styleStartIndex);
        const lastQuoteAfterStyle = html.indexOf('"', firstQuoteAfterStyle + 1);
        const cutStyle = html.substring(firstQuoteAfterStyle + 1, lastQuoteAfterStyle);
        return cutStyle;
    }

    getListItems(ListTypeId: number): Observable<IListItem[]> {
        return this.http.get<IListItem[]>(`/options/listitems/${ListTypeId}`).pipe(
            tap((answer) => {
                sortByProperty(answer, 'ListItemName');
            }),
        );
    }

    formatFullDate(date: Date): string {
        if (date) {
            const dateNoZ = date.toString().replace('Z', '');
            return (
                new Date(dateNoZ).toLocaleDateString() + ' ' + new Date(dateNoZ).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
            );
        }
        return '';
    }

    formatDate(date: Date): string {
        return date ? new Date(date.toString().replace('Z', '')).toLocaleDateString() : '';
    }

    clearMetaTags(): void {
        this.meta.removeTag('name=description');
        this.meta.removeTag('name=keywords');
    }
}
