<div id="shipping-cards-panel" style="height: 1px; overflow: hidden">
    &nbsp;
    <!-- Angular anchoring is buggy. Adding a div to anchor to was only way to make this work -->
</div>
<div class="miles-card">
    <h4>Shipping Address</h4>
    <collapsible-card-entity-list
        *ngIf="customerShippingList && !editingItem"
        [entities]="customerShippingList"
        [itemTemplate]="shippingTemplate"
        [selectedEntity]="selectedItem"
        [includeEdit]="true"
        [includeAdd]="true"
        [includeDelete]="true"
        [labelTextForShowHide]="'View or Add Shipping Addresses'"
        [textForAddButton]="'Add Shipping Address'"
        [(isOpen)]="showAllAddresses"
        [selectButton]="selectButton"
        [selectButtonText]="selectButtonText"
        [fewTotal]="numberOfItemsBeforeExpanded"
        [numOfColumns]="numberOfItemsBeforeExpanded"
        [emptyEntity]="emptyShippingAddress"
        (edit)="edit($event)"
        (entityClick)="edit($event)"
        (add)="edit($event)"
        (select)="select($event)"
        (delete)="onItemDeleted($event)"
    >
    </collapsible-card-entity-list>
    <app-shipping-detail
        *ngIf="editingItem"
        [customerShipping]="editingItem"
        (onNewPrimary)="setNewPrimary($event)"
        (onComplete)="save()"
        (onCancel)="cancel()"
    ></app-shipping-detail>
</div>
<br />

<ng-template #shippingTemplate let-shipping>
    <app-shipping-info [shipping]="shipping" [isAdmin]="isAdmin"></app-shipping-info>
</ng-template>
