import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { CountriesEnums } from '../common/constants/Enums';
import { GetState } from '../common/functions/common-functions.library';
import { CommonService } from '../common/services/common.service';
import { IExpandableObject } from '../model/expandable-object';
import { IMetaItem } from '../model/interfaces/base';
import { IState } from '../model/interfaces/state';
import { CatalogRequestDynamicControlsPartial } from '../model/partials/catalog-request-partial.form-controls';
import { CatalogRequestService } from './catalog-request.service';

@Component({
    selector: 'app-catalog-request',
    templateUrl: './catalog-request.component.html',
})
export class CatalogRequestComponent implements OnInit, AfterViewChecked {
    @Output('onComplete') completeEmitter = new EventEmitter();
    @Output('onCancel') cancelEmitter = new EventEmitter();

    catalogRequest = this._catalogRequestService.emptyCatalogRequestService;
    catalogRequestForm: UntypedFormGroup;
    selectedCountryId: number;
    countries: IMetaItem[];
    statesMetaItems: IMetaItem[];
    states: IState[];
    config: any = { formObject: [], viewOnly: [] };
    doubleClickIsDisabled: boolean;
    formControls: IExpandableObject;
    zipInvalid: string;

    get isCountryUS(): boolean {
        return this.selectedCountryId === CountriesEnums.US;
    }
    get isCountryCanada(): boolean {
        return this.selectedCountryId === CountriesEnums.Canada;
    }

    constructor(
        private _fb: UntypedFormBuilder,
        private _catalogRequestService: CatalogRequestService,
        private _notificationService: NotificationsService,
        private _commonService: CommonService,
        private _location: Location,
        private cdr: ChangeDetectorRef,
        private title: Title,
        private meta: Meta,
        private authService: AuthService,
        private commonService: CommonService,
    ) {}

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    ngOnInit(): void {
        this.setTitleAndPageData();
        this.selectedCountryId = CountriesEnums.US;
        forkJoin([this._commonService.getCountries(), this._commonService.getStates()]).subscribe(([countries, states]) => {
            this.countries = countries.map((country) => ({ Id: country.CountryId, Name: country.CountryName } as IMetaItem));
            this.states = states;
            this.statesMetaItems = states.map((state) => ({ Id: state.StateId, Name: state.StateDesc } as IMetaItem));
            this._createForm();
            this.newCountry(CountriesEnums.US);
        });
    }

    setTitleAndPageData(): void {
        this.title.setTitle('Care Courses - Request a Catalog');
        this.commonService.clearMetaTags();
        this.meta.addTags([
            {
                content: `Care Courses Catalog Request`,
                name: 'keywords',
            },
        ]);
    }

    private _createForm(): void {
        this.catalogRequestForm = this._fb.group({ CatalogRequest: this._fb.group({}) });
        this.formControls = new CatalogRequestDynamicControlsPartial(this.catalogRequest, {
            countries: this.countries,
            formGroup: 'CatalogRequest',
            states: this.statesMetaItems,
        }).Form;
    }

    newCountry(countryId: number): void {
        this.selectedCountryId = countryId;
        this._clearCountryFields();
    }

    private _clearCountryFields(): void {
        setTimeout(() => {
            this.catalogRequestForm.get('CatalogRequest.Address1')?.setValue('');
            this.catalogRequestForm.get('CatalogRequest.Address2')?.setValue('');
            this.catalogRequestForm.get('CatalogRequest.City')?.setValue('');
            this.catalogRequestForm.get('CatalogRequest.StateId')?.setValue('');
            this.catalogRequestForm.get('CatalogRequest.USZipCode')?.setValue('');
            this.catalogRequestForm.get('CatalogRequest.Address3')?.setValue('');
            this.catalogRequestForm.get('CatalogRequest.Address4')?.setValue('');
            this.catalogRequestForm.get('CatalogRequest.Address5')?.setValue('');
        });
    }

    formSubmitted(): void {
        if (this.catalogRequestForm.valid && !this.zipInvalid) {
            Object.assign(this.catalogRequest, this.catalogRequestForm.value.CatalogRequest);
            this.catalogRequest.Address4 =
                this.catalogRequest.Address4 || GetState(this.states, Number(this.catalogRequestForm.get('CatalogRequest.StateId').value)).StateCode;
            this.catalogRequest.Address5 = this.catalogRequest.Address5 || this.catalogRequestForm.value.CatalogRequest.USZipCode;
            this.saveCatalogRequest();
        } else {
            markAllFormFieldsAsTouched(this.catalogRequestForm);
            if (this.zipInvalid) {
                this._notificationService.error(this.zipInvalid);
            } else {
                this._notificationService.error('Please enter all required fields');
            }
            setTimeout(() => (this.doubleClickIsDisabled = false));
        }
    }

    saveCatalogRequest(): void {
        if (!this.catalogRequest.EmailAddress) {
            this.catalogRequest.EmailAddress = null;
        }
        this._catalogRequestService.addRequest(this.catalogRequest).subscribe(() => {
            this._notificationService.success('Request sent successfully');
            setTimeout(() => {
                this._location.back();
            }, 1000);
        });
    }

    cancel(): void {
        this._location.back();
    }
}
