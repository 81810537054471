import { IPhone } from '@mt-ng2/phone-control';
import { IState } from '../../model/interfaces/state';

// If this is changed, a script must be run to convert the existing DB data
const phoneExtensionDelimiter = ' x ';

export function SplitPhoneString(phone: string): IPhone {
    const phoneSplit = phone?.split(phoneExtensionDelimiter);
    return {
        Extension: phoneSplit?.length > 1 ? phoneSplit[phoneSplit.length - 1] : '',
        Phone: phoneSplit ? phoneSplit[0] : '',
    } as IPhone;
}

export function CombinePhoneStrings(base: string, ext: string): string {
    return base + (ext ? phoneExtensionDelimiter + ext : '');
}

export function TopOfPage(): void {
    window.scroll(0, 0);
}

export function TopOfPageContent(): void {
    document.getElementById('top-of-page-content').scrollIntoView();
}

export function FormatUsZipCode(zip: string): string {
    if (zip) {
        if (zip.length === 10) {
            return zip;
        } else if (zip.length > 5) {
            zip = zip.replace('-', '');
            const begin = zip.substring(0, 5);
            const end = zip.substring(5, 9);
            return begin + '-' + end;
        } else {
            return zip;
        }
    } else {
        return '';
    }
}

export function FormatNonUSPhoneNumber(phoneNum: string): string {
    return phoneNum.replace(/\D/g, '');
}

// Disallow letters
export const InternationalPhoneMask = new RegExp('^[0-9]*$');

export function GetState(states: IState[], stateId?: number, stateCode?: string, stateName?: string): IState {
    return states.find((state) => state.StateId === stateId || state.StateCode === stateCode || state.StateDesc === stateName);
}
