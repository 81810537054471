
<div #menuDiv class="menu-div size-constant">
    <div class="menu-component">
        <div class="container">
            <nav class="header-main-nav navbar navbar-expand-xl navbar-dark" (mouseleave)="!hamburgerShowing && clearMenus()">
                <button #hamburger class="navbar-toggler size-constant" type="button" (click)="hasOpenHamburgerMenu = !hasOpenHamburgerMenu">
                    <span class="navbar-toggler-icon size-constant"></span>
                </button>
                <div class="collapse navbar-collapse" [class.show]="hasOpenHamburgerMenu">
                    <ng-container
                        *ngTemplateOutlet="
                            navMenu;
                            context: {
                                $implicit: navItems,
                                itemIndex: 0,
                                isChild: false,
                                depth: 0
                            }
                        "
                    ></ng-container>
                </div>
            </nav>
        </div>
    </div>
</div>

<!-- This recursive element has three significant layers at various depths:
        depth 0: root navigation (which will contain subnavs)
        depth 1: subnav -- child of root navigation
        depth 2+: nested subnav -- child of subnav (or annother nested subnav) -- ancestor of root navigation
    Each significant layer has unique styling and functionality -->
<ng-template #navMenu let-navItems let-itemIndex="itemIndex" let-depth="depth">
    <ul
        class="header-nav"
        [class.navbar-nav]="depth == 0"
        [class.dropdown-menu]="depth > 0"
        [class.grand-child]="depth > 1"
        [class.show]="menusOpen[itemIndex]"
    >
        <li
            class="header-nav-item"
            [class.dropdown]="depth == 0"
            *ngFor="let navItem of navItems; let i = index"
            [ngStyle]="{ 'padding-left': hamburgerShowing ? 1.2 * depth + 'rem' : '0' }"
        >
            <a
                class="header-nav-link text-nowrap"
                [class.nav-link]="depth == 0"
                [class.dropdown-item]="depth > 0"
                (click)="navItemClicked(itemIndex + i + '.', navItem)"
                (mouseenter)="navItemHover(itemIndex + i + '.')"
                [href]="navItem.CustomURL || navItem.PageTemplatePath"
                onclick="return false;"
            >
                {{ navItem.Text }}
                <span *ngIf="hasChildren(navItem)">
                    &nbsp;
                    <span *ngIf="depth == 0">
                        <i *ngIf="!menusOpen[itemIndex + i + '.']" class="fa fa-caret-right" style="width: 12px;"></i>
                        <i *ngIf="menusOpen[itemIndex + i + '.']" class="fa fa-caret-down" style="width: 12px;"></i>
                    </span>
                    <span *ngIf="depth > 0">
                        <i *ngIf="!hamburgerShowing || !menusOpen[itemIndex + i + '.']" class="fa fa-caret-right"></i>
                        <i *ngIf="hamburgerShowing && menusOpen[itemIndex + i + '.']" class="fa fa-caret-down"></i>
                    </span>
                </span>
            </a>
            <div *ngIf="hasChildren(navItem) && menusOpen[itemIndex + i + '.']">
                <ng-container
                    *ngTemplateOutlet="
                        navMenu;
                        context: {
                            $implicit: navItem.Children,
                            itemIndex: itemIndex + i + '.',
                            depth: depth + 1
                        }
                    "
                ></ng-container>
            </div>
        </li>
    </ul>
</ng-template>
