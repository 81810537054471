import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, MtAuthGuard } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { mergeMap } from 'rxjs/operators';
import { PageContentService } from '../cms/page-content/page-content-service';
import { PageContentEnums } from '../common/constants/Enums';
import { TopOfPage } from '../common/functions/common-functions.library';
import { CommonService } from '../common/services/common.service';
import { ShoppingCartService } from '../common/shopping-cart/shopping-cart.service';

@Component({
    selector: 'app-login',
    styleUrls: ['./login.component.less'],
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    logoFull: string;
    returnUrl = '';
    pageContentEnums = PageContentEnums;
    LoginCreatePageContent: string;
    LoginBottomPageContent: string;
    isPasswordVisible = false;

    protected authService: AuthService;
    protected router: Router;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    protected meta: Meta;
    protected shoppingCartService: ShoppingCartService;
    protected activatedRoute: ActivatedRoute;
    protected pageContentService: PageContentService;

    constructor(injector: Injector, private commonService: CommonService) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.meta = injector.get(Meta);
        this.pageContentService = injector.get(PageContentService);
        this.shoppingCartService = injector.get(ShoppingCartService);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.logoFull = `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        this.createForm();
        this.setPageTitle();
        this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
        window.scrollTo(0, 0);
    }

    setPageTitle(): void {
        this.commonService.clearMetaTags();
        this.meta.addTags([
            {
                content: `Care Courses Login`,
                name: 'keywords',
            },
            {
                content: 'Users can use this page to login',
                name: 'description',
            },
        ]);
    }

    createForm(): void {
        if (!this.loginForm) {
            this.loginForm = new UntypedFormGroup({});
        }
        this.loginForm.addControl('password', new UntypedFormControl(''));
        this.loginForm.addControl('username', new UntypedFormControl('', [Validators.required.bind(this)]));
    }

    showUsernameRequiredError(): boolean {
        const control = this.loginForm.get('username');
        return control.touched && control.hasError('required');
    }

    showPasswordRequiredError(): boolean {
        const control = this.loginForm.get('password');
        return control.touched && control.hasError('required');
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value;
            this.authService
                .login(String(values.username), String(values.password), true)
                .pipe(mergeMap((loginResponse) => this.shoppingCartService.convertFromAnonymousToCustomerCart(loginResponse.UserDetails.Id)))
                .subscribe(
                    () => {
                        TopOfPage();
                        if (this.returnUrl) {
                            void this.router.navigateByUrl(this.returnUrl);
                        } else {
                            void this.router.navigate(['/customer']);
                        }
                    },
                    (errorResponse) => {
                        if (errorResponse.status === 418) {
                            if (errorResponse.error === 'DomainLoginEmailSent') {
                                this.notificationsService.success('A login link has been emailed to you');
                            } else {
                                this.notificationsService.error(
                                    'This email and password combination is not found in our system. Reset your password using the “Forgot Password” link below.',
                                );
                            }
                        } else if (errorResponse.status === 403) {
                            this.notificationsService.error(
                                'Your account has been locked after too many failed login attempts.  Please try again later',
                            );
                        } else {
                            const errorValues = Object.values(errorResponse.error as { [key: string]: string[] });
                            this.notificationsService.error(Object.values(errorValues).join());
                        }
                    },
                );
        } else {
            markAllFormFieldsAsTouched(this.loginForm);
        }
    }

    registerClicked(): void {
        const navExtras = this.returnUrl ? { queryParams: { returnUrl: this.returnUrl } } : {};
        void this.router.navigate(['/create-account'], navExtras);
    }

    togglePasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
