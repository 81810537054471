import { Injectable } from '@angular/core';
import { ICustomerBilling } from '@model/interfaces/customer-billing';
import { ICustomerShipping } from '@model/interfaces/customer-shipping';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AnnounceBillingShippingService {
    newBillingFromShippingEvent = new Subject<ICustomerBilling>();
    newBillingFromShippingObservable = this.newBillingFromShippingEvent.asObservable();

    newShippingFromBillingEvent = new Subject<ICustomerShipping>();
    newShippingFromBillingObservable = this.newShippingFromBillingEvent.asObservable();
    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    announceNewBillingFromShipping(newBilling: ICustomerBilling): void {
        this.newBillingFromShippingEvent.next(newBilling);
    }

    announceNewShippingFromBilling(newShipping: ICustomerShipping): void {
        this.newShippingFromBillingEvent.next(newShipping);
    }
}
