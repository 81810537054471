import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CountriesEnums } from '@common/constants/Enums';
import { AnnounceBillingShippingService } from '@common/services/announce-billing-shipping.service';
import { CommonService } from '@common/services/common.service';
import { CustomerBillingAddressesService } from '@customers/services/customer-billing-addresses.service';
import { CustomerShippingAddressesService } from '@customers/services/customer-shipping-addresses.service';
import { ICountry } from '@model/interfaces/country';
import { ICustomerBilling } from '@model/interfaces/customer-billing';
import { ICustomerShipping } from '@model/interfaces/customer-shipping';
import { CustomerShippingDynamicControlsPartial } from '@model/partials/customer-shipping-partial.form-controls';
import { IMetaItem } from '@mt-ng2/base-service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { IState } from '../../../model/interfaces/state';
import { CombinePhoneStrings, GetState } from '../../functions/common-functions.library';

@Component({
    selector: 'app-shipping-detail',
    templateUrl: './shipping-detail.component.html',
})
export class ShippingDetail implements OnInit, AfterViewChecked {
    @Input() customerShipping: ICustomerShipping;
    @Output('onComplete') completeEmitter = new EventEmitter();
    @Output('onCancel') cancelEmitter = new EventEmitter();
    @Output('onNewPrimary') onNewPrimaryEmitter = new EventEmitter<ICustomerShipping>();

    abstractCustomerShippingControls: any;
    countries: ICountry[];
    states: IState[];
    statesMetaItems: IMetaItem[];
    customerShippingForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    saveAsBillingAddress = false;
    countryId: number;
    zipInvalid: string;
    homePhoneInvalid: string;
    workPhoneInvalid: string;
    cellPhoneInvalid: string;
    wasPrimaryOnLoad: boolean;

    get countryIsUS(): boolean {
        return this.countryId === CountriesEnums.US;
    }
    get countryIsCanada(): boolean {
        return this.countryId === CountriesEnums.Canada;
    }
    get phoneShowingWithExtension(): boolean {
        return this.countryIsUS || this.countryIsCanada;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private commonService: CommonService,
        private notificationsService: NotificationsService,
        private customerShippingAddressesService: CustomerShippingAddressesService,
        private customerBillingAddressesService: CustomerBillingAddressesService,
        private announceBillingShippingService: AnnounceBillingShippingService,
    ) {}

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    ngOnInit(): void {
        this.wasPrimaryOnLoad = this.customerShipping.IsPrimary;
        this.countryId = this.customerShipping.CountryId;
        forkJoin([this.commonService.getCountries(), this.commonService.getStates()]).subscribe(([countries, states]) => {
            this.countries = countries;
            this.states = states;
            this.statesMetaItems = states.map((state) => ({ Id: state.StateId, Name: state.StateDesc }));
            this._createForm();
            const countryControl = this.customerShippingForm.get('CustomerShipping.CountryId');
            if (!countryControl.value) {
                countryControl.setValue(CountriesEnums.US);
            }
        });
    }

    newCountry(countryId: number): void {
        this.countryId = countryId;
        this._clearCountryFields();
    }

    private _clearCountryFields(): void {
        setTimeout(() => {
            this.customerShippingForm.get('CustomerShipping.ShippingAddress1')?.setValue('');
            this.customerShippingForm.get('CustomerShipping.ShippingAddress2')?.setValue('');
            this.customerShippingForm.get('CustomerShipping.ShippingCity')?.setValue('');
            this.customerShippingForm.get('CustomerShipping.ShippingStateId')?.setValue('');
            this.customerShippingForm.get('CustomerShipping.USZipCode')?.setValue('');
            this.customerShippingForm.get('CustomerShipping.ShippingAddress3')?.setValue('');
            this.customerShippingForm.get('CustomerShipping.ShippingAddress4')?.setValue('');
            this.customerShippingForm.get('CustomerShipping.ShippingAddress5')?.setValue('');
        });
    }

    formSubmitted(): void {
        if (this.customerShippingForm.valid && !this.zipInvalid) {
            Object.assign(this.customerShipping, this.customerShippingForm.value.CustomerShipping);
            this.customerShipping.ShippingAddress3 = this.customerShippingForm.value.CustomerShipping.ShippingAddress3 || '';
            if (this.countryIsUS || this.countryIsCanada) {
                this.customerShipping.WorkPhone = CombinePhoneStrings(
                    String(this.customerShippingForm.value.CustomerShipping.WorkPhone),
                    String(this.customerShippingForm.value.CustomerShipping.WorkExt),
                );
                this.customerShipping.HomePhone = CombinePhoneStrings(
                    String(this.customerShippingForm.value.CustomerShipping.HomePhone),
                    String(this.customerShippingForm.value.CustomerShipping.HomeExt),
                );
                this.customerShipping.CellPhone = this.customerShippingForm.value.CustomerShipping.CellPhone;
                if (this.countryIsUS) {
                    this.customerShipping.ShippingAddress4 = GetState(
                        this.states,
                        Number(this.customerShippingForm.value.CustomerShipping.ShippingStateId),
                    ).StateCode;
                    this.customerShipping.ShippingAddress5 = this.customerShippingForm.value.CustomerShipping.USZipCode;
                }
            } else {
                this.customerShipping.WorkPhone = this.customerShippingForm.value.CustomerShipping.WorkPhonePlaintext;
                this.customerShipping.HomePhone = this.customerShippingForm.value.CustomerShipping.HomePhonePlaintext;
                this.customerShipping.CellPhone = this.customerShippingForm.value.CustomerShipping.CellPhonePlaintext;
            }
            this.customerShippingAddressesService.saveAddress(this.customerShipping).subscribe((id) => {
                this.customerShipping.Id = id;
                this.customerShipping.Country = this.countries.find((c) => c.CountryId === this.customerShipping.CountryId);
                if (this.saveAsBillingAddress) {
                    this._copyToBillingAddress();
                }
                if (this.customerShipping.IsPrimary) {
                    this.onNewPrimaryEmitter.emit(this.customerShipping);
                }

                if (!id) {
                    this.notificationsService.warning('This address has already been added');
                    this.cancel();
                } else {
                    this._success();
                }
            });
        } else {
            markAllFormFieldsAsTouched(this.customerShippingForm);
            if (this.zipInvalid) {
                this.notificationsService.error(this.zipInvalid);
            } else if (this.homePhoneInvalid) {
                this.notificationsService.error(this.homePhoneInvalid);
            } else if (this.workPhoneInvalid) {
                this.notificationsService.error(this.workPhoneInvalid);
            } else if (this.cellPhoneInvalid) {
                this.notificationsService.error(this.cellPhoneInvalid);
            } else if (this._formPhoneIssue) {
                this._error('Please check phone number');
            } else {
                this._error();
            }
        }
    }

    primaryClicked(isChecked: boolean): void {
        setTimeout(() => {
            if (this.wasPrimaryOnLoad && !isChecked) {
                this.notificationsService.warning('You must have one primary address');
                this.customerShipping.IsPrimary = true;
            }
        });
    }

    private get _formPhoneIssue(): boolean {
        return (
            (this.customerShippingForm.get('CustomerShipping.HomePhonePlaintext') &&
                !this.customerShippingForm.get('CustomerShipping.HomePhonePlaintext').valid) ||
            (this.customerShippingForm.get('CustomerShipping.HomeExt') && !this.customerShippingForm.get('CustomerShipping.HomeExt').valid) ||
            (this.customerShippingForm.get('CustomerShipping.WorkPhonePlaintext') &&
                !this.customerShippingForm.get('CustomerShipping.WorkPhonePlaintext').valid) ||
            (this.customerShippingForm.get('CustomerShipping.WorkExt') && !this.customerShippingForm.get('CustomerShipping.WorkExt').valid) ||
            (this.customerShippingForm.get('CustomerShipping.CellPhonePlaintext') &&
                !this.customerShippingForm.get('CustomerShipping.CellPhonePlaintext').valid)
        );
    }

    isUSZipCodeValid(): boolean {
        if (!this.countryIsUS) {
            return true;
        } else {
            const result = this.USZipCodeInvalid();
            return !result;
        }
    }

    private _copyToBillingAddress(): void {
        const asBillingAddress: ICustomerBilling = { ...this.customerShipping };
        asBillingAddress.Id = 0;
        asBillingAddress.BillingAddress1 = this.customerShipping.ShippingAddress1;
        asBillingAddress.BillingAddress2 = this.customerShipping.ShippingAddress2;
        asBillingAddress.BillingAddress3 = this.customerShipping.ShippingAddress3;
        asBillingAddress.BillingAddress4 = this.customerShipping.ShippingAddress4;
        asBillingAddress.BillingAddress5 = this.customerShipping.ShippingAddress5;
        asBillingAddress.BillingCity = this.customerShipping.ShippingCity;
        this.customerBillingAddressesService.saveAddress(asBillingAddress).subscribe((data) => {
            asBillingAddress.Id = data;
            this.announceBillingShippingService.announceNewBillingFromShipping(asBillingAddress);
        });
    }

    private _createForm(): void {
        this._getControls();
        this.customerShippingForm = this.fb.group({
            CustomerShipping: this.fb.group({}),
        });
        this.cdr.detectChanges();
    }

    private _getControls(): void {
        this.abstractCustomerShippingControls = new CustomerShippingDynamicControlsPartial(this.customerShipping, {
            countryMetaItems: this.countries.map((c) => ({ Id: c.CountryId, Name: c.CountryName })),
            formGroup: 'CustomerShipping',
            states: this.states,
        }).Form;
    }

    private _error(message?: string): void {
        this.notificationsService.error(message || 'Please enter all required fields');
    }

    private _success(): void {
        this.notificationsService.success('Shipping information saved successfully');
        this.completeEmitter.emit(this.customerShipping);
        this._topOfComponent();
    }

    cancel(): void {
        this.cancelEmitter.emit();
        this._topOfComponent();
    }

    private _topOfComponent(): void {
        window.location.hash = '';
        window.location.hash = 'shipping-cards-panel';
    }

    USZipCodeInvalid(): boolean {
        if (this.customerShippingForm.get('CustomerShipping.USZipCode')) {
            const currZipVal = this.customerShippingForm.get('CustomerShipping.USZipCode').value;
            if (currZipVal) {
                return currZipVal.length < 5 || (currZipVal.length > 5 && currZipVal.length < 10) || /[a-zA-Z]/.test(String(currZipVal));
            }
            return false;
        } else {
            return false;
        }
    }
}
