import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStateCours } from '@model/interfaces/state-cours';
import { Observable } from 'rxjs';
import { ICourseCatalog } from '../../model/DTO/course-list';
import { IMetaItemExtended } from '../../model/custom/MetaItemExtended';
import { ICourse } from '../../model/interfaces/course';

@Injectable({
    providedIn: 'root',
})
export class CourseService {
    constructor(public http: HttpClient) {}

    getCourseByItemId(itemId: number): Observable<ICourse> {
        return this.http.get<ICourse>(`/courses/${itemId}`);
    }

    getCourses(sortBy: number, skip: number, take: number): Observable<HttpResponse<ICourseCatalog[]>> {
        return this.http.get<ICourseCatalog[]>('/courses', {
            observe: 'response',
            params: { sortBy: `${sortBy} `, skip: `${skip} `, take: `${take} ` },
        });
    }

    getFormatItemBundleMetaItems(): Observable<IMetaItemExtended[]> {
        return this.http.get<IMetaItemExtended[]>('/courses/get-all');
    }

    confirmCourseAvailableInWorkState(userId: number, courseIds: number[]): Observable<IStateCours[]> {
        return this.http.post<IStateCours[]>(`/courses/availableInWorkState/${userId}`, courseIds);
    }

    getStatus(): Observable<any> {
        return this.http.get<any>(`/courses/ping-page`);
    }

}
