import { UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { CustomerDynamicControls, ICustomerDynamicControlsParameters } from '../form-controls/customer.form-controls';
import { ICustomer } from '../interfaces/customer';

export interface ICustomerDynamicControlsParametersPartial extends ICustomerDynamicControlsParameters {
    facilityTypes?: any[];
    workCountries?: any[];
    workStates?: any[];
    hearAboutList?: any[];
    isAdmin?: boolean;
}

export function passwordsMatch(group: UntypedFormGroup): any {
    const passwordControl = group.get('Password');
    const confirmPasswordControl = group.get('ConfirmPassword');

    if (!(passwordControl && confirmPasswordControl)) {
        return { passwordsDoNotMatch: true };
    }

    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;
    return password === confirmPassword ? null : { passwordsDoNotMatch: true };
}

export function emailAddressessMatch(group: UntypedFormGroup): any {
    const emailAddressControl = group.get('EmailAddress');
    const confirmEmailAddressControl = group.get('ConfirmEmailAddress');

    if (!(emailAddressControl && confirmEmailAddressControl)) {
        return { emailAddressessDoNotMatch: true };
    }

    const emailAddress = emailAddressControl.value;
    const confirmEmailAddress = confirmEmailAddressControl.value;
    return emailAddress === confirmEmailAddress ? null : { emailAddressessDoNotMatch: true };
}

export class CustomerDynamicControlsPartial extends CustomerDynamicControls {
    constructor(customer?: ICustomer, additionalParameters?: ICustomerDynamicControlsParametersPartial) {
        super(customer, additionalParameters);

        // (<DynamicField>this.Form.CompanyName).setRequired(true);
        (<DynamicField>this.Form.FacilityId).setRequired(true);
        (<DynamicField>this.Form.RegistryId).labelHtml = '<label>Registry ID</label>';
        (<DynamicField>this.Form.FirstName).setRequired(true);
        (<DynamicField>this.Form.LastName).setRequired(true);
        (<DynamicField>this.Form.MiddleName).labelHtml =
            '<label>Middle Name <div class="small">If you do not have a middle name or initial, leave this blank.</div></label>';
        (<DynamicField>this.Form.WorkCountryId).setRequired(true);
        (<DynamicField>this.Form.WorkStateId).setRequired(true);
        (<DynamicField>this.Form.EmailAddress).setRequired(true);
        (<DynamicField>this.Form.EmailAddress).validation = [
            Validators.required,
            Validators.maxLength(50),
            Validators.email,
            Validators.pattern(/^.+@.+\..+$/),
        ];
        (<DynamicField>this.Form.EmailAddress).validators = { required: true, showRequired: true, maxlength: 50, email: true };
        if (additionalParameters.isAdmin) {
            (<DynamicField>this.Form.EmailAddress).setRequired(false);
            (<DynamicField>this.Form.FacilityId).setRequired(false);
            (<DynamicField>this.Form.EmailAddress).validation = [Validators.maxLength(50), Validators.email, Validators.pattern(/^.+@.+\..+$/)];
            (<DynamicField>this.Form.EmailAddress).validators = { maxlength: 50, email: true };
        }

        (<DynamicField>this.Form.CompanyName).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.EmailAddress).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.FacilityId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.FacilityId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.FacilityId).options = additionalParameters.facilityTypes;
        // (<DynamicField>this.Form.SignUpNewsLetter).type.fieldType = DynamicFieldTypes.Checkbox;

        (<DynamicField>this.Form.HearAboutUsId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.HearAboutUsId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.HearAboutUsId).options = additionalParameters.hearAboutList;

        (<DynamicField>this.Form.CompanyName).labelHtml = '<label>Facility Name</label>';
        (<DynamicField>this.Form.FacilityId).labelHtml = '<label>Facility Type</label>';
        (<DynamicField>this.Form.HearAboutUsId).labelHtml = '<label>How did you hear about us?</label>';
        (<DynamicField>this.Form.HearAboutOther).labelHtml = '<label>Other</label>';
        (<DynamicField>this.Form.ReceiveCdaOutreachEmails).labelHtml = '<label>Receive CDA Outreach Emails</label>';
        (<DynamicField>this.Form.SendEmail).labelHtml =
            '<label class="mb-0">Include on catalog mailing list<p class="small">(Uncheck to remove from mailing list)</p></label>';
        // (<DynamicField>this.Form.SignUpNewsLetter).labelHtml = '<label>Sign up for newsletter</label>'

        (<DynamicField>this.Form.OptToEmailMarketing).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.OptToEmailMarketing).type.inputType = SelectInputTypes.OldStyleRadioButtonList;
        (<DynamicField>(
            this.Form.OptToEmailMarketing
        )).labelHtml = `<label>Click “yes" to receive emailed newsletters and other important updates and insights!</label>`;
        (<DynamicField>this.Form.OptToEmailMarketing).options = [
            { Name: 'Yes', Id: true },
            { Name: 'No', Id: false },
        ];

        this.Form.Password = new DynamicField({
            formGroup: this.formGroup,
            label: 'Password',
            name: 'Password',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Password,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: '',
        });
        this.Form.ConfirmPassword = new DynamicField({
            formGroup: this.formGroup,
            label: 'Confirm Password',
            name: 'ConfirmPassword',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Password,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: '',
        });
        this.Form.ConfirmEmailAddress = new DynamicField({
            formGroup: this.formGroup,
            label: 'Confirm Email Address',
            name: 'ConfirmEmailAddress',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
                scale: null,
            }),
            validation: [Validators.maxLength(200), Validators.required],
            validators: { maxlength: 200, required: true },
            value: '',
        });
    }
}
