<div [class.container]="showSubtotal" [class.container]="isOnCartPage">
    <br *ngIf="isOnCartPage" />
    <ng-component *ngTemplateOutlet="adminSearch"></ng-component>
    <ng-container *ngIf="cartInventory && cartInventory.length > 0">
        <div [class.miles-card]="!isOnCartPage">
            <h1 *ngIf="isOnCartPage">My Cart</h1>
            <h4 *ngIf="!isOnCartPage"><b>My Cart</b></h4>
            <br />
            <card-entity-list
                [entities]="cartInventory"
                [itemTemplate]="cartTemplate"
                [includeDelete]="true"
                (delete)="onItemDeleted($event)"
                [numOfColumns]="1"
                [cursorType]="'default'"
            ></card-entity-list>
        </div>
        <ng-component *ngTemplateOutlet="subtotalInfo"></ng-component>
    </ng-container>
    <h3 *ngIf="!cartInventory || cartInventory.length === 0" class="pb-3">
        <h1 style="display: none">My Cart</h1>
        <div>There's nothing in your cart!</div>
        <div>Let's fix that: <a href="/publicpages/care_courses">Go to Courses</a></div>
    </h3>
</div>

<ng-template #subtotalInfo>
    <div class="container">
        <div class="row">
            <div *ngIf="showSubtotal" class="col-lg-6"></div>
            <div *ngIf="showSubtotal" class="miles-card offset-lg-6 col-lg-6">
                <div style="overflow: hidden">
                    <h4 class="m-0 p-0 float-right" style="border: none">
                        Subtotal: <b style="color: red">{{ shoppingCartService.$cartSubtotal | async | currency }}</b>
                    </h4>
                </div>
                <br />
                <div class="checkout-buttons" style="overflow: hidden">
                    <button
                        id="btnContinueCheckout"
                        (click)="goToCheckout()"
                        class="btn btn-primary float-right m-1"
                        style="color: black !important; background-color: #fac555 !important; border-color: #fac555 !important"
                    >
                        Continue to Checkout
                    </button>
                    <button (click)="continueShopping()" class="btn btn-primary float-right m-1">Continue Shopping</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #adminSearch>
    <div *ngIf="isAdmin && showSubtotal" class="admin-searchbar">
        <div class="admin-searchbar-button">
            <ng-select bindLabel="Name" bindText="Id" placeholder="Type here to search" [items]="metaItemList" [(ngModel)]="selectedMetaItem">
            </ng-select>
        </div>
        <div>
            <input class="form-control" type="number" min="1" [value]="adminCartAddQty" (change)="changeAdminCartAddQty($event)" />
        </div>
        <div>
            <button id="btnAddtoCart" (click)="adminAddToCart()" class="btn btn-primary" style="white-space: nowrap">Add to Cart</button>
        </div>
    </div>
    <br />
</ng-template>

<ng-template #cartTemplate let-cartItem>
    <div class="course-card with-buttons" (click)="goToItem(cartItem)" [style.cursor]="isCourseOrBundle(cartItem) ? 'pointer' : ''">
        <img
            *ngIf="cartItem.CourseFormat?.Course?.CourseImageName"
            src="{{ environment.adminSiteUploadImagePath + '' + cartItem.CourseFormat.Course.CourseImageName }}"
            onerror="this.onerror=null;this.src='../../assets/noimage.png'"
            class="course-img"
            alt="{{ imageAltAndTitleTextEnum.ManagedContentImageFor }} {{ cartItem.CourseFormat.Course.CourseName }}"
            title="{{ imageAltAndTitleTextEnum.ManagedContentImageFor }} {{ cartItem.CourseFormat.Course.CourseName }}"
        />
        <img
            *ngIf="cartItem.Item?.Bundle?.BundleImageName"
            src="{{ environment.adminSiteUploadImagePath + '' + cartItem.Item.Bundle.BundleImageName }}"
            onerror="this.onerror=null;this.src='../../assets/noimage.png'"
            class="course-img"
            alt="{{ imageAltAndTitleTextEnum.ManagedContentImageFor }} {{ cartItem.Item.Bundle.BundleName }}"
            title="{{ imageAltAndTitleTextEnum.ManagedContentImageFor }} {{ cartItem.Item.Bundle.BundleName }}"
        />
        <img
            class="course-img"
            *ngIf="!cartItem.CourseFormat?.Course?.CourseImageName && !cartItem.Item?.Bundle?.BundleImageName"
            src="../../assets/noimage.png"
            alt="{{ imageAltAndTitleTextEnum.DefaultImageAlt }}"
            title="{{ imageAltAndTitleTextEnum.DefaultImageTitle }}"
        />
        <span>
            <ng-container *ngIf="cartItem.CourseFormatId">
                <div class="course-title">{{ cartItem.CourseFormat.Course.CourseName }}</div>
                <div>{{ cartItem.CourseFormat.CourseMedia?.ListItemName }} - {{cartItem.CourseFormat.CourseLanguage?.ListItemName}}</div>
                <div>Item Number: {{ cartItem.CourseFormat.CourseSku }}</div>
                <div class="colon-aligned-data">
                    <div>Price:</div>
                    {{ cartItem.CourseFormat.Price | currency }}
                    <div>Total:</div>
                    {{ cartItem.CourseFormat.Price * cartItem.Qty | currency }}
                </div>
            </ng-container>
            <ng-container *ngIf="!cartItem.CourseFormatId">
                <div class="course-title">{{ cartItem.Item.ItemName }}</div>
                <app-shadowdom
                    [innerHTML]="cartItem.Item.Bundle ? cartItem.Item.Bundle.ShortDescription : cartItem.Item.ShortDescription"
                ></app-shadowdom>
                <div>Item Number: {{ cartItem.Item.Bundle ? cartItem.Item.Bundle.BundleNumber : cartItem.Item.ItemNumber }}</div>
                <div class="colon-aligned-data">
                    <div>Price:</div>
                    {{ displayItemPrice(cartItem) | currency }}
                    <div>Total:</div>
                    {{ displayItemPrice(cartItem) * cartItem.Qty | currency }}
                </div>
            </ng-container>
        </span>
        <span>
            <ng-component *ngTemplateOutlet="quantity; context: { $implicit: cartItem }"></ng-component>
        </span>
    </div>
</ng-template>

<ng-template #quantity let-cartItem>
    <div *ngIf="showQuantityField" class="qty-input" (click)="$event.stopPropagation()">
        Qty
        <input class="form-control p-2" type="number" min="1" [ngModel]="cartItem.Qty" (ngModelChange)="updateCount(cartItem, $event)" />
    </div>
</ng-template>
