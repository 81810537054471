import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IColumnSortedEvent, IItemDeletedEvent, IItemSelectedEvent } from '@mt-ng2/entity-list-module';
import { EmptyListConfig } from './empty.entity-list-config';
import Swal from 'sweetalert2';
import { IEntity } from '@mt-ng2/entity-list-module';
// tslint:disable: object-literal-sort-keys

@Component({
    selector: 'card-entity-list',
    styleUrls: ['./card-entity-list.component.less'],
    templateUrl: './card-entity-list.component.html',
})
export class CardEntityListComponent<T extends IEntity> implements OnInit {
    @Input() entities: T[];
    @Input() itemTemplate: TemplateRef<any>;
    @Input() selectedEntity: T;

    // Inputs relating to include flags
    @Input() includeEdit = false;
    @Input() includeAdd = false;
    @Input() includeToggle = false;
    @Input() includeDelete = false;

    // Inputs relating to 'add' functionality
    /** An empty object T -- unused if includeAdd is false */
    private _emptyEntity: T = null;
    @Input()
    get emptyEntity(): T {
        return this._emptyEntity;
    }
    set emptyEntity(val: T) {
        this._emptyEntity = JSON.parse(JSON.stringify(val));
    }
    /** The property of T that determines whether t is empty -- unused if includeAdd is false */
    @Input() identifierPropertyName = 'Id';
    @Input() textForAddButton: string;

    // Inputs relating to inline editing
    @Input() editingItemInline: T;
    @Input() editingNewItemInline = false;

    // Outputs relating to include flags
    @Output('edit') editEmitter = new EventEmitter<T>();
    @Output('add') addEmitter = new EventEmitter<T>();
    @Output('toggle') toggleEmitter = new EventEmitter<[T, MatSlideToggleChange]>();
    @Output('select') selectEmitter = new EventEmitter<T>();
    @Output('delete') deleteEmitter = new EventEmitter<IItemDeletedEvent>();
    @Output('entityClick') entityClickedEmitter = new EventEmitter<T>();

    // Inputs relating to pagination
    @Input() total: number;
    @Input() currentPage: number;
    @Input() itemsPerPage: number;

    // Outputs relating to pagination
    @Output() currentPageChange = new EventEmitter<number>();
    @Output() itemsPerPageChange = new EventEmitter<number>();
    @Output() onPageChanged = new EventEmitter<string>();
    @Output() onItemSelected = new EventEmitter<IItemSelectedEvent>();
    @Output() onColumnSorted = new EventEmitter<IColumnSortedEvent>();

    // Misc flags
    @Input() addButtonAtTop = false;
    @Input() numOfColumns = 2;
    @Input() selectButton = false;
    @Input() milesCard = true;
    @Input() pinkBackgroundOnProperty: string;
    @Input() cursorType = 'pointer';
    @Input() selectButtonText = 'pointer';

    // Show/hide all functionality
    @Input() labelTextForShowHide: string;
    includeShowHideAllButton = false;
    allEntitiesShowing = false;

    entityListConfig = new EmptyListConfig();

    get hasEmptyItem(): boolean {
        const emptyItemLocation = this.addButtonAtTop ? 0 : this.entities.length - 1;
        return this.entities[emptyItemLocation] ? !this.entities[emptyItemLocation][this.identifierPropertyName] : false;
    }

    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    ngOnInit(): void {
        this._addEmptyItemIfNeeded();
    }

    protected _addEmptyItemIfNeeded(): void {
        if (this.includeAdd) {
            // Add empty item to beginning or end
            if (!this.hasEmptyItem) {
                this.addButtonAtTop ? this.entities.unshift(this.emptyEntity) : this.entities.push(this.emptyEntity);
            }
        }
    }

    showHideClicked(): void {
        this.allEntitiesShowing = !this.allEntitiesShowing;
    }

    entityClicked(entity: T): void {
        this.selectedEntity = entity;
        this.entityClickedEmitter.emit(entity);
    }

    editClicked(entity: T, event?: any): void {
        event?.stopPropagation();
        this.editEmitter.emit(entity);
    }

    addClicked(entity: T, event?: any): void {
        event?.stopPropagation();
        this.editEmitter.emit(entity);
    }

    selectClicked(entity: T, event?: any): void {
        event?.stopPropagation();
        this.selectEmitter.emit(entity);
    }

    toggleClicked(entity: T, selectedToggle: MatSlideToggleChange): void {
        this.toggleEmitter.emit([entity, selectedToggle]);
    }

    // ** The entity updated from T to IEntity
    deleteClicked(entity: T, event?: Event): void {
        event?.stopPropagation();
        void Swal.fire({
            icon: 'warning',
            title: 'Are you sure you want to delete this?',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#5474af',
            reverseButtons: true,
            showCancelButton: true,
            showCloseButton: true,
            hideClass: { popup: '' },
            showClass: { popup: '' },
        }).then((result) => {
            if (result.isConfirmed) {
                const itemDeletedEvent: IItemDeletedEvent = {
                    $event: event,
                    entity: entity,
                };
                this.deleteEmitter.emit(itemDeletedEvent);
            }
        });
    }

    showEntityAsContent(entity: T): boolean {
        return entity && (entity[this.identifierPropertyName] || !this.includeAdd);
    }

    showEntityAsAdd(entity: T): boolean {
        return this.includeAdd && !entity[this.identifierPropertyName];
    }

    showSelectButton(entity: T): boolean {
        return this.allEntitiesShowing && this.selectButton && entity[this.identifierPropertyName];
    }

    showEditButton(entity: T): boolean {
        if (!this.editingItemInline) {
            return this.includeEdit;
        } else {
            // If editing item inline, show edit icon only if entity is NOT the item being edited
            // (this hides the edit icon for an item being edited inline)
            return this.includeEdit && this.editingItemInline[this.identifierPropertyName] !== entity[this.identifierPropertyName];
        }
    }

    showDeleteButton(entity: T): boolean {
        if (!this.editingItemInline) {
            return this.includeDelete;
        } else {
            // If editing item inline, show trashcan only if entity is NOT the item being edited
            // (this hides the trashcan for an item being edited inline)
            return this.includeDelete && this.editingItemInline[this.identifierPropertyName] !== entity[this.identifierPropertyName];
        }
    }
}
