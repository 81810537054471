/* eslint-disable no-console */
import { Component, OnInit } from '@angular/core';
import { CourseService } from '../courses/services/course.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-ping-page',
    templateUrl: './ping-page.component.html',
})
export class PingPageComponent implements OnInit {

    constructor(
        private courseService:CourseService,
        private notificationsService: NotificationsService
    ) {
        // This component doesn't have any properties or dependencies to initialize
    }

    ngOnInit(): void {
        // This component doesn't have any properties or dependencies to initialize
    }
    getStatus(){
        this.notificationsService.success(
            'Getting response from the API',
        );
        this.courseService.getStatus().subscribe((a)=>{
            this.notificationsService.success(
                'Response fetched',
            );
        });
    }
}
