<mt-entity-list
    class="custom-entity-list"
    [entities]="entities"
    [total]="total"
    [currentPage]="currentPage"
    (currentPageChange)="currentPageChange.emit($event)"
    [itemsPerPage]="itemsPerPage"
    (itemsPerPageChange)="itemsPerPageChange.emit($event)"
    (pageChanged)="onPageChanged.emit($event)"
    (itemSelectedEvent)="onItemSelected.emit($event)"
    (columnSortedEvent)="onColumnSorted.emit($event)"
    [entityListConfig]="entityListConfig"
    [class.one-column]="numOfColumns === 1"
    [class.two-column]="numOfColumns === 2"
    [class.three-column]="numOfColumns === 3"
>
    <ng-template mtEntityListHeader><!-- Don't show header --></ng-template>
    <ng-template mtEntityListItem let-entity>
        <div
            *ngIf="showEntityAsContent(entity)"
            (click)="entityClicked(entity)"
            class="position-relative"
            [class.miles-card]="milesCard"
            [class.pink-bg]="entity[pinkBackgroundOnProperty]"
            [style.cursor]="!editingItemInline ? cursorType : 'default'"
        >
            <ng-component *ngTemplateOutlet="containerTemplate; context: { $implicit: entity }"></ng-component>
        </div>
        <div *ngIf="selectButton && allEntitiesShowing && entity === selectedEntity"><hr /></div>
        <!-- If this entity has an add button -->
        <ng-component *ngTemplateOutlet="addTemplate; context: { $implicit: entity }"></ng-component>
    </ng-template>
</mt-entity-list>
<div class="clearfix">
    <button *ngIf="includeShowHideAllButton" class="btn btn-primary float-right" (click)="showHideClicked()">
        <ng-container *ngIf="allEntitiesShowing">Collapse&nbsp;&nbsp;<i class="fa fa-arrow-up"></i></ng-container>
        <ng-container *ngIf="!allEntitiesShowing">{{ labelTextForShowHide }}&nbsp;&nbsp;<i class="fa fa-arrow-down"></i></ng-container>
    </button>
</div>

<ng-template #containerTemplate let-entity>
    <ng-component *ngTemplateOutlet="editTemplate; context: { $implicit: entity }"></ng-component>
    <ng-component *ngTemplateOutlet="toggleTemplate; context: { $implicit: entity }"></ng-component>
    <ng-component *ngTemplateOutlet="itemTemplate; context: { $implicit: entity }"></ng-component>
    <ng-component *ngTemplateOutlet="deleteTemplate; context: { $implicit: entity }"></ng-component>
    <ng-component *ngTemplateOutlet="selectButtonTemplate; context: { $implicit: entity }"></ng-component>
    <ng-container *ngIf="showSelectButton(entity)">
        <br />
        <br />
    </ng-container>
</ng-template>

<ng-template #addTemplate let-entity>
    <div *ngIf="showEntityAsAdd(entity)" class="position-relative" [class.miles-card]="milesCard">
        <!-- Render the empty template, hide it, and add "add" button -->
        <div [class.invisible]="!editingNewItemInline">
            <ng-component *ngTemplateOutlet="containerTemplate; context: { $implicit: entity }"></ng-component>
        </div>
        <div class="btn-add">
            <div *ngIf="textForAddButton" class="text-center mb-2">{{ textForAddButton }}</div>
            <button *ngIf="!editingNewItemInline" class="no-animate d-block mx-auto btn btn-primary btn-fab-lg" (click)="addClicked(entity, $event)">
                <i class="fa fa-plus fa-top"></i>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #editTemplate let-entity>
    <a
        *ngIf="showEditButton(entity)"
        class="float-right d-block mb-2"
        href="#"
        onclick="return false;"
        role="button"
        (click)="editClicked(entity, $event)"
    >
        <i class="fa fa-lg fa-edit"></i>
    </a>
</ng-template>

<ng-template #toggleTemplate let-entity>
    <mat-slide-toggle
        *ngIf="includeToggle"
        class="mat-primary float-right"
        (click)="$event.stopPropagation()"
        (change)="toggleClicked(entity, $event)"
        [checked]="entity.IsPrimary"
        [labelPosition]="'before'"
        style="position: relative; clear: right"
        >Primary</mat-slide-toggle
    >
</ng-template>

<ng-template #selectButtonTemplate let-entity>
    <ng-container *ngIf="showSelectButton(entity)">
        <button class="btn btn-primary" style="position: absolute; bottom: 20px; left: 20px" (click)="selectClicked(entity, $event)">
            {{ selectButtonText }}
        </button>
    </ng-container>
</ng-template>

<ng-template #deleteTemplate let-entity>
    <a
        *ngIf="showDeleteButton(entity)"
        href="#"
        onclick="return false;"
        role="button"
        style="position: absolute; bottom: 20px; right: 20px"
        (click)="deleteClicked(entity, $event)"
    >
        <i class="fa fa-trash fa-lg"></i>
    </a>
</ng-template>
