import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-not-found',
    styleUrls: ['./not-found.component.css'],
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    ngOnInit(): void {
        // This component doesn't have any properties or dependencies to initialize
    }
}
