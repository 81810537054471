import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize, switchMap } from 'rxjs/operators';
import { CustomerRegistrationService } from '../customer-registration/customer-registration.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: UntypedFormGroup;
    logoFull: string;
    doubleClickIsDisabled = false;
    userId: number;
    resetKey: string;

    protected authService: AuthService;
    protected router: Router;
    public route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    private customerRegistrationService: CustomerRegistrationService;
    isPasswordVisible = false;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.customerRegistrationService = injector.get(CustomerRegistrationService);
        this.logoFull = `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        this.createForm();
        const queryParams = this.route.snapshot.queryParams;
        this.resetKey = queryParams.resetKey;
        this.userId = +queryParams.userId;
    }

    createForm(): void {
        if (!this.resetPasswordForm) {
            this.resetPasswordForm = new UntypedFormGroup({});
        }
        this.resetPasswordForm.addControl('Password', new UntypedFormControl('', [Validators.required.bind(this)]));
        this.resetPasswordForm.addControl('ConfirmPassword', new UntypedFormControl('', [Validators.required.bind(this)]));
    }

    passwordHasError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return control.touched && control.hasError('required');
    }

    showConfirmPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('ConfirmPassword');
        return control.touched && control.hasError('required');
    }

    showPasswordMustMatchError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        const controlValue = control.value as string;
        const confirmControl = this.resetPasswordForm.get('ConfirmPassword');
        const confirmControlValue = confirmControl.value as string;
        return control.touched && confirmControl.touched && controlValue.length && confirmControlValue.length && controlValue !== confirmControlValue;
    }

    hasRegexError(): boolean {
        if (this.resetPasswordForm.controls.Password.errors?.pattern) {
            this.resetPasswordForm.controls.Password.setErrors({ pattern: true });
            return true;
        }
        return false;
    }

    onSubmit(): void {
        const form = this.resetPasswordForm;
        if (this.authService.matchPassword(form, null)) {
            if (form.valid) {
                this.customerRegistrationService
                    .checkValidPassword(String(form.value.Password))
                    .pipe(
                        switchMap(() =>
                            this.authService.resetPassword(
                                this.userId,
                                String(form.value.Password),
                                String(form.value.ConfirmPassword),
                                this.resetKey,
                            ),
                        ),
                        finalize(() => (this.doubleClickIsDisabled = false)),
                    )
                    .subscribe(
                        (answer) => {
                            this.success();
                            void this.router.navigate(['/home']);
                        },
                        (error) => {
                            if (error.status === 418) {
                                this.notificationsService.error(
                                    'Sorry, we were unable to process your request at this time. It is not your fault. Please try again later, or contact your system administrator.',
                                );
                            } else if (error.status === 400) {
                                // TODO: Can we just inject the error service?
                                if (error.error) {
                                    const errorMessage = error.error.ModelState?.Service
                                        ? String(error.error.ModelState.Service)
                                        : error.error.Message
                                        ? String(error.error.Message)
                                        : error.error.ModelState && error.error.ModelState[0]
                                        ? String(error.error.ModelState[0])
                                        : null;

                                    this.error(errorMessage);
                                } else {
                                    this.notificationsService.error(
                                        'Something went wrong. Please click the <a href="/forgot-password"><u>forgot password</u></a> link again',
                                        undefined,
                                        {
                                            enableHtml: true,
                                        },
                                    );
                                    // eslint-disable-next-line no-console
                                    console.error(`Oops something went wrong!
                                    - Probably the key has been used or expired.
                                    - Or you did something you weren't supposed to do.
                                    - Your best bet is to just generate a new email.`);
                                }
                            }
                        },
                    );
            } else {
                markAllFormFieldsAsTouched(form);
            }
        } else {
            this.error('Passwords do not match');
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Password updated successfully');
    }
}
