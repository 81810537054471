<form *ngIf="customerBillingForm" class="container" [formGroup]="customerBillingForm" (ngSubmit)="formSubmitted()">
    <br />
    <h4>Contact information</h4>
    <div class="row">
        <div class="col-md-6">
            <mt-dynamic-field [field]="abstractCustomerBillingControls.FirstName" [form]="customerBillingForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerBillingControls.MiddleName" [form]="customerBillingForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerBillingControls.LastName" [form]="customerBillingForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="abstractCustomerBillingControls.CompanyName" [form]="customerBillingForm"></mt-dynamic-field>
        </div>
        <div class="col-md-6">
            <ng-container *ngIf="phoneShowingWithExtension">
                <div class="phone-number">
                    <us-canada-phone-dynamic-field
                        [field]="abstractCustomerBillingControls.HomePhone"
                        [form]="customerBillingForm"
                        [fieldName]="'CustomerBilling.HomePhone'"
                        [required]="true"
                        (invalidReason)="homePhoneInvalid = $event"
                    ></us-canada-phone-dynamic-field>
                    <mt-dynamic-field class="extension" [field]="abstractCustomerBillingControls.HomeExt" [form]="customerBillingForm"></mt-dynamic-field>
                </div>
                <div class="phone-number">
                    <us-canada-phone-dynamic-field
                        [field]="abstractCustomerBillingControls.WorkPhone"
                        [form]="customerBillingForm"
                        [fieldName]="'CustomerBilling.WorkPhone'"
                        [required]="false"
                        (invalidReason)="workPhoneInvalid = $event"
                    ></us-canada-phone-dynamic-field>
                    <mt-dynamic-field class="extension" [field]="abstractCustomerBillingControls.WorkExt" [form]="customerBillingForm"></mt-dynamic-field>
                </div>
                <us-canada-phone-dynamic-field
                    [field]="abstractCustomerBillingControls.CellPhone"
                    [form]="customerBillingForm"
                    [fieldName]="'CustomerBilling.CellPhone'"
                    [required]="false"
                    (invalidReason)="cellPhoneInvalid = $event"
                ></us-canada-phone-dynamic-field>
            </ng-container>
            <ng-container *ngIf="!phoneShowingWithExtension">
                <plaintext-phone-dynamic-field [field]="abstractCustomerBillingControls.HomePhonePlaintext" [form]="customerBillingForm" [fieldName]="'CustomerBilling.HomePhonePlaintext'"></plaintext-phone-dynamic-field>
                <plaintext-phone-dynamic-field [field]="abstractCustomerBillingControls.WorkPhonePlaintext" [form]="customerBillingForm" [fieldName]="'CustomerBilling.WorkPhonePlaintext'"></plaintext-phone-dynamic-field>
                <plaintext-phone-dynamic-field [field]="abstractCustomerBillingControls.CellPhonePlaintext" [form]="customerBillingForm" [fieldName]="'CustomerBilling.CellPhonePlaintext'"></plaintext-phone-dynamic-field>
            </ng-container>
        </div>
    </div>
    <br />

    <h4>Billing information</h4>
    <mt-dynamic-field
        [field]="abstractCustomerBillingControls.CountryId"
        [form]="customerBillingForm"
        (valueChanges)="newCountry($event)"
    ></mt-dynamic-field>
    <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress1" [form]="customerBillingForm"></mt-dynamic-field>
    <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress2" [form]="customerBillingForm"></mt-dynamic-field>
    <ng-container *ngIf="billingCountryIsUS">
        <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingCity" [form]="customerBillingForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingStateId" [form]="customerBillingForm"></mt-dynamic-field>

        <zip-code-dynamic-field
            [field]="abstractCustomerBillingControls.USZipCode"
            [form]="customerBillingForm"
            [fieldName]="'CustomerBilling.USZipCode'"
            (invalidReason)="zipInvalid = $event"
        ></zip-code-dynamic-field>
    </ng-container>
    <ng-container *ngIf="!billingCountryIsUS">
        <ng-container *ngIf="!billingCountryIsCanada">
            <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress3" [form]="customerBillingForm"></mt-dynamic-field>
        </ng-container>
        <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingCity" [form]="customerBillingForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress4" [form]="customerBillingForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="abstractCustomerBillingControls.BillingAddress5" [form]="customerBillingForm"></mt-dynamic-field>
    </ng-container>

    <input id="save-as-shipping-address" type="checkbox" [(ngModel)]="saveAsShippingAddress" [ngModelOptions]="{ standalone: true }">
    <label for="save-as-shipping-address">Save as new shipping address</label>
    <br>
    <input id="save-as-primary" type="checkbox" [(ngModel)]="customerBilling.IsPrimary" (ngModelChange)="primaryClicked($event)" [ngModelOptions]="{ standalone: true }">
    <label for="save-as-primary">Save as my primary billing address</label>

    <div class="float-multiple-right">
        <br />
        <button type="button" Class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button type="submit" mtDisableClickDuringHttpCalls style="margin-left: 10px;" Class="btn btn-primary btn-success">Save</button>
    </div>
</form>
