import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { HttpErrorResponse } from '@angular/common/http';
import { IAuthUser } from '@model/interfaces/auth-user';
import { DynamicField, DynamicFieldTypes, DynamicLabel, IDynamicField, IDynamicLabel } from '@mt-ng2/dynamic-form';
import { AuthEntityService } from '../../auth-entity.service';
import { AuthUserDynamicConfig } from '../auth-user.dynamic-config';

@Component({
    selector: 'app-auth-user-password',
    templateUrl: './auth-user-password.component.html',
})
export class AuthUserPasswordComponent implements OnInit {
    @Input('AuthUser') authUser: IAuthUser;
    @Output('complete') onCompleteEmitter = new EventEmitter();

    config: any = {};
    doubleClickIsDisabled = false;
    private _formFactory: AuthUserDynamicConfig<IAuthUser>;
    private _configControls: string[] = ['CurrentPassword', 'Password', 'ConfirmPassword'];
    viewOnly: any;
    formObject: DynamicField[];
    showForm = true;
    isShowingPassword = false;
    createdForm: FormGroup<any> = null;

    constructor(private notificationsService: NotificationsService, private authEntityService: AuthEntityService, private authService: AuthService) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this._setConfig();
    }

    private _setConfig(): void {
        this._formFactory = new AuthUserDynamicConfig<IAuthUser>(this.authUser, null, this._configControls, true);
        this.config = this._formFactory.getForUpdate();
        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    ShowPassword(): void {
        const password = this.createdForm.value.AuthUser.Password.toString();
        const currentPassword = this.createdForm.value.AuthUser.CurrentPassword.toString();
        const confirmPassword = this.createdForm.value.AuthUser.ConfirmPassword.toString();
        this.showForm = false;
        setTimeout(() => {
            this.formObject.forEach((x) => {
                x.type.fieldType = x.type.fieldType === DynamicFieldTypes.Input ? DynamicFieldTypes.Password : DynamicFieldTypes.Input;
                this.isShowingPassword = x.type.fieldType === DynamicFieldTypes.Input;
                if(x.name === 'Password') x.value = password;
                if(x.name === 'CurrentPassword') x.value = currentPassword;
                if(x.name === 'ConfirmPassword') x.value = confirmPassword;
            });
            this.showForm = true;
        });
    }

    saveCreatedForm(form: FormGroup): void{
        this.createdForm = form;
    }

    formSubmitted(form: FormGroup): void {
        if (this.authService.matchPassword(form)) {
            if (form.valid) {
                this.authEntityService
                    .savePassword(
                        this.authUser.Id,
                        String(form.value.AuthUser.Password),
                        String(form.value.AuthUser.CurrentPassword),
                        String(form.value.AuthUser.ConfirmPassword),
                    )                    .subscribe(
                        () => {
                            this._success();
                        },
                        (error: HttpErrorResponse) => {
                            if (error.status === 418) {
                                this.notificationsService.error('Incorrect current password');
                            } else {
                                this.notificationsService.error(String(error.error.ModelState[0]));
                            }
                        },
                    );
            } else {
                markAllFormFieldsAsTouched(form);
                this._error();
            }
        } else {
            this._error('Passwords do not match.');
        }
    }

    cancel(): void {
        this.onCompleteEmitter.emit();
    }
    private _success(): void {
        this.notificationsService.success('Password updated successfully');
        this.onCompleteEmitter.emit();
    }

    private _error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Please enter all required fields');
        } else {
            this.notificationsService.error(msg);
        }
    }
}
