<div class="container login-container">
    <div class="row d-flex justify-content-center">
        <div class="col-md-6 login-form-1">
            <h1 class="text-center">Set a new password for your account</h1>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                <div class="dynamic-field my-3" [class.has-error]="showPasswordRequiredError() || hasRegexError()">
                    <label>New Password <span class="errortext">*</span></label>
                    <input #Password class="form-control" type="{{ isPasswordVisible ? 'text' : 'password'}}" autocomplete="off" placeholder="Password" formControlName="Password" />
                    <div class="small errortext">
                        {{ showPasswordRequiredError() ? 'Password is required' : '' }}
                        {{ hasRegexError() ? 'Does not meet requirements' : '' }}
                    </div>
                </div>

                <div class="dynamic-field my-3" [class.has-error]="showPasswordMustMatchError() || showConfirmPasswordRequiredError()">
                    <label>Confirm New Password <span class="errortext">*</span></label>
                    <input #PasswordConfirm class="form-control" type="{{ isPasswordVisible ? 'text' : 'password'}}" autocomplete="off" placeholder="Confirm Password" formControlName="ConfirmPassword" />
                    <div class="small errortext">
                        {{ showPasswordMustMatchError() ? 'Passwords do not match' : '' }}
                        {{ showConfirmPasswordRequiredError() ? 'Confirmation is required' : '' }}
                    </div>
                </div>
                <a 
                class="float-left" style="cursor: pointer;" 
                (click)="isPasswordVisible = !isPasswordVisible">
                    {{ isPasswordVisible ? 'Hide Password' : 'Show Password' }}
                </a>
                <button
                    type="submit"
                    mtDisableClickDuringHttpCalls
                    Class="btn btn-primary float-right"
                >
                    Save and Login
                </button>
            </form>
        </div>
    </div>
</div>
