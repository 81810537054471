import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomerRegistrationPayload } from '@model/interfaces/custom/customer-registration-payload';
import { Observable } from 'rxjs';
import { IEmailPasswordDTO } from '../model/interfaces/custom/email-password-dto';

@Injectable({
    providedIn: 'root',
})
export class CustomerRegistrationService {
    constructor(public http: HttpClient) {}

    registerCustomer(data: ICustomerRegistrationPayload): Observable<number> {
        return this.http.post<number>(`/customers/register`, data);
    }

    checkValidEmailPassword(emailPasswordDTO: IEmailPasswordDTO): Observable<any> {
        return this.http.put<any>(`/customers/validate-email-password`, emailPasswordDTO);
    }

    checkValidPassword(password: string): Observable<any> {
        return this.http.get<any>(`/customers/validate-password/${password}`);
    }
}
