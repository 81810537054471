import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMenuDTO } from '@model/DTO/menu-dto';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    constructor(public http: HttpClient) {}

    getMenu(): Observable<IMenuDTO[]> {
        return this.http.get<IMenuDTO[]>('/menu');
    }
}
