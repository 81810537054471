import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormatUsZipCode } from '../../functions/common-functions.library';
// tslint:disable: object-literal-sort-keys

@Component({
    selector: 'zip-code-dynamic-field',
    templateUrl: './zip-code-field.component.html',
})
export class ZipCodeFieldComponent implements OnDestroy {
    @Input() field: any;
    @Input() form: any;
    @Input() fieldName: any;
    @Output() invalidReason = new EventEmitter<string>();
    zipCodeInvalid: boolean;
    errorMessage = 'US zip code must contain 5 or 9 digits';

    private _subscriptions = new Subscription();

    constructor() {
        // This component doesn't have any properties or dependencies to initialize
    }

    ngAfterViewInit(): void {
        const subscription: Subscription = this.form.get(this.fieldName).valueChanges.subscribe((val: string) => {
            this.form.get(this.fieldName).setValue(FormatUsZipCode(val), { emitEvent: false });
        });
        this._subscriptions.add(subscription);
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    checkZipCodeValidity(): void {
        const currentZip = this.form.get(this.fieldName).value;
        this.zipCodeInvalid = currentZip.length < 5 || (currentZip.length > 5 && currentZip.length < 10) || /[a-zA-Z]/.test(String(currentZip));
        this.invalidReason.emit(this.zipCodeInvalid ? this.errorMessage : null);
    }
}
