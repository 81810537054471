import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomer } from '@model/interfaces/customer';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    constructor(public http: HttpClient) {}

    getInfo(): Observable<ICustomer> {
        return this.http.get<ICustomer>(`/customers/detail`);
    }

    updateCustomer(customer: ICustomer): Observable<number> {
        return this.http.put<number>(`/customers/update`, customer, {
            responseType: 'text' as 'json',
        });
    }
}
